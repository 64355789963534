/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main Layout Container */
.main-layout {
  display: flex;
  min-height: 100vh;
  background-color: #13141B;
  position: relative;
  overflow: hidden;
}

/* Content Area */
.layout-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 300px;
  width: calc(100% - 300px);
  transition: all 0.3s ease;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  position: relative;
  z-index: 1;
}

.layout-content.sidebar-collapsed {
  margin-left: 80px;
  width: calc(100% - 80px);
}

/* Main Content Area */
.layout-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 70px;
  min-height: calc(100vh - 70px);
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 20px;
}

/* Footer Styles */
footer {
  margin-top: auto;
  width: 100%;
  padding: 20px;
  background-color: #13141B;
  z-index: 2;
}

/* Screen Size Specific Styles */
/* Large screens (>1440px) */
.main-layout.large .layout-content {
  margin-left: 300px;
  width: calc(100% - 300px);
}

.main-layout.large .layout-content.sidebar-collapsed {
  margin-left: 80px;
  width: calc(100% - 80px);
}

/* 14-inch screens (1366px-1440px) */
.main-layout.14inch {
  overflow: hidden;
}

.main-layout.14inch .layout-content {
  margin-left: 80px;
  width: calc(100% - 80px);
  overflow-x: hidden;
}

.main-layout.14inch .layout-content:not(.sidebar-collapsed) {
  margin-left: 300px;
  width: calc(100% - 300px);
}

.main-layout.14inch .layout-main {
  padding-top: 65px;
}

.main-layout.14inch .content-container {
  padding: 15px;
  max-width: 100%;
  overflow-x: hidden;
}

.main-layout.14inch footer {
  padding: 15px;
}

/* 13-inch screens (≤1366px) */
.main-layout.13inch {
  overflow: hidden;
}

.main-layout.13inch .layout-content {
  margin-left: 80px;
  width: calc(100% - 80px);
  overflow-x: hidden;
  z-index: 1;
}

.main-layout.13inch .layout-content:not(.sidebar-collapsed) {
  margin-left: 300px;
  width: calc(100% - 300px);
}

.main-layout.13inch .layout-main {
  padding-top: 65px;
}

.main-layout.13inch .content-container {
  padding: 15px;
  max-width: 100%;
  overflow-x: hidden;
}

.main-layout.13inch footer {
  padding: 12px;
}

/* Mobile screens */
.main-layout.mobile .layout-content {
  margin-left: 0;
  width: 100%;
}

.main-layout.mobile .layout-main {
  padding-top: 60px;
}

.main-layout.mobile .content-container {
  padding: 10px;
}

.main-layout.mobile footer {
  padding: 10px;
}

/* Transitions */
.layout-content,
.old-sidebar {
  transition: all 0.3s ease;
}

/* Ensure content doesn't overflow */
.layout-content > * {
  max-width: 100%;
  overflow-x: hidden;
}