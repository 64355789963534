/* Admin Sidebar Base */
.admin-sidebar {
    width: 260px;
    height: 100vh;
    background-color: #1a1a1a;
    color: #fff;
    transition: width 0.3s ease;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: auto;
}

.admin-sidebar.collapsed {
    width: 60px;
}

.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logo {
    font-size: 1.2rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
}

.toggle-button {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

.toggle-button:hover {
    color: #007bff;
}

.admin-sidebar-nav {
    padding: 1rem 0;
}

.admin-menu-group {
    margin-bottom: 0.5rem;
}

.admin-menu-header {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-menu-header:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.admin-menu-header.active {
    background-color: rgba(0, 123, 255, 0.2);
}

.admin-menu-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.admin-menu-title {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.admin-menu-arrow {
    font-size: 0.8rem;
    transition: transform 0.3s ease;
}

.admin-menu-arrow.rotated {
    transform: rotate(180deg);
}

.admin-menu-items {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.admin-menu-items.open {
    max-height: 500px;
}

.admin-nav-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 3rem;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.collapsed .admin-nav-item {
    padding: 0.5rem 1rem;
    justify-content: center;
}

.admin-nav-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.admin-nav-item.active {
    background-color: rgba(0, 123, 255, 0.2);
}

.admin-nav-item-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.collapsed .admin-nav-item-icon {
    margin-right: 0;
}

.admin-nav-item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Tooltip styles for collapsed state */
.collapsed .admin-menu-header[title]:hover::after,
.collapsed .admin-nav-item[title]:hover::after {
    content: attr(title);
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
    white-space: nowrap;
    margin-left: 8px;
    z-index: 1001;
}

/* Responsive styles */
@media (max-width: 768px) {
    .admin-sidebar {
        transform: translateX(-100%);
    }

    .admin-sidebar.collapsed {
        transform: translateX(0);
    }
}

/* Scrollbar Styling */
.admin-sidebar::-webkit-scrollbar {
    width: 6px;
}

.admin-sidebar::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
}

.admin-sidebar::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
}

.admin-sidebar::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
} 