/* CSS Variables for consistent theming */
:root {
    /* Colors */
    --primary-color: #2563eb;
    --primary-hover: #1d4ed8;
    --secondary-color: #64748b;
    --success-color: #16a34a;
    --danger-color: #dc2626;
    --warning-color: #f59e0b;
    --info-color: #0891b2;
    
    /* Background Colors */
    --bg-primary: #ffffff;
    --bg-secondary: #f8fafc;
    --bg-tertiary: #f1f5f9;
    
    /* Text Colors */
    --text-primary: #1e293b;
    --text-secondary: #475569;
    --text-muted: #94a3b8;
    
    /* Border Colors */
    --border-light: #e2e8f0;
    --border-medium: #cbd5e1;
    
    /* Shadows */
    --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    
    /* Border Radius */
    --radius-sm: 0.375rem;
    --radius-md: 0.5rem;
    --radius-lg: 0.75rem;
    
    /* Spacing */
    --spacing-xs: 0.5rem;
    --spacing-sm: 0.75rem;
    --spacing-md: 1rem;
    --spacing-lg: 1.5rem;
    --spacing-xl: 2rem;
    
    /* Transitions */
    --transition-fast: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    --transition-normal: 200ms cubic-bezier(0.4, 0, 0.2, 1);
    --transition-slow: 300ms cubic-bezier(0.4, 0, 0.2, 1);

    /* Update panel widths for new layout */
    --league-panel-width: 25%;
    --main-panel-width: 75%;

    /* Remove fixed width variables */
    --container-padding: 2rem;
    --section-spacing: 2rem;
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes slideIn {
    from { transform: translateX(20px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.02); }
    100% { transform: scale(1); }
}

/* Main Layout */
.credit-challenge-container {
    min-height: 100vh;
    background-color: var(--bg-secondary);
    padding: var(--container-padding);
    width: 100%;
}

.credit-challenge-content {
    display: flex;
    flex-direction: column;
    gap: var(--section-spacing);
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

/* Main Content Area */
.main-content-area {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
}

/* League Information Panel */
.league-info-panel {
    background: var(--bg-primary);
    padding: var(--spacing-xl);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-md);
    border: 1px solid var(--border-light);
    margin-bottom: var(--section-spacing);
}

.league-info-header {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    margin-bottom: var(--spacing-lg);
    padding-bottom: var(--spacing-md);
    border-bottom: 2px solid var(--border-light);
}

.league-logo {
    width: 48px;
    height: 48px;
    object-fit: contain;
}

.league-name {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
}

.league-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-lg);
}

.stat-item {
    text-align: center;
    padding: var(--spacing-sm);
    background: var(--bg-tertiary);
    border-radius: var(--radius-sm);
}

.stat-label {
    font-size: 0.875rem;
    color: var(--text-secondary);
    margin-bottom: var(--spacing-xs);
}

.stat-value {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
}

/* Challenge Form Section */
.challenge-form-section {
    background: var(--bg-primary);
    padding: var(--spacing-xl);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-lg);
    border: 1px solid var(--border-light);
    width: 100%;
}

.challenge-form-section:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg), 0 0 0 1px var(--primary-color);
}

/* Headings */
.heading-container {
    margin-bottom: var(--spacing-xl);
    padding-bottom: var(--spacing-md);
    border-bottom: 2px solid var(--border-light);
    position: relative;
}

.heading-container::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: var(--primary-color);
}

.page-heading {
    font-size: 1.75rem;
    color: var(--text-primary);
    margin: 0;
    font-weight: 700;
    letter-spacing: -0.025em;
}

/* Form Elements */
.form-group {
    margin-bottom: var(--spacing-lg);
    width: 100%;
    animation: fadeIn var(--transition-normal);
}

.form-label {
    display: block;
    margin-bottom: var(--spacing-xs);
    font-weight: 600;
    color: var(--text-primary);
    font-size: 0.9375rem;
}

.form-select {
    width: 100%;
    padding: var(--spacing-md);
    border: 1.5px solid var(--border-medium);
    border-radius: var(--radius-md);
    font-size: 1rem;
    background-color: var(--bg-primary);
    transition: all var(--transition-normal);
    color: var(--text-primary);
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1rem;
    padding-right: 2.5rem;
}

.form-select:hover:not(:disabled) {
    border-color: var(--primary-color);
}

.form-select:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

/* Form Layout Improvements */
.form-content {
    max-width: 100%;
    margin: 0 auto;
}

.dropdown-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-lg);
    margin-bottom: var(--spacing-lg);
    width: 100%;
}

.score-display {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: var(--spacing-xl);
    align-items: center;
    padding: var(--spacing-xl);
    background: var(--bg-tertiary);
    border-radius: var(--radius-lg);
    margin: var(--spacing-lg) 0;
    width: 100%;
}

.team-score-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-md);
    padding: var(--spacing-lg);
    background: var(--bg-primary);
    border-radius: var(--radius-md);
    box-shadow: var(--shadow-sm);
}

.vs-indicator {
    grid-column: span 1;
    justify-self: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-secondary);
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-primary);
    border-radius: 50%;
    box-shadow: var(--shadow-sm);
}

/* Live Preview Section */
.live-preview {
    background: var(--bg-primary);
    padding: var(--spacing-xl);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-md);
    border: 1px solid var(--border-light);
    width: 100%;
    margin-top: var(--section-spacing);
    position: relative;
    overflow: hidden;
}

/* Virtualized List Container */
.bets-list {
    display: grid;
    gap: var(--spacing-md);
    width: 100%;
    max-height: 800px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color) var(--bg-tertiary);
    will-change: transform;
    contain: content;
    padding-right: var(--spacing-md);
}

/* Scrollbar Styling */
.bets-list::-webkit-scrollbar {
    width: 8px;
}

.bets-list::-webkit-scrollbar-track {
    background: var(--bg-tertiary);
    border-radius: 4px;
}

.bets-list::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 4px;
}

/* Bet Item Card */
.bet-item {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    padding: var(--spacing-lg);
    background: var(--bg-secondary);
    border-radius: var(--radius-md);
    border: 1px solid var(--border-light);
    transition: transform var(--transition-normal), box-shadow var(--transition-normal);
    contain: content;
}

.bet-item:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
}

/* Score Summary Section */
.score-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-md);
    background: var(--bg-tertiary);
    border-radius: var(--radius-md);
    margin-bottom: var(--spacing-md);
}

.admin-score {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    padding: var(--spacing-sm) var(--spacing-md);
    background: var(--bg-primary);
    border-radius: var(--radius-sm);
    box-shadow: var(--shadow-sm);
}

.admin-score-label {
    font-size: 0.8125rem;
    color: var(--text-secondary);
    font-weight: 500;
}

.admin-score-value {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-primary);
}

.goal-advantage {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--radius-sm);
    font-weight: 600;
}

.goal-advantage-positive {
    background: rgba(22, 163, 74, 0.1);
    color: var(--success-color);
}

.goal-advantage-negative {
    background: rgba(220, 38, 38, 0.1);
    color: var(--danger-color);
}

.goal-advantage-neutral {
    background: rgba(245, 158, 11, 0.1);
    color: var(--warning-color);
}

/* Users Container */
.bet-users-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-md);
}

/* Bet User Section */
.bet-user {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    padding: var(--spacing-md);
    border-radius: var(--radius-sm);
    background: var(--bg-primary);
    position: relative;
}

/* User Header */
.bet-user-header {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
}

.user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.875rem;
}

.user-name {
    font-weight: 600;
    font-size: 0.9375rem;
    color: var(--text-primary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 40px);
}

/* Bet Details */
.bet-details {
    display: grid;
    gap: var(--spacing-xs);
    font-size: 0.875rem;
}

.bet-choice {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    background: var(--bg-tertiary);
    border-radius: var(--radius-sm);
    font-size: 0.8125rem;
    color: var(--text-secondary);
}

.bet-amount {
    font-weight: 600;
    color: var(--text-primary);
}

/* Payout Section */
.payout-preview {
    margin-top: auto;
    padding: var(--spacing-sm);
    border-radius: var(--radius-sm);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payout-label {
    font-size: 0.8125rem;
    color: var(--text-secondary);
}

.payout-value {
    font-size: 0.9375rem;
}

/* Outcome Styles */
.bet-user.winning {
    box-shadow: inset 0 0 0 1px var(--success-color);
}

.bet-user.winning .payout-preview {
    background: rgba(22, 163, 74, 0.1);
    color: var(--success-color);
}

.bet-user.losing {
    box-shadow: inset 0 0 0 1px var(--danger-color);
}

.bet-user.losing .payout-preview {
    background: rgba(220, 38, 38, 0.1);
    color: var(--danger-color);
}

/* Density Modes */
.bets-list.compact .bet-item {
    padding: var(--spacing-sm);
}

.bets-list.compact .bet-user {
    padding: var(--spacing-sm);
    gap: var(--spacing-xs);
}

.bets-list.spacious .bet-item {
    padding: var(--spacing-xl);
}

.bets-list.spacious .bet-user {
    padding: var(--spacing-lg);
    gap: var(--spacing-md);
}

/* Responsive Grid Layouts */
@media (min-width: 1440px) {
    .bets-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1200px) and (max-width: 1439px) {
    .bets-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .bets-list {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .bet-users-container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 767px) {
    .bets-list {
        grid-template-columns: 1fr;
        padding-right: 0;
    }

    .bet-item {
        padding: var(--spacing-md);
    }

    .bet-users-container {
        grid-template-columns: 1fr;
    }

    .score-summary {
        flex-direction: column;
        gap: var(--spacing-sm);
    }

    .admin-score,
    .goal-advantage {
        width: 100%;
        justify-content: center;
    }
}

/* Loading States */
.bet-item-skeleton {
    opacity: 0.7;
    animation: pulse 1.5s ease-in-out infinite;
}

.bet-item-skeleton .bet-user {
    background: linear-gradient(
        90deg,
        var(--bg-tertiary) 0%,
        var(--bg-secondary) 50%,
        var(--bg-tertiary) 100%
    );
    background-size: 200% 100%;
    animation: shimmer 2s infinite;
}

@keyframes shimmer {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
}

/* Accessibility Enhancements */
.bet-user:focus-within {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}

@media (prefers-reduced-motion: reduce) {
    .bet-item,
    .bet-user,
    .payout-preview {
        transition: none;
    }
}

/* Error States */
.error-state {
    text-align: center;
    padding: var(--spacing-xl);
    color: var(--danger-color);
    background: rgba(220, 38, 38, 0.1);
    border-radius: var(--radius-md);
    margin: var(--spacing-lg) 0;
}

.retry-button {
    margin-top: var(--spacing-md);
    padding: var(--spacing-sm) var(--spacing-lg);
    background: var(--danger-color);
    color: white;
    border: none;
    border-radius: var(--radius-sm);
    cursor: pointer;
    font-weight: 500;
}

/* Progress Indicator */
.progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    background: var(--primary-color);
    transition: width var(--transition-normal);
}

/* Empty State */
.no-bets-message {
    text-align: center;
    padding: var(--spacing-xl);
    color: var(--text-secondary);
    font-size: 1.1rem;
    background: var(--bg-tertiary);
    border-radius: var(--radius-md);
    border: 1px dashed var(--border-medium);
    margin: var(--spacing-lg) 0;
}

/* Live Preview Enhancements */
.payout-preview {
    margin-top: var(--spacing-lg);
}

.payout-item {
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-sm);
    border-radius: var(--radius-md);
    border: 1px solid var(--border-light);
    transition: all var(--transition-normal);
}

.payout-item.winning {
    background: rgba(22, 163, 74, 0.1);
    border-color: var(--success-color);
}

.payout-item.losing {
    background: rgba(220, 38, 38, 0.1);
    border-color: var(--danger-color);
}

.payout-item.draw {
    background: rgba(245, 158, 11, 0.1);
    border-color: var(--warning-color);
}

/* Form Status Dropdown */
.form-group:last-of-type {
    margin-bottom: var(--spacing-xl);
}

/* Alert Container Styles */
.alert-container {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    pointer-events: none;
}

.alert {
    padding: var(--spacing-md) var(--spacing-lg);
    border-radius: var(--radius-md);
    background: var(--bg-primary);
    box-shadow: var(--shadow-lg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slideInDown 0.3s ease-out;
    pointer-events: auto;
}

.alert-success {
    background: var(--success-color);
    color: white;
}

.alert-error {
    background: var(--danger-color);
    color: white;
}

.alert-warning {
    background: var(--warning-color);
    color: white;
}

@keyframes slideInDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
