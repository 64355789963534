/* Root Variables */
:root {
  --primary-color: #2C5F2D;
  --primary-hover: #224924;
  --danger-color: #dc3545;
  --danger-hover: #c82333;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --light-bg: #f8f9fa;
  --border-color: #dee2e6;
  --text-primary: #333;
  --text-secondary: #6c757d;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --radius-sm: 8px;
  --radius-md: 12px;
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
}

/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
}

.layout {
  display: flex;
}

.main-content {
  flex-grow: 1;
  margin-left: 250px;
  padding: 20px;
  overflow-y: auto;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Payment Methods Styles */
.payment-methods {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-methods h1, 
.payment-methods h2 {
  color: #2c5f2d;
  margin-bottom: 20px;
}

/* Container Layout */
.payment-methods-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: var(--spacing-md);
}

.payment-methods-content {
  display: grid;
  gap: var(--spacing-lg);
}

/* Card Styles */
.payment-methods-card {
  background: white;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-lg);
  height: fit-content;
}

.payment-methods-card h2 {
  color: var(--primary-color);
  margin-bottom: var(--spacing-md);
  font-size: 1.5rem;
  font-weight: 600;
}

/* Form Styles */
.payment-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.form-group label {
  font-weight: 500;
  color: var(--text-primary);
}

.form-input {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

.form-input:focus {
  outline: none;
  border-color: var(--primary-color);
}

/* Field Groups */
.field-group {
  background: var(--light-bg);
  padding: var(--spacing-md);
  border-radius: var(--radius-sm);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  border: 1px solid var(--border-color);
}

.field-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-sm);
}

/* Button Styles */
.submit-button,
.add-field-button,
.remove-field-button {
  padding: 0.75rem 1rem;
  border: none;
  border-radius: var(--radius-sm);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
}

.submit-button {
  background-color: var(--primary-color);
  color: white;
}

.submit-button:hover {
  background-color: var(--primary-hover);
}

/* Update button styles to be more specific */
.payment-form .add-field-button {
  padding: 0.75rem 1rem;
  border: none;
  border-radius: var(--radius-sm);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}

.payment-form .add-field-button:hover {
  background-color: var(--primary-hover) !important;
  color: white !important;
}

/* Update remove button styles to be more specific */
.payment-form .remove-field-button {
  padding: 0.75rem 1rem;
  border: none;
  border-radius: var(--radius-sm);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  background-color: var(--danger-color) !important;
  color: white !important;
  border: none !important;
}

.payment-form .remove-field-button:hover {
  background-color: var(--danger-hover) !important;
  color: white !important;
}

.payment-form .remove-field-button:disabled {
  background-color: var(--light-bg) !important;
  color: var(--text-secondary) !important;
  cursor: not-allowed;
}

/* Remove the conflicting styles */
.remove-field-button {
  background-color: var(--danger-color);
  color: white;
}

.remove-field-button:hover {
  background-color: var(--danger-hover);
}

.remove-field-button:disabled {
  background-color: var(--light-bg);
  color: var(--text-secondary);
  cursor: not-allowed;
}

/* Payment Methods List */
.payment-methods-list {
  display: grid;
  gap: var(--spacing-md);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  align-items: stretch;
}

.payment-method-item {
  background: white;
  border-radius: var(--radius-sm);
  padding: var(--spacing-md);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  height: 100%;
  min-height: 200px;
  position: relative;
  overflow: hidden;
}

.payment-method-item h3 {
  color: var(--primary-color);
  margin: 0;
  font-size: 1.2rem;
  padding-bottom: var(--spacing-sm);
  border-bottom: 1px solid var(--border-color);
}

.payment-type {
  color: var(--text-secondary);
  font-size: 0.9rem;
  padding: var(--spacing-xs) 0;
  border-bottom: 1px solid var(--border-color);
}

.field-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  overflow-y: auto;
  max-height: calc(100% - 140px);
  padding: var(--spacing-sm) 0;
  margin: var(--spacing-xs) 0;
  border-bottom: 1px solid var(--border-color);
}

.field-item {
  padding: 0.75rem;
  background: var(--light-bg);
  border-radius: var(--radius-sm);
  border: 1px solid var(--border-color);
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.field-item:last-child {
  margin-bottom: 0;
}

.field-item strong {
  color: var(--text-primary);
  margin-right: 0.5rem;
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
}

/* Action Buttons */
.action-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-sm);
  padding-top: var(--spacing-sm);
  margin-top: auto;
  background: white;
  position: sticky;
  bottom: 0;
}

.edit-button,
.delete-button {
  padding: 0.75rem;
  border: none;
  border-radius: var(--radius-sm);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
}

.edit-button {
  background-color: var(--primary-color);
  color: white;
}

.edit-button:hover {
  background-color: var(--primary-hover);
}

.delete-button {
  background-color: var(--danger-color);
  color: white;
}

.delete-button:hover {
  background-color: var(--danger-hover);
}

/* Message Styles */
.error-message,
.success-message {
  padding: var(--spacing-sm);
  border-radius: var(--radius-sm);
  margin-bottom: var(--spacing-md);
  font-weight: 500;
}

.error-message {
  background-color: #ffebee;
  color: var(--danger-color);
  border: 1px solid #ffcdd2;
}

.success-message {
  background-color: #e8f5e9;
  color: var(--success-color);
  border: 1px solid #c8e6c9;
}

/* Scrollbar styling */
.field-list::-webkit-scrollbar {
  width: 6px;
}

.field-list::-webkit-scrollbar-track {
  background: var(--light-bg);
  border-radius: 3px;
}

.field-list::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 3px;
}

.field-list::-webkit-scrollbar-thumb:hover {
  background: var(--text-secondary);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .payment-methods-list {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

@media (max-width: 768px) {
  .payment-methods-container {
    padding: var(--spacing-sm);
  }

  .field-inputs {
    grid-template-columns: 1fr;
  }

  .payment-methods-list {
    grid-template-columns: 1fr;
  }

  .payment-method-item {
    min-height: 180px;
  }

  .field-list {
    max-height: calc(100% - 120px);
  }
}

@media (max-width: 480px) {
  .payment-methods-card {
    padding: var(--spacing-sm);
  }

  .field-group {
    padding: var(--spacing-sm);
  }
}

@media (max-width: 768px) {
  .main-content {
      margin-left: 0;
  }
}
