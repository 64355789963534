/* League Details Specific Container */
.league-details__container {
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  max-width: 1400px;
  margin: 0 auto;
  min-height: calc(100vh - 4rem);
}

/* League Header Section */
.league-details__header {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

/* Banner and Icon Styles */
.league-details__banner-wrapper {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 2rem;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.league-details__banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.league-details__banner-wrapper:hover .league-details__banner-image {
  transform: scale(1.05);
}

.league-details__banner-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, 
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0) 100%);
  padding: 2rem;
  display: flex;
  align-items: flex-end;
  gap: 2rem;
}

.league-details__icon-wrapper {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 24px;
  overflow: hidden;
  border: 4px solid #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  flex-shrink: 0;
  transform: translateY(20%);
}

.league-details__icon-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.league-details__icon-wrapper:hover .league-details__icon-image {
  transform: scale(1.1);
}

.league-details__banner-content {
  flex: 1;
}

.league-details__banner-content .league-details__title {
  color: #ffffff;
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
  background: none;
  -webkit-text-fill-color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.league-details__banner-content .league-details__description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  max-width: 600px;
}

/* Position Card Styles */
.league-details__position-card {
  background: linear-gradient(135deg, #2c5f2d 0%, #48bb78 100%);
  border-radius: 20px;
  padding: 2rem;
  color: #ffffff;
  margin-bottom: 2rem;
  box-shadow: 0 8px 24px rgba(44, 95, 45, 0.15);
  position: relative;
  overflow: hidden;
}

.league-details__position-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.1) 100%);
  opacity: 0.1;
  mix-blend-mode: overlay;
}

.league-details__position-title {
  color: #ffffff;
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.league-details__stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.league-details__stat-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.league-details__stat-item:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.2);
}

.league-details__stat-label {
  display: block;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.75rem;
  color: rgba(255, 255, 255, 0.9);
}

.league-details__stat-value {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;
}

.league-details__progress-section {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 16px;
  margin-top: 1rem;
}

.league-details__progress-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: #ffffff;
  font-size: 0.9rem;
}

.league-details__progress-container {
  height: 8px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  overflow: hidden;
}

.league-details__progress-bar {
  height: 100%;
  background: #ffffff;
  border-radius: 20px;
  transition: width 1s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.league-details__progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%);
  animation: shimmer 2s infinite;
}

/* User Position Section */
.league-details__user-position {
  background: linear-gradient(135deg, #2c5f2d 0%, #48bb78 100%);
  border-radius: 16px;
  padding: 2rem;
  color: white;
  margin-bottom: 2rem;
  box-shadow: 0 8px 16px rgba(44, 95, 45, 0.2);
  position: relative;
  overflow: hidden;
}

.league-details__user-position::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
  pointer-events: none;
}

.league-details__position-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 600;
}

.league-details__stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1.25rem;
}

.league-details__stat-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.25rem;
  border-radius: 12px;
  backdrop-filter: blur(8px);
  transition: transform 0.3s ease, background 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.league-details__stat-card:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.2);
}

.league-details__stat-label {
  font-size: 0.9rem;
  opacity: 0.9;
  margin-bottom: 0.75rem;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.league-details__stat-value {
  font-size: 1.75rem;
  font-weight: 700;
  display: block;
}

/* Progress Bar */
.league-details__progress-container {
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  overflow: hidden;
  height: 8px;
  position: relative;
}

.league-details__progress-bar {
  height: 100%;
  background: #fff;
  border-radius: 20px;
  transition: width 1s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.league-details__progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, 
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,0.3) 50%,
    rgba(255,255,255,0) 100%);
  animation: shimmer 2s infinite;
}

/* Leaderboard Section */
.league-details__leaderboard {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.league-details__leaderboard-title {
  font-size: 1.75rem;
  color: #2c3e50;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 600;
}

.league-details__table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.75rem;
}

.league-details__table th {
  background: #f8f9fa;
  padding: 1.25rem 1rem;
  text-align: left;
  color: #6c757d;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.875rem;
}

.league-details__table td {
  padding: 1.25rem 1rem;
  background: #fff;
  transition: all 0.3s ease;
  border-top: 1px solid rgba(0,0,0,0.05);
}

.league-details__table tr {
  transition: all 0.3s ease;
}

.league-details__table tr:hover td {
  background: #f8f9fa;
  transform: translateX(4px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.league-details__table tr.current-user td {
  background: #e6f4e6;
  font-weight: 500;
}

.league-details__table tr.current-user:hover td {
  background: #d1ebd1;
}

/* Rank Icons */
.league-details__rank {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.league-details__rank-icon {
  font-size: 1.25rem;
  transition: transform 0.3s ease;
}

.league-details__rank-icon:hover {
  transform: scale(1.2);
}

.league-details__rank-icon.gold {
  color: #FFD700;
  filter: drop-shadow(0 2px 4px rgba(255, 215, 0, 0.3));
}

.league-details__rank-icon.silver {
  color: #C0C0C0;
  filter: drop-shadow(0 2px 4px rgba(192, 192, 192, 0.3));
}

.league-details__rank-icon.bronze {
  color: #CD7F32;
  filter: drop-shadow(0 2px 4px rgba(205, 127, 50, 0.3));
}

/* Points Column */
.league-details__points {
  font-weight: 600;
  color: #2c5f2d;
  position: relative;
}

/* Animations */
@keyframes shimmer {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes progressFill {
  from { width: 0; }
  to { width: var(--progress-width); }
}

.league-details__progress-bar {
  animation: progressFill 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Loading State */
.league-details__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.league-details__loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2c5f2d;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Buttons */
.league-details__button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: #2c5f2d;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.league-details__button:hover {
  background: #234b24;
  transform: translateY(-1px);
}

.league-details__button--outline {
  background: transparent;
  border: 2px solid #2c5f2d;
  color: #2c5f2d;
}

.league-details__button--outline:hover {
  background: #2c5f2d;
  color: white;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .league-details__container {
    padding: 1.5rem;
  }

  .league-details__stats-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .league-details__stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .league-details__container {
    padding: 1rem;
  }

  .league-details__header {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .league-details__title {
    font-size: 2rem;
  }

  .league-details__banner {
    height: 180px;
  }

  .league-details__stats-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .league-details__table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .league-details__table td,
  .league-details__table th {
    padding: 1rem 0.75rem;
  }
}

@media (max-width: 576px) {
  .league-details__stats-grid {
    grid-template-columns: 1fr;
  }

  .league-details__stat-card {
    padding: 1rem;
  }
}
