/* Modern Reset and Container */
.profile-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;
    background: #ffffff;
    min-height: calc(100vh - 70px);
    overflow-y: visible;
    gap: 30px;
    display: flex;
    flex-direction: column;
}

/* Profile Header and Banner */
.profile-header {
    background: #ffffff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    margin-bottom: 30px;
}

.profile-banner {
    background: linear-gradient(135deg, #2c5f2d 0%, #1e4620 100%);
    padding: 40px 30px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 30px;
}

.profile-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 4px solid #ffffff;
    overflow: hidden;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-avatar {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.profile-info-header {
    color: #ffffff;
}

.profile-info-header h1 {
    font-size: 32px;
    margin: 0;
    margin-bottom: 10px;
}

.member-since {
    font-size: 14px;
    opacity: 0.8;
}

/* Stats Container */
.stats-container {
    padding: 25px;
    background: #ffffff;
    border-bottom: 1px solid #e0e0e0;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    padding: 0;
    max-width: 100%;
}

.stat-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    min-width: 160px;
    flex-shrink: 0;
}

/* Remove hover effects */
.stat-card::before {
    display: none;
}

.stat-card:hover {
    transform: none;
    box-shadow: none;
}

.stat-icon {
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.stat-info {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.stat-info h3 {
    color: #666;
    font-size: 13px;
    margin: 0 0 5px 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    width: 100%;
}

.stat-value {
    font-size: 22px;
    font-weight: 700;
    color: #2c5f2d;
    display: block;
    line-height: 1.2;
    text-align: center;
    width: 100%;
}

/* Static colors for stat cards */
.stat-card.bets .stat-icon {
    background: rgba(66, 153, 225, 0.1);
    color: #4299e1;
}

.stat-card.winrate .stat-icon {
    background: rgba(236, 201, 75, 0.1);
    color: #ecc94b;
}

.stat-card.score .stat-icon {
    background: rgba(237, 100, 166, 0.1);
    color: #ed64a6;
}

.stat-card.points .stat-icon {
    background: rgba(46, 187, 123, 0.1);
    color: #2ebb7b;
}

.stat-card.friends .stat-icon {
    background: rgba(129, 140, 248, 0.1);
    color: #818cf8;
}

.stat-card.requests .stat-icon {
    background: rgba(99, 102, 241, 0.1);
    color: #6366f1;
}

/* Remove hover state changes */
.stat-card:hover .stat-icon {
    background: inherit;
    color: inherit;
}

/* Friends Section */
.friends-section {
    background: #ffffff;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.friends-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.friend-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.friend-header {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid #e0e0e0;
    background: #f8f9fa;
}

.friend-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #2c5f2d;
    padding: 3px;
}

.friend-avatar img {
    width: 70%;
    height: 70%;
    object-fit: contain;
}

.friend-basic-info {
    flex: 1;
}

.friend-basic-info h3 {
    margin: 0;
    color: #2c5f2d;
    font-size: 18px;
}

.friend-basic-info a {
    text-decoration: none;
}

.favorite-team {
    font-size: 14px;
    color: #666;
    display: block;
    margin-top: 4px;
}

/* Stats Grid in Friend Card */
.friend-stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid #e0e0e0;
}

.friend-stats-grid .stat-item {
    text-align: center;
    padding: 15px 10px;
    position: relative;
}

/* Vertical lines between stats */
.friend-stats-grid .stat-item:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 20%;
    height: 60%;
    width: 1px;
    background-color: #e0e0e0;
}

.friend-stats-grid .stat-item label {
    display: block;
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.friend-stats-grid .stat-item span {
    font-size: 16px;
    font-weight: 600;
    color: #2c5f2d;
    display: block;
}

/* Message Button */
.message-btn {
    width: 100%;
    padding: 12px;
    background: #2c5f2d;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: auto; /* Push button to bottom */
}

.message-btn:hover {
    background: #1e4620;
}

.message-btn::before {
    content: '✉';
    font-size: 16px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .friends-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .friends-grid {
        grid-template-columns: 1fr;
    }
    
    .friend-card {
        max-width: 100%;
    }
}

/* Search Box */
.search-box {
    display: flex;
    gap: 10px;
    max-width: 400px;
}

.search-box input {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 14px;
}

.search-box button {
    padding: 10px 20px;
    background: #2c5f2d;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .stats-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}

@media (max-width: 768px) {
    .profile-banner {
        flex-direction: column;
        text-align: center;
    }
    
    .section-header {
        flex-direction: column;
        gap: 20px;
    }
    
    .search-box {
        width: 100%;
        max-width: none;
    }
}

/* Modern Betting History Table */
.bets-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: 20px 0;
}

.bets-table thead th {
    background: #f8f9fa;
    padding: 12px;
    font-weight: 600;
    text-align: left;
    border-bottom: 2px solid #dee2e6;
}

.bets-table tbody tr {
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #dee2e6;
    counter-increment: betCounter;
}

.bets-table tbody tr:last-child {
    border-bottom: none;
}

.bets-table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.bets-table tbody tr td {
    padding: 16px 12px;
    position: relative;
}

.bets-table tbody tr td:first-child {
    padding-left: 50px;
}

.bets-table tbody tr td:first-child::before {
    content: counter(betCounter);
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: #1976d2;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85rem;
    font-weight: 600;
}

.bets-table tbody tr::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: #dee2e6;
    pointer-events: none;
}

.bet-reference {
    font-family: monospace;
    font-size: 0.9rem;
    color: #495057;
    font-weight: 500;
}

.team-cell {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 0;
}

.team-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    flex: 1;
}

.team-logo-container img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
    background: white;
    padding: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.vs-badge {
    font-weight: 600;
    color: #6c757d;
    padding: 0 8px;
}

.bet-amount {
    color: #666;
    font-size: 0.875rem;
}

.bet-status {
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 4px;
    display: inline-block;
}

.bet-status.pending {
    background: #fff3cd;
    color: #856404;
}

.bet-status.completed {
    background: #d4edda;
    color: #155724;
}

.bet-status.cancelled {
    background: #f8d7da;
    color: #721c24;
}

.bet-returns {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.return-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    background: #f8f9fa;
    border-radius: 4px;
    font-size: 0.9rem;
}

.return-label {
    color: #6c757d;
    font-weight: 500;
}

.return-value {
    color: #2b3035;
    font-weight: 600;
}

/* Status Badges */
.status {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}

.status.won {
    background: rgba(0, 255, 135, 0.1);
    color: #00ff87;
}

.status.lost {
    background: rgba(255, 56, 56, 0.1);
    color: #ff3838;
}

.status.pending {
    background: rgba(255, 199, 0, 0.1);
    color: #ffc700;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    background: #2c5f2d;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: #1e4620;
}

/* Container Modifications */
.profile-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;
    background: #ffffff;
    min-height: calc(100vh - 70px);
    overflow-y: visible;
    gap: 30px;
    display: flex;
    flex-direction: column;
}

/* Enhanced Section Spacing */
.profile-header, .search-section.card, .recent-bets.card, .friends-section {
    margin-bottom: 30px;
    border: 1px solid #e0e0e0;
    background: #ffffff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

/* Enhanced Recent Bets Table */
.recent-bets.card {
    background: #ffffff;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    margin-bottom: 30px;
}

.recent-bets.card h2 {
    color: #2c3e50;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 25px 0;
    padding-bottom: 15px;
    border-bottom: 2px solid #f0f0f0;
    position: relative;
}

.bets-table-container {
    margin-top: 20px;
    overflow-x: auto;
}

.recent-bets {
    background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
    border-radius: 16px;
    padding: 25px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    overflow: hidden;
}

.recent-bets h2 {
    color: #2c5f2d;
    font-size: 24px;
    margin-bottom: 25px;
    font-weight: 700;
    position: relative;
    display: inline-block;
}

.recent-bets h2::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 40px;
    height: 4px;
    background: #2c5f2d;
    border-radius: 2px;
}

.bets-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;
    margin-top: -12px;
}

.bets-table thead th {
    background: transparent;
    color: #666;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    padding: 12px;
    letter-spacing: 0.5px;
    border: none;
    text-align: left;
}

.bets-table thead th:first-child {
    padding-left: 20px;
}

.bets-table thead th:last-child {
    padding-right: 20px;
}

.bets-table tbody tr {
    background: white;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.03);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border-radius: 12px;
}

.bets-table tbody tr:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.bets-table td {
    padding: 16px 12px;
    border: none;
    position: relative;
    vertical-align: middle;
}

.bets-table td:first-child {
    padding-left: 20px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.bets-table td:last-child {
    padding-right: 20px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

/* Bet Reference Number */
.bet-reference {
    font-family: 'Roboto Mono', monospace;
    font-size: 13px;
    color: #2c5f2d;
    font-weight: 600;
    background: rgba(44, 95, 45, 0.1);
    padding: 4px 8px;
    border-radius: 4px;
    letter-spacing: 0.5px;
}

/* Team Display */
.team-cell {
    display: flex;
    align-items: center;
    gap: 10px;
}

.team-logo-container {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: white;
    padding: 3px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.team-logo-container::after {
    content: '';
    position: absolute;
    inset: -2px;
    border-radius: 50%;
    border: 2px solid transparent;
    background: linear-gradient(135deg, #2c5f2d 0%, #1e4620 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

.team-logo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}

.team-name {
    font-weight: 600;
    color: #000000;
    font-size: 14px;
}

/* Status Badges */
.bet-status {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bet-status.pending {
    background: linear-gradient(135deg, #ffd700 0%, #ffaa01 100%);
    color: #000000;
}

.bet-status.won {
    background: linear-gradient(135deg, #00b09b 0%, #2ed573 100%);
    color: #ffffff;
}

.bet-status.lost {
    background: linear-gradient(135deg, #ff4757 0%, #ff6b81 100%);
    color: #ffffff;
}

.bet-status.active {
    background: linear-gradient(135deg, #377dff 0%, #56a8ff 100%);
    color: #ffffff;
}

.bet-status.joined {
    background: linear-gradient(135deg, #98FB98 0%, #90EE90 100%);
    color: #000000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Amount Display */
.bet-amount {
    font-weight: 700;
    color: #2c5f2d;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 4px;
}

/* VS Badge */
.vs-badge {
    background: #f8f9fa;
    color: #666;
    padding: 4px 12px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 12px;
    position: relative;
    margin: 0 10px;
}

.vs-badge::before,
.vs-badge::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 15px;
    height: 1px;
    background: #ddd;
}

.vs-badge::before {
    right: 100%;
    margin-right: 5px;
}

.vs-badge::after {
    left: 100%;
    margin-left: 5px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .bets-table {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    
    .recent-bets {
        padding: 20px 15px;
    }
}

@media (max-width: 768px) {
    .bet-reference {
        font-size: 12px;
        padding: 3px 6px;
    }
    
    .team-name {
        font-size: 13px;
    }
    
    .bet-amount {
        font-size: 14px;
    }
}

/* Enhanced Search Section */
.search-section input {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    color: #333333;
    padding: 15px 20px;
}

.search-section input::placeholder {
    color: #a0aec0;
}

.search-section button {
    background: #2c5f2d;
    color: white;
    font-weight: 600;
    padding: 15px 30px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.search-section button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(44, 95, 45, 0.3);
}

/* Message Status Enhancement */
.message-status {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px 25px;
    border-radius: 10px;
    z-index: 1000;
    animation: slideIn 0.3s ease-out;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.message-status.success {
    background: rgba(0, 255, 135, 0.1);
    border-left: 4px solid #00ff87;
    color: #00ff87;
}

.message-status.error {
    background: rgba(255, 56, 56, 0.1);
    border-left: 4px solid #ff3838;
    color: #ff3838;
}

/* Update Profile Banner and Welcome Message */
.profile-banner {
    background: linear-gradient(135deg, #2c5f2d 0%, #1e4620 100%);
    padding: 40px 30px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 30px;
}

.welcome-text {
    color: #ffffff;
    font-size: 32px;
    margin: 0;
    margin-bottom: 10px;
    text-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.banner-message-btn {
    margin-top: 15px;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: fit-content;
}

.banner-message-btn:hover {
    background: rgba(255, 255, 255, 0.3);
    transform: translateY(-2px);
}

.msg-icon {
    font-size: 16px;
}

/* Responsive adjustments for stats */
@media (max-width: 1400px) {
    .stats-grid {
        grid-template-columns: repeat(6, 1fr);
        gap: 15px;
    }
    
    .stat-card {
        padding: 15px;
    }
}

@media (max-width: 1200px) {
    .stats-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .stats-container {
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .stats-grid {
        grid-template-columns: 1fr;
    }
}

/* Message Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
}

.message-modal {
    background: white;
    border-radius: 15px;
    width: 95%;
    max-width: 600px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.close-button {
    position: absolute;
    right: 15px;
    top: 15px;
    background: none;
    border: none;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
    z-index: 1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.close-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.modal-user-preview {
    background: linear-gradient(135deg, #2c5f2d 0%, #1e4620 100%);
    padding: 25px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.modal-user-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: white;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid rgba(255, 255, 255, 0.2);
}

.modal-user-avatar img {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.modal-user-info {
    text-align: center;
}

.modal-user-info h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
}

.modal-team-name {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 5px;
    display: block;
}

.modal-user-stats {
    display: flex;
    gap: 30px;
    margin-top: 15px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    width: 100%;
    justify-content: center;
}

.modal-stat {
    display: flex;
    align-items: center;
    gap: 12px;
}

.modal-stat-icon {
    font-size: 20px;
    color: #ffd700;
}

.modal-stat-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal-stat-details label {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 2px;
}

.modal-stat-details span {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
}

.message-input-container {
    padding: 25px;
    background: #ffffff;
    width: 100%;
    box-sizing: border-box;
}

.message-input-container label {
    display: block;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: 500;
}

.message-input-container textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    resize: vertical;
    min-height: 120px;
    font-size: 14px;
    transition: border-color 0.3s ease;
    margin-bottom: 20px;
    box-sizing: border-box;
    display: block;
}

.message-input-container textarea:focus {
    outline: none;
    border-color: #2c5f2d;
}

.modal-actions {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
}

.modal-actions button {
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 8px;
    border: none;
    min-width: 120px;
}

.cancel-btn {
    background: #f5f5f5;
    color: #666;
}

.cancel-btn:hover {
    background: #e0e0e0;
}

.send-btn {
    background: #2c5f2d;
    color: white;
}

.send-btn:hover {
    background: #1e4620;
    transform: translateY(-2px);
}

.send-btn:disabled {
    background: #cccccc;
    cursor: not-allowed;
    transform: none;
}

.send-icon {
    font-size: 16px;
}

.message-input-section {
    padding: 25px;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
}

.message-input-header {
    margin-bottom: 15px;
}

.message-input-header label {
    font-size: 1.1rem;
    font-weight: 500;
    color: #333;
}

.message-textarea-container {
    width: 100%;
    margin-bottom: 20px;
}

.message-textarea {
    width: 100%;
    min-height: 180px;
    padding: 15px;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    font-size: 1rem;
    line-height: 1.6;
    resize: vertical;
    box-sizing: border-box;
    font-family: inherit;
    transition: all 0.3s ease;
}

.message-textarea:focus {
    outline: none;
    border-color: #2c5f2d;
    box-shadow: 0 0 0 3px rgba(44, 95, 45, 0.1);
}

.message-modal-footer {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-btn {
    padding: 12px 25px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    min-width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.modal-btn.cancel-btn {
    background: #f0f0f0;
    color: #666;
}

.modal-btn.cancel-btn:hover {
    background: #e0e0e0;
}

.modal-btn.send-btn {
    background: #2c5f2d;
    color: white;
}

.modal-btn.send-btn:hover {
    background: #234b24;
}

.modal-btn.send-btn:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.send-icon {
    font-size: 1.1rem;
}

.modal-user-stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 15px 20px;
    background: #f8f9fa;
    border-radius: 8px;
    margin-top: 15px;
}

.modal-stat-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.modal-stat-grid-item label {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 5px;
}

.modal-stat-grid-item span {
    font-size: 1.2rem;
    font-weight: 600;
    color: #2c5f2d;
}

/* Enhanced User Search Results */
.user-search-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.user-search-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.user-search-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.user-primary-info {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
}

.user-primary-info .username {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2c5f2d;
}

.user-primary-info .team-info {
    display: flex;
    align-items: center;
    gap: 8px;
    background: rgba(44, 95, 45, 0.05);
    padding: 6px 12px;
    border-radius: 20px;
}

.team-logo-tiny {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.favorite-team {
    font-size: 0.9rem;
    color: #666;
}

.user-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 15px;
    background: #f8f9fa;
    border-radius: 10px;
}

.user-stats span {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    background: white;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.user-stats span::before {
    font-size: 1.1rem;
    margin-bottom: 5px;
    color: #2c5f2d;
}

.user-stats span:nth-child(1)::before {
    content: '🎮';
}

.user-stats span:nth-child(2)::before {
    content: '🏆';
}

.user-stats span:nth-child(3)::before {
    content: '⭐';
}

.add-friend-btn {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    background: linear-gradient(135deg, #2c5f2d 0%, #1e4620 100%);
    color: white;
    font-weight: 600;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: auto;
}

.add-friend-btn:hover {
    background: linear-gradient(135deg, #1e4620 0%, #153215 100%);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(44, 95, 45, 0.2);
}

.add-friend-btn svg {
    font-size: 1.1rem;
}

/* Search Results Container */
.search-results {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

/* Search Section Enhancement */
.search-section.card {
    background: white;
    padding: 25px;
    border-radius: 15px;
}

.search-section h2 {
    color: #2c5f2d;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.search-box {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.search-box input {
    flex: 1;
    padding: 12px 20px;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    font-size: 0.95rem;
    transition: all 0.3s ease;
}

.search-box input:focus {
    outline: none;
    border-color: #2c5f2d;
    box-shadow: 0 0 0 3px rgba(44, 95, 45, 0.1);
}

.search-box button {
    padding: 12px 24px;
    background: #2c5f2d;
    color: white;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.search-box button:hover {
    background: #1e4620;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(44, 95, 45, 0.2);
}

.search-error {
    color: #dc3545;
    background: rgba(220, 53, 69, 0.1);
    padding: 12px 20px;
    border-radius: 8px;
    margin-top: 10px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 8px;
}

.search-error::before {
    content: '⚠️';
}

/* Bet Returns */
.bet-returns {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.return-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
}

.return-label {
    color: #666;
    font-weight: 500;
}

.return-value {
    color: #2c5f2d;
    font-weight: 600;
}

/* Make table rows clickable */
.bets-table tbody tr {
    cursor: pointer;
}

/* Add transition for hover effect */
.bets-table tbody tr {
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
}

.bets-table tbody tr:hover {
    background-color: #f8f9fa;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

/* Ensure table container scrolls horizontally on mobile */
.bets-table-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.team-selector {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.team-selector .username {
    font-size: 12px;
    color: #666;
    font-weight: 500;
}

.profile-container .recent-bets .team-cell .team-selector .team-name {
    font-weight: 600;
    color: #000000;
    font-size: 14px;
}

.profile-container .recent-bets .team-cell .team-selector .username {
    font-size: 12px;
    color: #666;
    font-weight: 700;
}

.team-name {
    font-size: 1rem;
    font-weight: normal;
    color: #2b3035;
    margin: 4px 0;
}

.bets-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: 20px 0;
}

.bets-table thead th {
    background: #f8f9fa;
    padding: 12px;
    font-weight: 600;
    text-align: left;
    border-bottom: 2px solid #dee2e6;
}

.bets-table tbody tr {
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #dee2e6;
    counter-increment: betCounter;
}

.bets-table tbody tr:last-child {
    border-bottom: none;
}

.bets-table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.bets-table tbody tr td {
    padding: 16px 12px;
    position: relative;
}

.bets-table tbody tr td:first-child {
    padding-left: 50px;
}

.bets-table tbody tr td:first-child::before {
    content: counter(betCounter);
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: #1976d2;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85rem;
    font-weight: 600;
}

.bets-table tbody tr::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: #dee2e6;
    pointer-events: none;
}

.bet-reference {
    font-family: monospace;
    font-size: 0.9rem;
    color: #495057;
    font-weight: 500;
}

.team-cell {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 0;
}

.team-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    flex: 1;
}

.team-logo-container img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
    background: white;
    padding: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.vs-badge {
    font-weight: 600;
    color: #6c757d;
    padding: 0 8px;
}

.bet-amount {
    color: #666;
    font-size: 0.875rem;
}

.bet-status {
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 4px;
    display: inline-block;
}

.bet-status.pending {
    background: #fff3cd;
    color: #856404;
}

.bet-status.completed {
    background: #d4edda;
    color: #155724;
}

.bet-status.cancelled {
    background: #f8d7da;
    color: #721c24;
}

.bet-returns {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.return-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    background: #f8f9fa;
    border-radius: 4px;
    font-size: 0.9rem;
}

.return-label {
    color: #6c757d;
    font-weight: 500;
}

.return-value {
    color: #2b3035;
    font-weight: 600;
}

.username {
    font-size: 0.85rem;
    color: #6c757d;
}

.profile-container .profile-recent-bets {
    margin: 2rem 0;
}

.profile-container .profile-bets-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: 20px 0;
}

.profile-container .profile-bets-table thead th {
    background: #f8f9fa;
    padding: 12px;
    font-weight: 600;
    text-align: left;
    border-bottom: 2px solid #dee2e6;
}

.profile-container .profile-bets-table tbody tr {
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #dee2e6;
    counter-increment: betCounter;
}

.profile-container .profile-bets-table tbody tr:last-child {
    border-bottom: none;
}

.profile-container .profile-bets-table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.profile-container .profile-bets-table tbody tr td {
    padding: 16px 12px;
    position: relative;
}

.profile-container .profile-bets-table tbody tr td:first-child {
    padding-left: 50px;
}

.profile-container .profile-bets-table tbody tr td:first-child::before {
    content: counter(betCounter);
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: #1976d2;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85rem;
    font-weight: 600;
}

.profile-container .profile-bets-table tbody tr::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: #dee2e6;
    pointer-events: none;
}

.profile-container .profile-bet-reference {
    font-family: 'Courier New', monospace;
    font-size: 0.9rem;
    color: #1976d2;
    font-weight: 500;
    background: rgba(25, 118, 210, 0.1);
    padding: 4px 8px;
    border-radius: 4px;
    letter-spacing: 0.5px;
}

.profile-container .profile-team-cell {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 0;
}

.profile-container .profile-team-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    flex: 1;
    min-width: 120px;
}

.profile-container .profile-team-name {
    font-size: 0.9rem;
    color: #2b3035;
    margin: 4px 0;
    text-align: center;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.profile-container .profile-team-logo-container {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

.profile-container .profile-team-logo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
    background: white;
    padding: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-container .profile-bet-returns {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.profile-container .profile-return-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    background: #ffffff;
    border-radius: 4px;
    font-size: 0.9rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
}

.profile-container .profile-return-item:hover {
    border-color: rgba(25, 118, 210, 0.3);
    background: rgba(25, 118, 210, 0.02);
}

.profile-container .profile-return-label {
    color: #6c757d;
    font-weight: 500;
    margin-right: 12px;
}

.profile-container .profile-return-value {
    color: #2b3035;
    font-weight: 600;
    font-family: 'Courier New', monospace;
    letter-spacing: 0.5px;
}

.profile-container .profile-username {
    font-size: 0.85rem;
    color: #6c757d;
}

.profile-recent-bets-section {
    margin: 2rem 0;
}

.profile-recent-bets-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: 20px 0;
}

.profile-recent-bets-table thead th {
    background: #f8f9fa;
    padding: 12px;
    font-weight: 600;
    text-align: left;
    border-bottom: 2px solid #dee2e6;
}

.profile-recent-bets-table tbody tr {
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #dee2e6;
    counter-increment: betCounter;
}

.profile-recent-bets-table tbody tr:last-child {
    border-bottom: none;
}

.profile-recent-bets-table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.profile-recent-bets-table tbody tr td {
    padding: 16px 12px;
    position: relative;
}

.profile-recent-bets-table tbody tr td:first-child {
    padding-left: 50px;
}

.profile-recent-bets-table tbody tr td:first-child::before {
    content: counter(betCounter);
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: #1976d2;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85rem;
    font-weight: 600;
}

.profile-recent-bet-ref {
    font-family: 'Courier New', monospace;
    font-size: 0.9rem;
    color: #1976d2;
    font-weight: 500;
    background: rgba(25, 118, 210, 0.1);
    padding: 4px 8px;
    border-radius: 4px;
    letter-spacing: 0.5px;
}

.profile-recent-team-cell {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 0;
}

.profile-recent-team-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    flex: 1;
    min-width: 120px;
}

.profile-recent-team-name {
    font-size: 0.9rem;
    color: #2b3035;
    margin: 4px 0;
    text-align: center;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.profile-recent-team-logo {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

.profile-recent-team-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
    background: white;
    padding: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-recent-vs {
    font-weight: 600;
    color: #6c757d;
    padding: 0 8px;
}

.profile-recent-bet-amount {
    font-weight: 500;
    color: #2b3035;
}

.profile-recent-bet-status {
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 4px;
    display: inline-block;
}

.profile-recent-bet-status.pending {
    background: #fff3cd;
    color: #856404;
}

.profile-recent-bet-status.completed {
    background: #d4edda;
    color: #155724;
}

.profile-recent-bet-status.cancelled {
    background: #f8d7da;
    color: #721c24;
}

.profile-recent-returns {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.profile-recent-return-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    background: #ffffff;
    border-radius: 4px;
    font-size: 0.9rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
}

.profile-recent-return-item:hover {
    border-color: rgba(25, 118, 210, 0.3);
    background: rgba(25, 118, 210, 0.02);
}

.profile-recent-return-label {
    color: #6c757d;
    font-weight: 500;
    margin-right: 12px;
}

.profile-recent-return-value {
    color: #2b3035;
    font-weight: 600;
    font-family: 'Courier New', monospace;
    letter-spacing: 0.5px;
}

.profile-recent-username {
    font-size: 0.85rem;
    color: #6c757d;
}

.profile-recent-team-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 8px 0;
}

.profile-recent-team-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    min-width: 120px;
}

.profile-recent-team-logo {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile-recent-team-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}

.profile-recent-team-name {
    font-size: 0.9rem;
    color: #2b3035;
    text-align: center;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
}

.profile-recent-vs {
    font-weight: 600;
    color: #6c757d;
    padding: 0 12px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    height: 100%;
    background: #f8f9fa;
    border-radius: 4px;
}
