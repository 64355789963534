/* Table Styles */
.view-bets-container .bets-table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0 8px !important;
  background: transparent !important;
  border: none !important;
}

.view-bets-container .bets-table th,
.view-bets-container .bets-table td {
  padding: 16px !important;
  text-align: left !important;
  background: white !important;
  border: none !important;
  border-bottom: none !important;
  border-top: none !important;
}

.view-bets-container .bets-table thead {
  border-bottom: 1px solid #e9ecef;
}

.view-bets-container .bets-table th {
  padding: 16px !important;
  font-weight: 500 !important;
  color: #495057 !important;
  background-color: white !important;
  position: relative !important;
}

.view-bets-container .bets-table th:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: linear-gradient(to right, #e9ecef 0%, #e9ecef 100%);
}

.view-bets-container .bets-table th {
  background-color: #f8f9fa !important;
  font-weight: 500 !important;
  color: #6c757d !important;
  font-size: 0.9em !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
  border: none !important;
}

.view-bets-container .bets-table tbody tr {
  margin-bottom: 8px !important;
  transition: all 0.2s ease !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02) !important;
  border: none !important;
}

.view-bets-container .bets-table tbody tr:hover {
  background-color: #f8f9fa !important;
  transform: translateY(-1px) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05) !important;
}

.view-bets-container .bets-table tbody tr td {
  background: white !important;
  border: none !important;
}

.view-bets-container .bets-table tbody tr:hover td {
  background: #f8f9fa !important;
}

.view-bets-container .team-info {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 240px;
}

.view-bets-container .team-logo {
  width: 48px;
  height: 48px;
  object-fit: contain;
  background: #f8f9fa;
  padding: 6px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.view-bets-container .status-badge {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.85em;
  font-weight: 500;
  gap: 6px;
}

.view-bets-container .status-badge.open {
  background-color: #e3f2fd;
  color: #1976d2;
}

.view-bets-container .status-badge.joined {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.view-bets-container .status-badge.completed {
  background-color: #ede7f6;
  color: #5e35b1;
}

.view-bets-container .status-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.view-bets-container .status-badge.open .status-dot {
  background-color: #1976d2;
}

.view-bets-container .status-badge.joined .status-dot {
  background-color: #2e7d32;
}

.view-bets-container .status-badge.completed .status-dot {
  background-color: #5e35b1;
}

.view-bets-container .pick-badge {
  background-color: #e3f2fd;
  color: #1976d2;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 0.75em;
  font-weight: 500;
}

.view-bets-container .generate-link-btn {
  padding: 8px 16px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.9em;
  font-weight: 500;
}

.view-bets-container .generate-link-btn:hover {
  background-color: #1565c0;
  transform: translateY(-1px);
}

.view-bets-container .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
}

.view-bets-container .pagination-button {
  padding: 8px 16px;
  border: none;
  background-color: white;
  color: #495057;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.view-bets-container .pagination-button:hover:not(:disabled) {
  background-color: #f8f9fa;
  transform: translateY(-1px);
}

.view-bets-container .pagination-button.active {
  background-color: #1976d2;
  color: white;
}

.view-bets-container .pagination-button:disabled {
  background-color: #f8f9fa;
  color: #adb5bd;
  cursor: not-allowed;
  transform: none;
}

.view-bets-container .highlight-new-bet {
  animation: highlightRow 5s ease-out;
}

@keyframes highlightRow {
  0% {
    background-color: #e3f2fd;
  }
  100% {
    background-color: transparent;
  }
}

/* Desktop Team and VS Styles */
.view-bets-container .teams-header {
  text-align: center !important;
}

.view-bets-container .team-cell {
  min-width: 240px !important;
}

.view-bets-container .vs-cell {
  width: 80px !important;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 !important;
}

.view-bets-container .vs-indicator {
  position: relative;
  color: #6c757d;
  font-weight: 500;
  padding: 8px 24px;
  display: inline-block;
}

.view-bets-container .vs-indicator::before,
.view-bets-container .vs-indicator::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 20px;
  height: 1px;
  background: #dee2e6;
}

.view-bets-container .vs-indicator::before {
  right: 100%;
}

.view-bets-container .vs-indicator::after {
  left: 100%;
}

.view-bets-container .team-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

/* Modal Styles */
.view-bets-container .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.view-bets-container .bet-details-modal {
  background: #ffffff;
  padding: 40px;
  border-radius: 16px;
  width: 95%;
  max-width: 1200px;
  position: relative;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 40px;
  max-height: 90vh;
  overflow-y: auto;
}

.view-bets-container .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.view-bets-container .close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  z-index: 10;
}

.view-bets-container .modal-left {
  display: flex;
  flex-direction: column;
}

.view-bets-container .reference-title {
  font-size: 20px;
  color: #1b5e20;
  font-weight: 600;
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(27, 94, 32, 0.1);
}

.view-bets-container .match-info {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.view-bets-container .match-type-badge {
  background: #e3f2fd;
  color: #2196f3;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

.view-bets-container .match-date {
  color: #666;
  font-size: 14px;
}

.view-bets-container .teams-match {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.view-bets-container .team-card {
  background: #ffffff;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  position: relative;
}

.view-bets-container .selected-badge {
  position: absolute;
  top: 12px;
  left: 12px;
  background: #1b5e20;
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.view-bets-container .selected-badge::before {
  content: '✓';
  font-weight: bold;
}

.view-bets-container .team-card img {
  width: 80px;
  height: 80px;
  margin-bottom: 16px;
  object-fit: contain;
}

.view-bets-container .team-name {
  font-size: 16px;
  color: #000000 !important;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
}

.view-bets-container .team-username {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

.view-bets-container .team-odds {
  color: #1b5e20;
  font-size: 14px;
  font-weight: 500;
}

.view-bets-container .vs-badge {
  width: 40px;
  height: 40px;
  background: #1b5e20;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
}

/* Right side styles */
.view-bets-container .modal-right {
  padding-left: 40px;
  border-left: 1px solid #eee;
}

.view-bets-container .section-title {
  color: #666;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.view-bets-container .details-section {
  margin-bottom: 32px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.view-bets-container .details-section:last-child {
  margin-bottom: 0;
}

.view-bets-container .section-title {
  color: #1b5e20;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding-bottom: 8px;
  border-bottom: 2px solid rgba(27, 94, 32, 0.1);
}

.view-bets-container .detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.view-bets-container .detail-row:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.view-bets-container .detail-label {
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

.view-bets-container .detail-value {
  font-weight: 500;
  font-size: 14px;
  color: #333;
}

.view-bets-container .detail-value.amount {
  color: #ff6b6b;
}

.view-bets-container .detail-value.return {
  color: #51cf66;
}

/* Odds Styling */
.view-bets-container .team-odds {
  font-family: 'Monaco', monospace;
  letter-spacing: 0.5px;
}

.view-bets-container .team-odds.user1 {
  background: #e8f5e9;
  color: #2c5f2d;
}

.view-bets-container .team-odds.user2 {
  background: #e3f2fd;
  color: #1976d2;
}

/* Username Uppercase */
.view-bets-container .team-user {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}

/* Your Pick Badge Repositioning */
.view-bets-container .team-info .pick-badge {
  position: static;
  margin-top: 8px;
  display: block;
  text-align: center;
  width: fit-content;
  margin: 8px auto 0;
}

.view-bets-container .bets-table th,
.view-bets-container .bets-table td {
  border-bottom: none;
}

.view-bets-container .bets-table th {
  border-bottom: none;
}

.view-bets-container .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
}

.view-bets-container .pagination-button {
  padding: 6px 12px;
  border: 1px solid #dee2e6;
  background-color: white;
  color: #495057;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s;
}

.view-bets-container .pagination-button:hover:not(:disabled) {
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.view-bets-container .pagination-button.active {
  background-color: #1976d2;
  color: white;
  border-color: #1976d2;
}

.view-bets-container .pagination-button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.view-bets-container .search-container {
  margin-bottom: 24px;
  width: 100%;
  max-width: 400px;
}

.view-bets-container .search-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 0.95em;
  background-color: white;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
}

.view-bets-container .search-input:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 3px rgba(25, 118, 210, 0.1);
}

.view-bets-container .search-input::placeholder {
  color: #adb5bd;
}

.view-bets-container .title-section {
  margin-bottom: 24px;
}

.view-bets-container .title-section h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 12px;
  font-weight: 500;
}

.view-bets-container .title-line {
  height: 1px;
  background: linear-gradient(to right, #1976d2 0%, #1976d2 30%, transparent 100%);
  opacity: 0.2;
  margin-bottom: 24px;
}

.view-bets-container .reference {
  font-family: 'Monaco', monospace;
  color: #2c5f2d;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  padding: 8px 12px;
  background: #e8f5e9;
  border-radius: 6px;
  display: inline-block;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(44, 95, 45, 0.1);
}

.view-bets-container .reference:hover {
  background: #c8e6c9;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(44, 95, 45, 0.15);
}

.view-bets-container .amount-cell {
  font-weight: 600;
  color: #2c5f2d;
  font-size: 15px;
}

.view-bets-container .return-cell {
  color: #198754;
  font-weight: 600;
  font-size: 15px;
}

.view-bets-container .currency {
  font-size: 12px;
  opacity: 0.7;
  margin-left: 4px;
}

.view-bets-container .team-info span {
  font-size: 15px;
  font-weight: 500;
  color: #333;
}

.view-bets-container .match-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
}

.view-bets-container .schedule-section,
.view-bets-container .odds-section {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
}

.view-bets-container .schedule-grid,
.view-bets-container .odds-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
}

.view-bets-container .schedule-item,
.view-bets-container .odds-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.view-bets-container .schedule-item:last-child,
.view-bets-container .odds-item:last-child {
  border-bottom: none;
}

.view-bets-container .schedule-label,
.view-bets-container .odds-label {
  color: #666;
  font-size: 13px;
  font-weight: 500;
}

.view-bets-container .schedule-value,
.view-bets-container .odds-value {
  font-size: 13px;
  font-weight: 600;
  color: #1b5e20;
}

.view-bets-container .teams-match {
  margin-bottom: 0;
}

.view-bets-container .modal-header {
  margin-bottom: 30px;
}

.view-bets-container .status-badges {
  display: flex;
  gap: 16px;
  margin-top: 20px;
}

.view-bets-container .status-badge-large {
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.view-bets-container .status-badge-large::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.view-bets-container .status-badge-large.open {
  background: #fff3cd;
  color: #856404;
}

.view-bets-container .status-badge-large.open::before {
  background: #856404;
}

.view-bets-container .status-badge-large.joined {
  background: #cce5ff;
  color: #004085;
}

.view-bets-container .status-badge-large.joined::before {
  background: #004085;
}

.view-bets-container .status-badge-large.completed {
  background: #d4edda;
  color: #155724;
}

.view-bets-container .status-badge-large.completed::before {
  background: #155724;
}

.view-bets-container .match-type-badge-large {
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.view-bets-container .match-type-badge-large.half_time {
  background: #e3f2fd;
  color: #0d47a1;
}

.view-bets-container .match-type-badge-large.full_time {
  background: #f3e5f5;
  color: #4a148c;
}

.view-bets-container .odds-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  text-align: left;
}

.view-bets-container .odds-label {
  color: #666;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  flex: 1;
}

.view-bets-container .odds-value {
  font-size: 13px;
  font-weight: 600;
  color: #1b5e20;
  min-width: 60px;
  text-align: right;
}

/* Desktop/Mobile View Control */
@media (min-width: 769px) {
  .mobile-bets-grid {
    display: none !important;
  }
  
  .table-responsive {
    display: block;
  }
}

@media (max-width: 768px) {
  .table-responsive {
    display: none !important;
  }

  .mobile-bets-grid {
    display: grid !important;
    grid-gap: 12px;
    padding: 12px;
  }

  /* Mobile Styles */
  .view-bets-container .bets-table {
    display: none !important;
  }

  .mobile-bets-grid {
    display: none;
  }

  .mobile-bets-grid {
    display: grid !important;
    grid-gap: 12px;
    padding: 12px;
  }

  .mobile-bet-card {
    background: white;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    padding: 16px;
    display: grid;
    gap: 16px;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .mobile-bet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
  }

  .mobile-teams-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #f8f9fa;
    border-radius: 16px;
    margin: 0 16px 16px;
    border: 1px solid rgba(0, 0, 0, 0.04);
  }

  .mobile-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .mobile-team-logo {
    width: 48px;
    height: 48px;
    object-fit: contain;
  }

  .mobile-team span {
    font-size: 0.9em;
    font-weight: 500;
    color: #2b3035;
    text-align: center;
  }

  .mobile-vs {
    font-size: 0.85em;
    font-weight: 600;
    color: #6c757d;
    background: white;
    padding: 4px 12px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.04);
  }

  .mobile-bet-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 16px;
    background: #f8f9fa;
    border-radius: 16px;
  }

  .mobile-detail-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .mobile-detail-item.full-width {
    grid-column: 1 / -1;
    border-top: 1px solid #e9ecef;
    padding-top: 12px;
    margin-top: 4px;
  }

  .mobile-detail-label {
    font-size: 0.85em;
    color: #6c757d;
  }

  .mobile-detail-value {
    font-weight: 500;
    font-size: 1.1em;
    color: #2b3035;
  }

  .mobile-bet-actions {
    display: flex;
    justify-content: stretch;
    gap: 12px;
    margin-top: 4px;
  }

  .mobile-action-button {
    flex: 1;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-weight: 500;
    font-size: 0.95em;
    border: none;
    cursor: pointer;
    background: #1976d2;
    color: white;
    transition: background-color 0.2s;
  }

  .mobile-action-button:active {
    background: #1565c0;
  }

  /* Hide generate link button for joined/completed bets */
  .mobile-bet-card[data-status="joined"] .mobile-bet-actions,
  .mobile-bet-card[data-status="completed"] .mobile-bet-actions {
    display: none;
  }

  /* Mobile Modal Styles */
  .view-bets-container .modal-content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100dvh;
    margin: 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }

  .mobile-modal-header {
    position: sticky;
    top: 0;
    background: white;
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    gap: 12px;
    z-index: 10;
  }

  .mobile-modal-body {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-modal-footer {
    position: sticky;
    bottom: 0;
    background: white;
    padding: 16px;
    border-top: 1px solid #f0f0f0;
    display: flex;
    gap: 12px;
  }

  .mobile-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: flex-end;
    animation: fadeIn 0.2s ease-out;
  }

  .mobile-modal-content {
    width: 100%;
    background: white;
    border-radius: 20px 20px 0 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 85vh;
  }

  .mobile-modal-header {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    gap: 16px;
    background: white;
    border-radius: 20px 20px 0 0;
    z-index: 10;
  }

  .mobile-modal-body {
    padding: 20px;
    display: grid;
    grid-template-rows: auto auto auto;
    gap: 20px;
    overflow: hidden;
  }

  .mobile-modal-ref {
    font-size: 1.1em;
    color: #1976d2;
    font-weight: 500;
  }

  .mobile-modal-teams {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 16px;
    padding: 16px;
    background: #f8f9fa;
    border-radius: 16px;
  }

  .mobile-modal-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
  }

  .mobile-modal-team-logo {
    width: 64px;
    height: 64px;
    object-fit: contain;
    background: white;
    padding: 8px;
    border-radius: 50%;
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  }

  .mobile-modal-team-name {
    font-weight: 500;
    font-size: 0.95em;
    margin-top: 4px;
  }

  .mobile-modal-team-pick {
    font-size: 0.85em;
    color: #2e7d32;
    background: #e8f5e9;
    padding: 4px 8px;
    border-radius: 12px;
  }

  .mobile-modal-vs {
    font-weight: 500;
    color: #6c757d;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .mobile-modal-details {
    display: grid;
    gap: 16px;
  }

  .mobile-modal-section {
    background: white;
    border-radius: 16px;
    border: 1px solid #f0f0f0;
    padding: 16px;
  }

  .mobile-modal-section h4 {
    margin: 0 0 12px 0;
    color: #495057;
    font-size: 1em;
  }

  .mobile-modal-detail-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    font-size: 0.9em;
  }

  .mobile-modal-detail-item:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }

  .mobile-modal-detail-item span:first-child {
    color: #6c757d;
  }

  .mobile-modal-detail-item span:last-child {
    font-weight: 500;
  }

  .mobile-modal-footer {
    padding: 16px;
    border-top: 1px solid #f0f0f0;
    background: white;
  }

  .mobile-modal-action-button {
    width: 100%;
    height: 44px;
    background: #1976d2;
    color: white;
    border: none;
    border-radius: 12px;
    font-weight: 500;
    font-size: 0.95em;
    cursor: pointer;
  }

  .mobile-bet-ref {
    background: #e8f5e9;
    padding: 6px 12px;
    border-radius: 8px;
    color: #2e7d32;
    font-weight: 500;
    font-size: 0.9em;
  }

  .mobile-odds-analysis {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    padding: 16px;
    background: #f8f9fa;
    border-radius: 16px;
    margin-top: 16px;
  }

  .mobile-odds-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 12px;
    background: white;
    border-radius: 12px;
    text-align: center;
  }

  .mobile-odds-label {
    font-size: 0.85em;
    color: #6c757d;
    font-weight: 500;
  }

  .mobile-odds-value {
    font-size: 1.1em;
    font-weight: 600;
    color: #2b3035;
  }

  .mobile-odds-return {
    font-size: 0.8em;
    color: #2e7d32;
  }

  .mobile-odds-loss {
    font-size: 0.8em;
    color: #d32f2f;
  }

  .mobile-odds-draw {
    font-size: 0.8em;
    color: #ed6c02;
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.view-bets-container .mobile-users-section {
  display: grid;
  grid-template-columns: 1fr 80px 1fr;
  gap: 0;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 16px;
  margin-top: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 16px;
}

.view-bets-container .mobile-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.view-bets-container .mobile-user-info:first-child {
  justify-self: start;
  padding-right: 16px;
}

.view-bets-container .mobile-user-info:last-child {
  justify-self: end;
  padding-left: 16px;
}

.view-bets-container .mobile-vs-divider {
  align-self: center;
  color: #6c757d;
  font-weight: 500;
  position: relative;
  width: 100%;
  text-align: center;
}

.view-bets-container .mobile-vs-divider::before,
.view-bets-container .mobile-vs-divider::after {
  content: '';
  position: absolute;
  left: 50%;
  width: 1px;
  height: 20px;
  background: #dee2e6;
}

.view-bets-container .mobile-vs-divider::before {
  bottom: calc(100% + 8px);
}

.view-bets-container .mobile-vs-divider::after {
  top: calc(100% + 8px);
}

.view-bets-container .mobile-user-name {
  font-weight: 600;
  color: #2b3035;
  font-size: 0.95em;
  word-break: break-word;
}

.view-bets-container .mobile-user-status {
  font-size: 0.85em;
  padding: 4px 8px;
  border-radius: 6px;
  background: #e9ecef;
  color: #6c757d;
}

.view-bets-container .mobile-user-status.creator {
  background: #e3f2fd;
  color: #1976d2;
}

.view-bets-container .mobile-user-status.opponent {
  background: #fff3e0;
  color: #ed6c02;
}

.view-bets-container .mobile-user-status.waiting {
  background: #fdf3e3;
  color: #ed6c02;
  font-style: italic;
}

/* Mobile Modal Additional Sections */
.view-bets-container .mobile-section-title {
  font-size: 0.9em;
  font-weight: 600;
  color: #6c757d;
  margin: 24px 16px 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.view-bets-container .mobile-odds-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 0 16px 16px;
  background: #f8f9fa;
  border-radius: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.view-bets-container .mobile-odds-item {
  background: white;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.view-bets-container .mobile-odds-item.full-width {
  grid-column: 1 / -1;
}

.view-bets-container .mobile-odds-label {
  font-size: 0.85em;
  color: #6c757d;
}

.view-bets-container .mobile-odds-value {
  font-size: 1.1em;
  font-weight: 600;
  color: #2b3035;
}

.view-bets-container .mobile-financial-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 0 16px 16px;
  background: #f8f9fa;
  border-radius: 16px;
}

.view-bets-container .mobile-financial-item {
  background: white;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.view-bets-container .mobile-financial-label {
  font-size: 0.85em;
  color: #6c757d;
}

.view-bets-container .mobile-financial-value {
  font-size: 1.1em;
  font-weight: 600;
}

.view-bets-container .mobile-financial-value.win {
  color: #2e7d32;
}

.view-bets-container .mobile-financial-value.loss {
  color: #d32f2f;
}

.view-bets-container .mobile-financial-value.draw {
  color: #ed6c02;
}

/* Make modal content scrollable */
.view-bets-container .mobile-modal-content {
  max-height: 90vh;
  overflow-y: auto;
  padding-bottom: 16px;
}

/* Add smooth scrolling */
.view-bets-container .mobile-modal-content {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar but keep functionality */
.view-bets-container .mobile-modal-content {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.view-bets-container .mobile-modal-content::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
    z-index: 1000;
  }

  .mobile-modal-content {
    background: white;
    width: 100%;
    border-radius: 20px;
    position: relative;
    max-height: 85vh;
    display: flex;
    flex-direction: column;
    margin: 16px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  }

  .mobile-modal-header {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background: white;
    border-radius: 20px 20px 0 0;
    z-index: 2;
  }

  .mobile-modal-body {
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 16px 16px 80px 16px;
    background: white;
  }

  .mobile-modal-body::-webkit-scrollbar {
    width: 8px;
  }

  .mobile-modal-body::-webkit-scrollbar-track {
    background: transparent;
  }

  .mobile-modal-body::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
  }

  .mobile-modal-footer {
    padding: 16px;
    border-top: 1px solid #f0f0f0;
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 2;
    border-radius: 0 0 20px 20px;
  }

  .mobile-modal-close {
    background: none;
    border: none;
    font-size: 24px;
    color: #6c757d;
    padding: 4px 8px;
    cursor: pointer;
  }

  .mobile-modal-action-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 12px;
    background: #2196f3;
    color: white;
    font-weight: 600;
    font-size: 1em;
    cursor: pointer;
    transition: background 0.2s;
  }

  .mobile-modal-action-button:hover {
    background: #1976d2;
  }
}

.view-bets-container .mobile-ref-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 16px;
  margin: 0 16px 16px;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.view-bets-container .mobile-ref-number {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.view-bets-container .mobile-ref-label {
  font-size: 0.85em;
  color: #6c757d;
}

.view-bets-container .mobile-ref-value {
  font-weight: 600;
  color: #2b3035;
  background: #e8f5e9;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 0.95em;
}

.view-bets-container .mobile-status-badges {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

.view-bets-container .mobile-status-badge {
  font-size: 0.85em;
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 500;
  text-transform: uppercase;
}

.view-bets-container .mobile-status-badge.open {
  background: #e3f2fd;
  color: #1976d2;
}

.view-bets-container .mobile-status-badge.joined {
  background: #fff3e0;
  color: #ed6c02;
}

.view-bets-container .mobile-status-badge.completed {
  background: #e8f5e9;
  color: #2e7d32;
}

.view-bets-container .mobile-match-type {
  background: #f3e5f5;
  color: #9c27b0;
}
