:root {
    --primary-green: #2c5f2d;
    --light-green: #52b788;
    --white: #ffffff;
    --gray-100: #f8f9fa;
    --gray-200: #e9ecef;
    --gray-300: #dee2e6;
    --gray-600: #718096;
    --gray-800: #2d3748;
    --shadow-sm: 0 2px 4px rgba(0,0,0,0.05);
    --shadow-md: 0 4px 6px rgba(0,0,0,0.1);
    --border-radius: 12px;
}

#root {
    height: 100vh;
    overflow-y: auto;
}

html {
    height: 100%;
    overflow-y: scroll;
}

body {
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto !important;
}

.user-registration {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background: linear-gradient(135deg, var(--primary-green) 0%, var(--light-green) 100%);
    overflow-y: auto;
    position: relative;
}

.user-registration-container {
    width: 100%;
    max-width: 420px;
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-md);
    padding: 1.5rem;
    margin: 1rem auto;
    overflow-y: visible;
}

.user-registration-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.user-registration-header h1 {
    font-size: 22px;
    color: var(--gray-800);
    margin: 0 0 8px;
}

.user-registration-subtitle {
    color: var(--gray-600);
    font-size: 14px;
    margin: 0;
    line-height: 1.4;
}

.user-registration-form {
    width: 100%;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 4px;
    color: var(--gray-800);
    font-size: 13px;
    font-weight: 500;
}

.input-wrapper {
    position: relative;
    width: 100%;
}

.input-wrapper i {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--gray-600);
    font-size: 14px;
}

.input-wrapper input,
.input-wrapper select {
    width: 100%;
    padding: 0.75rem 0.75rem 0.75rem 2rem;
    border: 1px solid var(--gray-300);
    border-radius: var(--border-radius);
    font-size: 13px;
    transition: all 0.2s ease;
    background-color: var(--white);
}

.input-wrapper select {
    padding-right: 2rem;
}

.input-wrapper input:focus,
.input-wrapper select:focus {
    outline: none;
    border-color: var(--light-green);
    box-shadow: 0 0 0 3px rgba(82, 183, 136, 0.1);
}

.input-wrapper input::placeholder {
    color: var(--gray-600);
    font-size: 12px;
}

.input-wrapper select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 14px;
}

.error-message,
.success-message {
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 16px;
    font-size: 13px;
    text-align: center;
}

.error-message {
    background: #fee2e2;
    color: #dc2626;
}

.success-message {
    background: #dcfce7;
    color: #166534;
}

.user-registration-button {
    width: 100%;
    padding: 11px;
    background: var(--primary-green);
    color: var(--white);
    border: none;
    border-radius: var(--border-radius);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 8px;
}

.user-registration-button:hover {
    background: var(--light-green);
}

.user-registration-footer {
    margin-top: 20px;
    text-align: center;
}

.user-registration-footer p {
    color: var(--gray-600);
    font-size: 13px;
    margin: 0;
}

.login-link {
    color: var(--primary-green);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
}

.login-link:hover {
    color: var(--light-green);
}

/* Media queries for smaller screens */
@media screen and (max-height: 700px) {
    .user-registration {
        padding: 0.5rem;
        align-items: flex-start;
    }

    .user-registration-container {
        margin: 0.5rem auto;
        padding: 1rem;
    }

    .user-registration-header {
        margin-bottom: 1rem;
    }

    .form-group {
        margin-bottom: 0.75rem;
    }

    .input-wrapper input,
    .input-wrapper select {
        padding: 0.5rem 0.75rem 0.5rem 2rem;
    }
}

@media screen and (max-width: 380px) {
    .user-registration {
        padding: 0.25rem;
    }

    .user-registration-container {
        padding: 0.75rem;
    }

    .input-wrapper input,
    .input-wrapper select {
        font-size: 13px;
        padding: 0.5rem 0.5rem 0.5rem 1.75rem;
    }

    .input-wrapper i {
        font-size: 13px;
        left: 10px;
    }
}
