.alert-message-container {
    display: inline-flex;
    margin-left: 20px;
    vertical-align: middle;
}

.alert-message {
    background: white;
    color: #2c3e50;
    padding: 12px 24px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    gap: 12px;
    opacity: 1;
    transform: translateX(0);
    transition: all 0.4s ease;
    font-weight: 600;
    min-width: 200px;
}

.alert-message.success {
    border-left: 5px solid #2ecc71;
    background: linear-gradient(to right, #f1fff1, white);
}

.alert-message.error {
    border-left: 5px solid #e74c3c;
    background: linear-gradient(to right, #fff1f1, white);
}

.alert-message .alert-icon {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.alert-message.success .alert-icon {
    color: #2ecc71;
}

.alert-message.error .alert-icon {
    color: #e74c3c;
}

.alert-message .alert-text {
    font-size: 15px;
    line-height: 1.4;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #2c3e50;
}

.alert-message.fade-out {
    opacity: 0;
    transform: translateX(20px);
    transition: all 0.6s ease;
}

@keyframes alertIn {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.alert-message {
    animation: alertIn 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}
