.league-management {
    padding: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.create-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.create-button:hover {
    background-color: #45a049;
}

.error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.success-message {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.loading {
    text-align: center;
    padding: 20px;
    color: #666;
}

.no-leagues {
    text-align: center;
    padding: 40px;
    color: #666;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.leagues-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 24px;
    padding: 20px;
}

.league-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    overflow: hidden;
    transition: all 0.3s ease;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.league-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.league-banner {
    width: 100%;
    height: 140px;
    object-fit: cover;
    background-color: #f0f0f0;
}

.league-header {
    padding: 16px;
    margin-top: -40px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    z-index: 1;
}

.league-icon {
    width: 64px;
    height: 64px;
    border-radius: 16px;
    object-fit: cover;
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border: 3px solid white;
}

.league-header h2 {
    margin: 0;
    font-size: 1.25rem;
    color: #333;
    font-weight: 600;
    flex-grow: 1;
}

.league-content {
    padding: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.league-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
}

.league-info:last-child {
    border-bottom: none;
}

.info-label {
    color: #666;
    font-size: 0.9rem;
}

.info-value {
    font-weight: 500;
    color: #333;
}

.status {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
    text-transform: capitalize;
    position: absolute;
    top: 12px;
    right: 12px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.status.active {
    background-color: #e8f5e9;
    color: #2e7d32;
}

.status.inactive {
    background-color: #ffebee;
    color: #c62828;
}

.status.upcoming {
    background-color: #e3f2fd;
    color: #1565c0;
}

.status.archived {
    background-color: #f5f5f5;
    color: #616161;
}

.league-actions {
    padding: 15px;
    display: flex;
    gap: 10px;
    background: #f8f9fa;
    border-top: 1px solid #eee;
}

.league-actions button {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
}

.league-actions button:first-child {
    background-color: #e3f2fd;
    color: #1565c0;
}

.league-actions button:first-child:hover {
    background-color: #bbdefb;
}

.league-actions button:last-child {
    background-color: #4CAF50;
    color: white;
}

.league-actions button:last-child:hover {
    background-color: #45a049;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-header h2 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #666;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.form-group input,
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.form-actions button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-actions button[type="submit"] {
    background-color: #4CAF50;
    color: white;
}

.form-actions button[type="button"] {
    background-color: #f5f5f5;
    color: #333;
}
