.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  z-index: 1000;
  font-size: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.scroll-to-top:hover {
  opacity: 1;
  transform: translateY(-2px);
  transition: all 0.3s ease;
}
