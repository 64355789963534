/* League Home Styles - Sports Betting Theme */
:root {
  --primary-color: #2c5f2d;
  --secondary-color: #234c24;
  --success-color: #34d399;
  --warning-color: #fbbf24;
  --error-color: #ef4444;
  --accent-color: #047857;
  --vip-color: #065f46;
  --text-primary: #111827;
  --text-secondary: #4b5563;
  --bg-primary: #ffffff;
  --bg-secondary: #f9fafb;
  --border-color: #e5e7eb;
  --card-hover: #f3f4f6;
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.05);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.05);
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --font-main: 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
  --font-numbers: 'Roboto Mono', monospace;
  --progress-bg: #d1fae5;
  --progress-fill: #10b981;
}

/* Dark mode variables */
[data-theme='dark'] {
  --text-primary: #e8eaed;
  --text-secondary: #9aa0a6;
  --bg-primary: #202124;
  --bg-secondary: #303134;
  --border-color: #3c4043;
  --card-hover: #303134;
}

body {
  font-family: var(--font-main);
  background: var(--bg-secondary);
  color: var(--text-primary);
  line-height: 1.5;
}

.league-home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  background: var(--bg-primary);
  min-height: 100vh;
}

/* User Stats Container */
.user-stats-container {
  margin-bottom: 2rem;
}

/* User Stats Bar - Betting Style */
.user-stats-bar {
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  padding: 1.25rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  box-shadow: var(--shadow-md);
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  border-radius: var(--radius-sm);
  background: var(--bg-secondary);
  transition: transform 0.2s;
}

.stat-item:hover {
  transform: translateY(-2px);
}

.stat-icon {
  font-size: 1.25rem;
  color: var(--primary-color);
  background: var(--bg-primary);
  padding: 0.5rem;
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-sm);
}

.stat-info {
  flex: 1;
}

.stat-info label {
  color: var(--text-secondary);
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.stat-info span {
  color: var(--text-primary);
  font-family: var(--font-numbers);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
}

/* League Controls - Search Bar */
.league-controls {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
}

.league-search {
  flex: 1;
  min-width: 200px;
  padding: 0.75rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  font-size: 0.875rem;
  transition: all 0.2s;
  background: var(--bg-secondary);
}

.league-search:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.1);
}

.view-my-leagues-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--radius-md);
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s;
  white-space: nowrap;
  text-align: center;
}

.view-my-leagues-btn:hover {
  background: var(--secondary-color);
  transform: translateY(-1px);
}

/* Leagues Grid - Betting Slip Style */
.leagues-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

/* League Card Container */
.league-list-item {
  background: white;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.league-main-info {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.25rem;
  border-bottom: 1px solid var(--border-color);
}

.league-icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: var(--bg-primary);
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.league-details {
  flex: 1;
  min-width: 0;
}

.league-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.league-title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.league-members {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  background: var(--bg-secondary);
  border-radius: var(--radius-sm);
  font-size: 0.875rem;
  white-space: nowrap;
  flex-shrink: 0;
}

.league-description {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin: 0;
  line-height: 1.4;
}

.league-betting {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid var(--border-color);
}

.betting-limits {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.limit-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  padding: 0.75rem;
  background: var(--bg-secondary);
  border-radius: var(--radius-sm);
}

.limit-label {
  font-size: 0.75rem;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.limit-value {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary);
}

.league-actions {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.league-actions .status-badge {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.9rem;
  color: white !important;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.league-actions .status-badge.active {
  background: #2c5f2d !important;
  color: white !important;
}

.league-actions .status-badge.inactive {
  background: #dc3545 !important;
  color: white !important;
}

.league-actions .join-league-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #2c5f2d !important;
  color: white !important;
  width: 100%;
  text-align: center;
}

.league-actions .view-league-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #0066cc !important;
  color: white !important;
  width: 100%;
  text-align: center;
}

.league-actions .join-league-btn:hover:not(:disabled) {
  background: #234c24 !important;
  transform: translateY(-2px);
}

.league-actions .view-league-btn:hover {
  background: #0052a3 !important;
  transform: translateY(-2px);
}

.league-actions .join-league-btn:disabled {
  background: #ccc !important;
  cursor: not-allowed;
}

/* League Footer Section */
.league-footer {
  padding: 1.25rem;
  border-top: 1px solid var(--border-color);
  background: var(--bg-primary);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

/* Empty State */
.league-card.empty {
  border: 2px dashed var(--border-color);
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: var(--bg-secondary);
  min-height: 200px;
}

.empty-state {
  text-align: center;
  color: var(--text-secondary);
}

.empty-state-icon {
  font-size: 2.5rem;
  color: var(--border-color);
  margin-bottom: 1rem;
}

/* Responsive Design */
@media screen and (max-width: 1366px) {
  .league-card {
    min-height: 180px;
  }

  .league-header {
    padding: 1rem;
  }

  .league-icon {
    width: 44px;
    height: 44px;
  }

  .league-icon img,
  .league-icon svg {
    width: 24px;
    height: 24px;
  }

  .league-title {
    font-size: 1rem;
  }

  .league-content {
    padding: 1rem;
    gap: 1rem;
  }

  .league-description {
    font-size: 0.875rem;
  }

  .betting-limits {
    padding: 0.875rem;
    gap: 0.875rem;
  }

  .league-footer {
    padding: 1rem;
  }
}

/* Grid Layout */
.leagues-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin: 1.5rem 0;
}

/* Mobile Responsive */
@media screen and (max-width: 768px) {
  .leagues-grid {
    grid-template-columns: 1fr;
  }

  .league-card {
    min-height: 160px;
  }

  .league-header {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .league-members {
    width: 100%;
    justify-content: center;
  }

  .betting-limits {
    grid-template-columns: 1fr;
  }

  .league-footer {
    flex-direction: column;
  }
}

/* Season Info - Match Ticker Style */
.season-info {
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  padding: 1.25rem;
  margin-bottom: 2rem;
  box-shadow: var(--shadow-md);
}

.season-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.season-icon {
  font-size: 2rem;
  color: var(--primary-color);
  background: var(--bg-secondary);
  padding: 0.75rem;
  border-radius: var(--radius-sm);
}

.season-title h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-primary);
}

.season-dates {
  display: flex;
  gap: 2rem;
  margin-top: 0.75rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
  font-family: var(--font-numbers);
}

.season-countdown {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1rem;
  padding: 0.75rem 1rem;
  background: var(--bg-secondary);
  border-radius: var(--radius-sm);
  color: var(--primary-color);
  font-weight: 600;
  font-family: var(--font-numbers);
}

.countdown-icon {
  animation: pulse 2s infinite;
  font-size: 1.25rem;
}

/* Welcome Guide - VIP Style */
.welcome-guide {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.guide-content {
  background: var(--bg-primary);
  border-radius: var(--radius-lg);
  padding: 1.5rem;
  width: 90%;
  max-width: 600px;
  box-shadow: var(--shadow-lg);
}

.guide-content h2 {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--vip-color);
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.guide-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  background: var(--bg-secondary);
  border-radius: var(--radius-md);
  border: 1px solid var(--border-color);
  transition: all 0.3s;
}

.feature-item:hover {
  transform: translateY(-4px);
  box-shadow: var(--shadow-lg);
  border-color: var(--primary-color);
}

.feature-icon {
  font-size: 1.5rem;
  color: var(--primary-color);
  background: var(--bg-primary);
  padding: 0.75rem;
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-sm);
}

.feature-text h3 {
  margin: 0 0 0.5rem 0;
  color: var(--text-primary);
  font-size: 1.1rem;
  font-weight: 600;
}

.feature-text p {
  margin: 0;
  color: var(--text-secondary);
  font-size: 0.9rem;
  line-height: 1.5;
}

.start-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 0 auto;
  padding: 0.75rem 2rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--radius-md);
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

.start-button:hover {
  background: var(--secondary-color);
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

/* Loading States */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  gap: 1rem;
}

.loading-spinner {
  width: 48px;
  height: 48px;
  border: 4px solid var(--bg-secondary);
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Animations */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Progress Bar Styles */
.progress-container {
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  padding: 1.25rem;
  box-shadow: var(--shadow-md);
}

.progress-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.progress-info span:first-child {
  font-weight: 600;
  font-size: 1rem;
}

.progress-info span:last-child {
  color: var(--accent-color);
  font-family: var(--font-numbers);
  font-weight: 500;
}

.progress-bar {
  height: 8px;
  background: var(--progress-bg);
  border-radius: 4px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: var(--progress-fill);
  border-radius: 4px;
  transition: width 0.3s ease;
}

/* League List Styling */
.leagues-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

/* League List Item Layout */
.league-list-item {
  display: grid;
  grid-template-columns: minmax(250px, 2fr) minmax(160px, 1fr) 120px;
  gap: 1.25rem;
  padding: 1.25rem;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  transition: all 0.2s;
  align-items: center;
  overflow: hidden;
}

/* League Main Info */
.league-main-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.league-icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: var(--bg-primary);
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}

.league-icon img,
.league-icon svg {
  width: 24px;
  height: 24px;
  color: var(--primary-color);
}

.league-details {
  flex: 1;
  min-width: 0;
}

.league-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.league-title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.league-members {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  background: var(--bg-secondary);
  border-radius: var(--radius-sm);
  font-size: 0.875rem;
  white-space: nowrap;
  flex-shrink: 0;
}

.info-icon {
  color: var(--primary-color);
  font-size: 0.875rem;
  flex-shrink: 0;
}

.info-value {
  font-family: var(--font-numbers);
  font-size: 0.8125rem;
  font-weight: 500;
  color: var(--text-secondary);
}

/* Betting Limits */
.league-betting {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.betting-limits {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem 1rem;
  background: var(--progress-bg);
  border-radius: var(--radius-sm);
}

.limit-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  min-width: 0;
}

.limit-label {
  font-size: 0.75rem;
  color: var(--accent-color);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.limit-value {
  font-family: var(--font-numbers);
  font-weight: 600;
  color: #dc3545 !important;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

/* League Actions */
.league-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem;
}

.status-badge {
  width: 100%;
  text-align: center;
  padding: 0.75rem;
  border-radius: var(--radius-sm);
  font-weight: 500;
}

.status-badge.active {
  background: #DCFCE7;
  color: #166534;
}

.status-badge.inactive {
  background: #FEF3C7;
  color: #92400E;
}

.join-league-btn,
.view-league-btn {
  width: 100%;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-radius: var(--radius-sm);
  font-weight: 500;
  transition: all 0.2s;
  text-align: center;
  color: white;
}

/* User's Current League Style */
.league-list-item.current-league {
  background: #F0FDF4;
  border: 1px solid #34D399;
}

.league-list-item.current-league .league-icon {
  border-color: #34D399;
}

.league-list-item.current-league .info-icon {
  color: #34D399;
}

/* Responsive Design - 13/14 inch screens */
@media screen and (max-width: 1366px) {
  .league-list-item {
    grid-template-columns: minmax(200px, 1.5fr) minmax(140px, 1fr) 100px;
    padding: 1rem;
    gap: 1rem;
  }

  .league-icon {
    width: 38px;
    height: 38px;
  }

  .league-icon img,
  .league-icon svg {
    width: 20px;
    height: 20px;
  }

  .league-details {
    max-width: calc(100% - 48px);
  }

  .league-title {
    font-size: 0.875rem;
  }

  .league-members {
    height: 22px;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }

  .info-icon,
  .info-value {
    font-size: 0.75rem;
  }

  .betting-limits {
    padding: 0.625rem;
    gap: 0.5rem;
  }

  .limit-value {
    font-size: 0.8125rem;
  }

  .league-actions {
    width: 100px;
  }

  .status-badge {
    font-size: 0.625rem;
    padding: 0.3125rem;
  }

  .join-league-btn,
  .view-league-btn {
    font-size: 0.75rem;
    padding: 0.4375rem;
  }
}

/* Mobile Responsive */
@media screen and (max-width: 768px) {
  .league-list-item {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }

  .league-main-info {
    width: 100%;
  }

  .league-details {
    max-width: calc(100% - 48px);
  }

  .league-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .league-members {
    margin-top: 0.25rem;
  }

  .betting-limits {
    margin-top: 0.75rem;
    padding: 0.75rem;
  }

  .league-actions {
    flex-direction: row;
    width: 100%;
    padding-top: 0.75rem;
    margin-top: 0.75rem;
    border-top: 1px solid var(--border-color);
    gap: 0.75rem;
  }

  .status-badge,
  .join-league-btn,
  .view-league-btn {
    flex: 1;
    max-width: none;
  }
}

/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  padding: 0;
  background: none;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}

.pagination button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: var(--bg-primary);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 500;
  border-radius: var(--radius-sm);
  cursor: pointer;
  transition: all 0.2s;
  min-width: 120px;
  justify-content: center;
  text-align: center;
  color: white;
}

.pagination button:hover:not(:disabled) {
  background: var(--primary-color);
  color: white;
  transform: translateY(-1px);
}

.pagination button:disabled {
  border-color: var(--border-color);
  color: var(--text-secondary);
  cursor: not-allowed;
  opacity: 0.7;
}

.pagination span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  padding: 0.75rem 1.25rem;
  background: var(--bg-secondary);
  border-radius: var(--radius-sm);
  font-weight: 500;
  color: var(--text-primary);
}

/* Ensure pagination is visible on mobile */
@media screen and (max-width: 768px) {
  .pagination {
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 1rem 0;
    padding: 0;
  }
  
  .pagination button {
    min-width: 100px;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
  }
  
  .pagination span {
    min-width: 120px;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
  }
}

/* Ensure all button text is centered */
button, 
.btn,
[class*="-btn"] {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  color: white !important;
}

.pagination button.prev-btn {
  background: #0066cc !important;
  color: white !important;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--radius-sm);
  font-weight: 500;
  transition: all 0.2s;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination button.next-btn {
  background: #2c5f2d !important;
  color: white !important;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--radius-sm);
  font-weight: 500;
  transition: all 0.2s;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination button.prev-btn:hover:not(:disabled) {
  background: #0052a3 !important;
  transform: translateY(-1px);
}

.pagination button.next-btn:hover:not(:disabled) {
  background: #234c24 !important;
  transform: translateY(-1px);
}

.pagination button:disabled {
  background: #ccc !important;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Add media query for 14" screens */
@media screen and (max-width: 1366px) and (min-height: 768px) {
  .guide-content {
    padding: 1.25rem;
    max-width: 560px;
  }
  
  .guide-content h2 {
    font-size: 1.75rem;
    gap: 0.75rem;
  }
  
  .guide-features {
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .feature-item {
    padding: 0.75rem;
  }
  
  .start-button {
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
  }
}
