/* Reset and Base Styles */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Variables */
:root {
  --header-height: 60px;
  --header-height-desktop: 70px;
  --primary-green: #00ff87;
  --dark-bg: #13141B;
  --white: #ffffff;
  --transition: 0.3s ease;
}

/* Mobile-First Base Styles */
.main-header {
  background: var(--dark-bg);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: var(--header-height);
  z-index: 1000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: var(--transition);
}

.main-header.scrolled {
  background: rgba(19, 20, 27, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.header-container {
  max-width: 1400px;
  height: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Logo Styles */
.logo-link {
  font-size: 1.3rem;
  font-weight: 700;
  color: var(--white);
  text-decoration: none;
  background: linear-gradient(45deg, var(--primary-green), #00ff87);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: transform 0.2s ease;
}

.logo-link:hover {
  transform: scale(1.05);
}

/* Mobile Menu Button */
.mobile-menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.menu-icon,
.menu-icon::before,
.menu-icon::after {
  display: block;
  width: 24px;
  height: 2px;
  background: var(--white);
  position: absolute;
  transition: var(--transition);
}

.menu-icon::before,
.menu-icon::after {
  content: '';
}

.menu-icon::before {
  transform: translateY(-8px);
}

.menu-icon::after {
  transform: translateY(8px);
}

.menu-open .menu-icon {
  background: transparent;
}

.menu-open .menu-icon::before {
  transform: rotate(45deg);
}

.menu-open .menu-icon::after {
  transform: rotate(-45deg);
}

/* Navigation */
.header-nav {
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  background: var(--dark-bg);
  padding: 1rem;
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-nav.active {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.header-nav a,
.header-nav .nav-link {
  color: var(--white);
  text-decoration: none;
  font-size: 1rem;
  padding: 0.8rem 1rem;
  transition: var(--transition);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-weight: 500;
}

.header-nav a:hover,
.header-nav .nav-link:hover {
  background: rgba(255, 255, 255, 0.1);
  color: var(--primary-green);
}

/* User Menu & Auth Buttons */
.header-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.user-menu {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.user-info {
  display: none; /* Hidden on mobile */
}

.header-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  text-decoration: none;
  transition: var(--transition);
  border: none;
  cursor: pointer;
}

/* Default hide all button text on mobile */
.header-btn span {
  display: none;
}

/* Show text for dashboard and logout buttons on mobile */
.dashboard-btn span,
.logout-btn span {
  display: inline-block;
  font-size: 0.85rem;
}

/* Make mobile buttons more compact */
@media (max-width: 767px) {
  .dashboard-btn,
  .logout-btn {
    padding: 0.4rem 0.8rem;
    min-width: auto;
  }

  .dashboard-btn span,
  .logout-btn span {
    font-size: 0.8rem;
  }

  .header-btn i {
    font-size: 1rem;
  }

  /* Adjust spacing between buttons */
  .user-menu {
    gap: 0.4rem;
  }
}

.login-btn,
.register-btn {
  background: rgba(255, 255, 255, 0.1);
  color: var(--white);
}

.login-btn:hover,
.register-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.dashboard-btn {
  background: var(--primary-green);
  color: var(--dark-bg);
}

.dashboard-btn:hover {
  background: #00e676;
  transform: translateY(-1px);
}

.logout-btn {
  background: rgba(255, 69, 58, 0.1);
  color: #ff453a;
}

.logout-btn:hover {
  background: rgba(255, 69, 58, 0.2);
}

/* Tablet Breakpoint (768px) */
@media (min-width: 768px) {
  .main-header {
    height: var(--header-height-desktop);
  }

  .header-container {
    padding: 0 2rem;
  }

  .logo-link {
    font-size: 1.5rem;
  }

  .header-btn span {
    display: inline; /* Show button text */
  }

  .user-info {
    display: flex; /* Show user info */
    align-items: center;
    gap: 0.8rem;
    padding: 0.5rem 1rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 30px;
  }

  .user-icon {
    color: var(--primary-green);
    font-size: 1.2rem;
  }

  .username {
    color: var(--white);
    font-weight: 500;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* Desktop Breakpoint (1024px) */
@media (min-width: 1024px) {
  .mobile-menu-btn {
    display: none;
  }

  .header-nav {
    position: static;
    transform: none;
    opacity: 1;
    visibility: visible;
    padding: 0;
    background: none;
    border: none;
    flex-direction: row;
    gap: 1.5rem;
    margin: 0 2rem;
  }

  .header-nav a,
  .header-nav .nav-link {
    width: auto;
    padding: 0.5rem 1rem;
    background: none;
    text-align: center;
    white-space: nowrap;
  }

  .header-nav a:hover,
  .header-nav .nav-link:hover {
    background: none;
  }

  .username {
    max-width: 200px;
  }

  .header-btn {
    padding: 0.6rem 1.2rem;
  }
}

/* Large Desktop Breakpoint (1280px) */
@media (min-width: 1280px) {
  .header-container {
    padding: 0 2.5rem;
  }

  .logo-link {
    font-size: 1.8rem;
  }

  .header-nav {
    gap: 2rem;
  }

  .username {
    max-width: 250px;
  }
}

/* Add specific breakpoint for 13-14 inch screens */
@media (min-width: 1024px) and (max-width: 1440px) {
  .header-container {
    padding: 0 1rem;
  }

  .logo-link {
    font-size: 1.2rem;
  }

  .header-nav {
    gap: 1rem;
    margin: 0 1rem;
  }

  .header-nav a,
  .header-nav .nav-link {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }

  .user-info {
    padding: 0.4rem 0.8rem;
  }

  .user-icon {
    font-size: 1rem;
  }

  .username {
    max-width: 120px;
    font-size: 0.9rem;
  }

  .header-btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
  }

  .header-btn i {
    font-size: 1rem;
  }
}

/* Specific adjustments for 13-inch screens */
@media (min-width: 1024px) and (max-width: 1280px) {
  .logo-link {
    font-size: 1.1rem;
  }

  .header-nav {
    gap: 0.8rem;
    margin: 0 0.8rem;
  }

  .header-nav a,
  .header-nav .nav-link {
    padding: 0.35rem 0.7rem;
    font-size: 0.85rem;
  }

  .user-info {
    padding: 0.35rem 0.7rem;
  }

  .username {
    max-width: 100px;
    font-size: 0.85rem;
  }

  .header-btn {
    padding: 0.35rem 0.7rem;
    font-size: 0.8rem;
  }

  .header-btn span {
    font-size: 0.8rem;
  }
}

/* Update Sidebar Layout Adjustment */
@media (min-width: 1024px) {
  .main-header {
    width: calc(100% - 250px);
    margin-left: 250px;
  }

  /* Adjust header width for 13-14 inch screens when sidebar is present */
  @media (max-width: 1440px) {
    .main-header {
      width: calc(100% - 220px);
      margin-left: 220px;
    }

    .sidebar-collapsed .main-header {
      width: calc(100% - 70px);
      margin-left: 70px;
    }
  }

  /* Further adjustment for 13-inch screens */
  @media (max-width: 1280px) {
    .main-header {
      width: calc(100% - 200px);
      margin-left: 200px;
    }

    .sidebar-collapsed .main-header {
      width: calc(100% - 60px);
      margin-left: 60px;
    }
  }
}