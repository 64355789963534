/* Reset any global scroll behavior */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;
}

/* Layout Structure */
.layout {
    min-height: 100vh;
    position: relative;
}

/* Sidebar Styles */
.sidebar {
    width: 220px;
    background-color: #2c5f2d;
    height: 100vh;
    padding: 20px;
    color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    overflow-y: auto;
    z-index: 1000;
    transition: all 0.3s ease;
}

/* Main Content Area */
.main-content {
    flex: 1;
    background-color: #f5f5f5;
}

/* Header Styles */
.header-bar {
    background: white;
    padding: 20px 30px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-content h1 {
    margin: 0;
    font-size: 24px;
    color: #333;
    font-weight: 500;
}

/* Content Container */
.page-container {
    padding: 0 30px 30px;
}

/* Generic Page Styles */
.content-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 30px;
    margin-bottom: 30px;
}

/* Form Styles */
.form-container {
    max-width: 800px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: 500;
}

.form-control {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
}

.form-control:focus {
    border-color: #007bff;
    outline: none;
}

/* Button Styles */
.btn {
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-primary {
    background-color: #007bff;
    color: white;
}

.btn-primary:hover {
    background-color: #0056b3;
}

/* Add User Specific Styles */
.add-user {
    max-width: 800px;
    margin: 0 auto;
}

.add-user form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-user label {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.add-user input,
.add-user select {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
}

/* Message Styles */
.message {
    padding: 15px;
    border-radius: 6px;
    margin-top: 20px;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .main-content {
        width: calc(100% - 220px);
    }
}

@media (max-width: 768px) {
    .sidebar {
        transform: translateX(-100%);
    }
    
    .sidebar.active {
        transform: translateX(0);
    }
    
    .main-content {
        margin-left: 0;
        width: 100%;
    }
    
    .page-container {
        padding: 0 20px 20px;
    }
    
    .content-card {
        padding: 20px;
    }
}

/* Dashboard Styles */
.dashboard {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    width: 100%;
    height: 100%;
}

.card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.card-half {
    grid-column: span 1;
}

.card-full {
    grid-column: 1 / -1;
}

/* Table styles */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
}

th {
    background-color: #f8f9fa;
    font-weight: 600;
}

/* Responsive table */
@media (max-width: 1024px) {
    .dashboard {
        grid-template-columns: 1fr;
    }

    table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
    overflow-y: auto;
}

.bet-details-modal {
    background: white;
    border-radius: 12px;
    padding: 24px;
    width: 100%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.stat-item {
    text-align: center;
}

.stat-value {
    font-size: 24px;
    font-weight: bold;
    color: #8BC34A;
}

.stat-label {
    font-size: 14px;
    color: #666;
}

/* Stats Cards */
.card.card-half {
    background: white;
    border: 1px solid #e9ecef;
    border-radius: 15px;
    padding: 25px;
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
}

.card.card-half:hover {
    transform: translateY(-5px);
}

.card.card-half::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: #2c5f2d;
    opacity: 0.8;
}

.card.card-half h2 {
    color: #2c5f2d;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
}

.stats {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.stat-item {
    text-align: center;
    padding: 15px;
    width: 100%;
    background: white;
}

.stat-value {
    font-size: 32px;
    font-weight: 700;
    color: #2c5f2d;
    margin-bottom: 8px;
    line-height: 1.2;
}

.stat-label {
    font-size: 14px;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .card.card-half {
        padding: 20px;
    }

    .stat-value {
        font-size: 28px;
    }

    .stat-label {
        font-size: 12px;
    }
}

/* Table Styles */
table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f5f5f5;
    font-weight: 500;
}

/* Challenge Section Styles */
.challenge-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.challenge-card {
    background: linear-gradient(145deg, #1a1a1a, #2d2d2d);
    border-radius: 15px;
    padding: 20px;
    color: white;
    box-shadow: 0 4px 15px rgba(0,0,0,0.2);
}

.team-matchup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.team-matchup-mini img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: contain;
    margin: 0 5px;
    background: #fff;
    padding: 2px;
}

.team-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
    background: #fff;
    padding: 3px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.bet-teams .team img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: contain;
    background: #fff;
    padding: 3px;
    margin-bottom: 10px;
}

.team-info img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: contain;
    background: #fff;
    padding: 2px;
    vertical-align: middle;
    margin-right: 8px;
}

/* Top Teams Overview */
.card.card-full {
    background: none;
    box-shadow: none;
    padding: 0;
}

.card.card-full h2 {
    color: #2c5f2d;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 2px solid #2c5f2d;
}

.teams-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 25px;
    padding: 10px;
}

.team-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: linear-gradient(145deg, #ffffff, #f5f5f5);
    border-radius: 15px;
    transition: transform 0.3s ease;
    border: 1px solid #eee;
}

.team-card:hover {
    transform: translateY(-5px);
    background: linear-gradient(145deg, #f5f5f5, #ffffff);
}

.team-logo-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
    border: 3px solid #2c5f2d;
    padding: 5px;
    background: white;
}

.team-name {
    font-size: 18px;
    font-weight: 600;
    color: #2c5f2d;
    margin: 10px 0;
    text-align: center;
}

.team-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.user-count {
    font-size: 24px;
    font-weight: 700;
    color: #2c5f2d;
}

.user-label {
    font-size: 14px;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 1px;
}
