.error-alert {
  position: relative;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;
}

.error-alert-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.error-alert-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-alert-type {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.5px;
}

.error-alert-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.error-alert-close:hover {
  opacity: 1;
}

.error-alert-message {
  font-size: 0.95rem;
  line-height: 1.5;
}

.error-alert-details {
  background: rgba(0, 0, 0, 0.05);
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 0.85rem;
  overflow-x: auto;
}

.error-alert-details pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
}

/* Severity-based styles */
.error-alert.info {
  background-color: #e3f2fd;
  border-left: 4px solid #2196f3;
  color: #0d47a1;
}

.error-alert.warning {
  background-color: #fff3e0;
  border-left: 4px solid #ff9800;
  color: #e65100;
}

.error-alert.error {
  background-color: #ffebee;
  border-left: 4px solid #f44336;
  color: #b71c1c;
}

.error-alert.critical {
  background-color: #311b92;
  border-left: 4px solid #ff1744;
  color: #ffffff;
}

/* Animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .error-alert {
    margin: 0.75rem 0;
    padding: 0.75rem;
  }

  .error-alert-message {
    font-size: 0.9rem;
  }

  .error-alert-details {
    font-size: 0.8rem;
  }
} 