.friend-requests-container {
    padding: 30px;
    background: #ffffff;
}

.requests-section {
    margin-bottom: 40px;
}

.requests-section:last-child {
    margin-bottom: 0;
}

.requests-header {
    background: #ffffff;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    margin-bottom: 30px;
}

.requests-header h1 {
    margin: 0;
    color: #2c5f2d;
    font-size: 28px;
}

.requests-count {
    color: #666;
    font-size: 14px;
    display: block;
    margin-top: 5px;
}

.requests-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.request-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.2s ease;
}

.request-header {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid #e0e0e0;
    background: #f8f9fa;
}

.request-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #2c5f2d;
    padding: 3px;
}

.request-avatar img {
    width: 70%;
    height: 70%;
    object-fit: contain;
}

.request-user-info h3 {
    margin: 0;
    color: #2c5f2d;
    font-size: 18px;
}

.favorite-team {
    font-size: 14px;
    color: #666;
    display: block;
    margin-top: 4px;
}

.request-stats {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #e0e0e0;
}

.stat-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.stat-icon {
    font-size: 20px;
    color: #2c5f2d;
}

.stat-details {
    display: flex;
    flex-direction: column;
}

.stat-details label {
    font-size: 12px;
    color: #666;
    text-transform: uppercase;
}

.stat-details span {
    font-size: 16px;
    font-weight: 600;
    color: #2c5f2d;
}

.request-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1px;
    background: #e0e0e0;
}

.request-actions.single-action {
    grid-template-columns: 1fr;
}

.request-actions button {
    padding: 12px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: all 0.3s ease;
}

.accept-btn {
    background: #2c5f2d;
    color: white;
}

.accept-btn:hover {
    background: #1e4620;
}

.reject-btn {
    background: #ffffff;
    color: #dc3545;
}

.reject-btn:hover {
    background: #fff5f5;
}

.cancel-btn {
    background: #ffffff;
    color: #666;
}

.cancel-btn:hover {
    background: #f8f9fa;
    color: #dc3545;
}

/* Loading and Error States */
.requests-loading,
.requests-error,
.no-requests {
    text-align: center;
    padding: 40px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.requests-error {
    color: #dc3545;
}

.no-requests-content h3 {
    color: #333;
    margin: 0 0 10px 0;
}

.no-requests-content p {
    color: #666;
    margin: 0;
}

/* Alert Styles */
.alert {
    padding: 15px 25px;
    border-radius: 10px;
    margin-bottom: 20px;
    animation: slideIn 0.3s ease-out;
}

.alert-success {
    background: rgba(44, 95, 45, 0.1);
    border-left: 4px solid #2c5f2d;
    color: #2c5f2d;
}

.alert-error {
    background: rgba(220, 53, 69, 0.1);
    border-left: 4px solid #dc3545;
    color: #dc3545;
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .friend-requests-container {
        padding: 15px;
    }
    
    .requests-grid {
        grid-template-columns: 1fr;
    }
    
    .request-card {
        max-width: 100%;
    }
    
    .requests-header {
        padding: 20px;
    }
    
    .requests-header h1 {
        font-size: 24px;
    }
} 