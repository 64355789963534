.create-league-container {
    width: 100%;
    padding: 2rem;
}

.create-league-header {
    margin-bottom: 2rem;
}

.create-league-header h1 {
    color: #1a1a1a;
    margin-bottom: 0.5rem;
}

.create-league-header p {
    color: #666;
}

.create-league-form {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 2rem;
}

.form-section {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 8px;
}

.form-section h2 {
    color: #333;
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
}

.media-section {
    background: #fff;
    display: grid;
    gap: 2rem;
}

.media-preview {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 2rem;
    margin-bottom: 1.5rem;
}

.icon-upload, .banner-upload {
    position: relative;
    background: #f8f9fa;
    border-radius: 8px;
    padding: 1rem;
}

.icon-upload {
    width: 300px;
    height: 300px;
}

.banner-upload {
    width: 100%;
    height: 300px;
}

.upload-label {
    display: block;
    cursor: pointer;
    border: 2px dashed #ddd;
    border-radius: 8px;
    transition: all 0.3s ease;
    overflow: hidden;
    height: 100%;
    background: #fff;
}

.upload-label:hover {
    border-color: #007bff;
    background: #f8f9fa;
}

.upload-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    text-align: center;
    width: 100%;
}

.upload-placeholder svg {
    font-size: 2.5rem;
    color: #007bff;
}

.upload-placeholder span {
    font-size: 0.9rem;
    font-weight: 500;
}

.file-input {
    display: none;
}

.icon-preview, .banner-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.color-picker-section {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    margin-top: 1rem;
}

.color-picker-section label {
    margin: 0;
    font-weight: 500;
}

.color-picker-section input[type="color"] {
    -webkit-appearance: none;
    width: 60px;
    height: 40px;
    border: none;
    border-radius: 4px;
    padding: 0;
    cursor: pointer;
}

.color-picker-section input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

.color-picker-section input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
}

.color-preview {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #fff;
    background: var(--selected-color, #007bff);
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: 500;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

.btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 120px;
}

.btn.primary {
    background: #007bff;
    color: white;
}

.btn.primary:hover {
    background: #0056b3;
}

.btn.secondary {
    background: #6c757d;
    color: white;
}

.btn.secondary:hover {
    background: #5a6268;
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.error-message {
    background: #fee;
    color: #c00;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

/* Only apply mobile styles when screen is actually mobile-sized */
@media (max-width: 768px) {
    .create-league-container {
        padding: 1rem;
    }
    
    .create-league-form {
        padding: 1rem;
    }
    
    .form-section {
        padding: 1rem;
    }
    
    .form-row {
        grid-template-columns: 1fr;
    }
    
    .media-preview {
        grid-template-columns: 1fr;
    }
    
    .form-actions {
        flex-direction: column;
    }
    
    .btn {
        width: 100%;
        min-width: unset;
    }
}

@media (max-width: 1200px) {
    .media-preview {
        grid-template-columns: 1fr;
    }

    .icon-upload {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .icon-upload, .banner-upload {
        height: 200px;
    }

    .color-picker-section {
        flex-direction: column;
        align-items: flex-start;
    }
}
