.challenges-page {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    background: #f8f9fa;
    min-height: calc(100vh - 4rem);
}

.challenges-header {
    margin-bottom: 2rem;
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.challenges-header h1 {
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.75rem;
}

.challenges-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.challenges-search-box {
    flex: 1;
    min-width: 250px;
}

.challenges-search-box input {
    padding: 0.75rem 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    font-size: 1rem;
    transition: border-color 0.2s ease;
}

.challenges-search-box input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.challenges-filter-buttons {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.challenges-filter-button {
    padding: 0.75rem 1.25rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
    color: #333;
}

.challenges-filter-button:hover {
    background: #f5f5f5;
    border-color: #c1c1c1;
}

.challenges-filter-button.active {
    background: #007bff;
    color: white;
    border-color: #0056b3;
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.2);
}

.challenges-list {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.challenges-table-container {
    border: 1px solid #e0e0e0;
    border-radius: 8px 8px 0 0;
    overflow: auto;
    background: white;
    margin-bottom: 0;
}

.challenges-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background: white;
    min-width: 800px;
}

.challenges-table th,
.challenges-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.challenges-table th {
    background: #f8f9fa;
    font-weight: 600;
    color: #495057;
    border-bottom: 2px solid #dee2e6;
    position: sticky;
    top: 0;
    z-index: 1;
}

.challenges-table tr:last-child td {
    border-bottom: none;
}

.challenges-table tr:hover {
    background-color: #f8f9fa;
}

.challenges-number-column {
    width: 50px;
    text-align: center !important;
    color: #6c757d;
    font-family: monospace;
    font-size: 0.875rem;
}

.challenges-teams-compact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 8px 0;
    min-width: 300px;
}

.challenges-team-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    min-width: 120px;
}

.challenges-team-logo-small {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.challenges-team-logo-small img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}

.challenges-team-name-compact {
    font-size: 0.9rem;
    color: #2b3035;
    text-align: center;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
}

.challenges-vs-small {
    font-weight: 600;
    color: #6c757d;
    padding: 0 12px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    height: 100%;
    background: #f8f9fa;
    border-radius: 4px;
}

.challenges-odds-compact,
.challenges-goals-compact {
    font-family: monospace;
    background: #f8f9fa;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
}

.challenges-date-compact {
    color: #495057;
    font-size: 0.875rem;
}

.challenges-countdown-compact {
    font-family: monospace;
    color: #dc3545;
    font-weight: 500;
}

.challenges-place-bet-button-small {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: #28a745;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.challenges-place-bet-button-small:hover {
    background: #218838;
    text-decoration: none;
    color: white;
}

.challenges-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    background: white;
    border-radius: 0 0 8px 8px;
    border-top: 1px solid #e0e0e0;
}

.challenges-pagination-button {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #495057;
}

.challenges-pagination-button:hover {
    background: #f5f5f5;
    border-color: #c1c1c1;
}

.challenges-pagination-button.active {
    background: #007bff;
    color: white;
    border-color: #0056b3;
}

.challenges-pagination-button:disabled {
    background: #f8f9fa;
    color: #adb5bd;
    cursor: not-allowed;
    border-color: #ddd;
}

.challenges-pagination-ellipsis {
    color: #6c757d;
    padding: 0 0.5rem;
}

.status-badge {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: capitalize;
}

.status-badge.open {
    background-color: #e8f5e9;
    color: #2e7d32;
}

.status-badge.closed {
    background-color: #ffebee;
    color: #c62828;
}

.status-badge.settled {
    background-color: #e3f2fd;
    color: #1565c0;
}

.status-badge.expired {
    background-color: #fafafa;
    color: #616161;
}

.no-data {
    padding: 3rem;
    text-align: center;
    color: #6c757d;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background: #f8f9fa;
    font-size: 1.1rem;
}

.loading {
    padding: 3rem;
    text-align: center;
    color: #6c757d;
    font-size: 1.1rem;
}

.error-message {
    padding: 1rem;
    background: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    margin-bottom: 1rem;
}
