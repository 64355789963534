.league-season-management {
    padding: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 20px;
}

.back-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
}

.back-button:hover {
    text-decoration: underline;
}

.header h1 {
    margin: 0;
    color: #333;
}

.create-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s;
}

.create-button:hover {
    background-color: #218838;
}

.seasons-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.season-card {
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.season-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.season-header h3 {
    margin: 0;
    color: #333;
}

.status {
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.85em;
    font-weight: 600;
}

.status.upcoming {
    background-color: #cce5ff;
    color: #004085;
}

.status.active {
    background-color: #d4edda;
    color: #155724;
}

.status.completed {
    background-color: #e2e3e5;
    color: #383d41;
}

.status.cancelled {
    background-color: #f8d7da;
    color: #721c24;
}

.season-details {
    margin: 15px 0;
}

.detail-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    color: #555;
}

.label {
    font-weight: 600;
}

.season-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.action-button {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s;
}

.action-button.start {
    background-color: #28a745;
    color: white;
}

.action-button.start:hover {
    background-color: #218838;
}

.action-button.end {
    background-color: #dc3545;
    color: white;
}

.action-button.end:hover {
    background-color: #c82333;
}

.action-button.view {
    background-color: #007bff;
    color: white;
}

.action-button.view:hover {
    background-color: #0056b3;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.season-modal {
    background: white;
    border-radius: 10px;
    padding: 30px;
    width: 90%;
    max-width: 500px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
}

.form-group {
    margin-bottom: 20px;
}

.form-row {
    display: flex;
    gap: 20px;
}

.form-row .form-group {
    flex: 1;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: 600;
}

input[type="text"],
input[type="number"],
input[type="datetime-local"] {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.cancel-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.loading {
    text-align: center;
    padding: 40px;
    color: #666;
    font-size: 18px;
}
