/* Unique Team Logo Styles for Challenge Page */
.join-challenge .challenge-team-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  border: none;
  background: none;
  padding: 0;
}

/* Remove any pseudo-elements that might interfere */
.join-challenge .challenge-team-wrapper::before,
.join-challenge .challenge-team-wrapper::after {
  display: none;
  content: none;
}

.join-challenge .challenge-team-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  transition: transform 0.2s ease;
  margin: 0;
  position: relative;
  z-index: 2;
  border: none;
  background: none;
  padding: 0;
}

.join-challenge .challenge-team-logo:hover {
  transform: scale(1.05);
}

.join-challenge .challenge-team-name {
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  line-height: 1.2;
  max-width: 200px;
  word-wrap: break-word;
  padding: 0;
  margin: 0.5rem 0 0;
  position: relative;
  z-index: 2;
}

.join-challenge .opponent-name {
  font-size: 0.9rem;
  color: #ffffff;
  opacity: 0.8;
  text-align: center;
}

.join-challenge .opponent-pick {
  background: #ff6b6b;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 0.25rem;
  text-align: center;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .join-challenge .challenge-team-logo {
    width: 80px;
    height: 80px;
  }
  
  .join-challenge .challenge-team-name {
    font-size: 0.9rem;
    max-width: 150px;
  }

  .join-challenge .opponent-name {
    font-size: 0.8rem;
  }

  .join-challenge .opponent-pick {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 480px) {
  .join-challenge .challenge-team-logo {
    width: 60px;
    height: 60px;
  }
  
  .join-challenge .challenge-team-name {
    font-size: 0.8rem;
    max-width: 120px;
  }

  .join-challenge .opponent-name {
    font-size: 0.7rem;
  }

  .join-challenge .opponent-pick {
    font-size: 0.6rem;
  }
}
