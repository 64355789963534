/* League Home Container */
.league-home-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* User Stats Bar */
.user-stats-bar {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 20px;
}

.stat-item {
    display: flex;
    align-items: center;
    padding: 15px;
    background: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #eee;
}

.stat-item:last-child {
    border-right: none;
}

.stat-icon {
    font-size: 24px;
    margin-right: 10px;
    color: #007bff;
}

.stat-info {
    display: flex;
    flex-direction: column;
}

.stat-info label {
    font-size: 12px;
    color: #666;
}

.stat-info span {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

/* Progress Bar */
.progress-bar-container {
    flex: 1;
    max-width: 200px;
    margin-left: 20px;
}

.progress-bar {
    width: 100%;
    height: 8px;
    background: #eee;
    border-radius: 4px;
    overflow: hidden;
    margin: 5px 0;
}

.progress {
    height: 100%;
    background: #007bff;
    transition: width 0.3s ease;
}

/* Season Info */
.season-info {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.season-details {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.days-remaining {
    color: #28a745;
    font-weight: bold;
}

/* League Controls */
.league-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.league-search {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 300px;
    font-size: 14px;
}

.view-my-leagues-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.view-my-leagues-btn:hover {
    background: #0056b3;
}

/* Leagues Grid */
.leagues-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

/* League Card */
.league-card {
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    overflow: hidden;
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 400px;
}

.league-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.league-banner {
    width: 100%;
    height: 140px;
    position: relative;
    background: linear-gradient(45deg, #007bff, #00a5ff);
}

.league-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.9;
}

.league-icon {
    width: 80px;
    height: 80px;
    margin: -40px auto 0;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    position: relative;
    border: 4px solid white;
}

.league-icon img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.league-card h3 {
    text-align: center;
    margin: 15px 0;
    color: #333;
    padding: 0 15px;
    font-size: 1.4rem;
}

.league-card p {
    color: #666;
    font-size: 0.95rem;
    padding: 0 20px;
    margin-bottom: 20px;
    line-height: 1.5;
    flex-grow: 1;
}

.league-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 15px 20px;
    border-top: 1px solid #eee;
    font-size: 0.9rem;
    background: #f8f9fa;
}

.betting-limits {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 15px 20px;
    background: #f1f3f5;
    font-size: 0.9rem;
    color: #495057;
}

.join-league-btn {
    width: 100%;
    padding: 15px;
    border: none;
    background: #28a745;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.join-league-btn:hover:not(:disabled) {
    background: #218838;
    transform: translateY(-2px);
}

.join-league-btn:disabled {
    background: #6c757d;
    cursor: not-allowed;
}

/* Welcome Guide */
.welcome-guide {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.guide-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
}

.guide-content h2 {
    margin-bottom: 20px;
    color: #333;
}

.guide-content ul {
    margin: 20px 0;
    padding-left: 20px;
}

.guide-content li {
    margin-bottom: 10px;
    color: #666;
}

.guide-content button {
    width: 100%;
    padding: 12px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

.guide-content button:hover {
    background: #0056b3;
}

/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
}

.pagination button {
    padding: 8px 16px;
    border: 1px solid #007bff;
    background: white;
    color: #007bff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.pagination button:hover:not(:disabled) {
    background: #007bff;
    color: white;
}

.pagination button:disabled {
    border-color: #ccc;
    color: #ccc;
    cursor: not-allowed;
}

/* Success Message */
.success-message {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #28a745;
    color: white;
    padding: 15px 25px;
    border-radius: 5px;
    animation: slideIn 0.3s ease-out;
    z-index: 1000;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Loading and Error States */
.loading, .error-message, .no-leagues {
    text-align: center;
    padding: 40px;
    color: #666;
    font-size: 18px;
}

.error-message {
    color: #dc3545;
}

/* Responsive Design */
@media (max-width: 768px) {
    .user-stats-bar {
        flex-direction: column;
        gap: 15px;
    }

    .stat-item {
        border-right: none;
        border-bottom: 1px solid #eee;
        padding: 10px 0;
        width: 100%;
    }

    .stat-item:last-child {
        border-bottom: none;
    }

    .progress-bar-container {
        max-width: 100%;
        margin-left: 0;
    }

    .league-controls {
        flex-direction: column;
        gap: 15px;
    }

    .league-search {
        width: 100%;
    }

    .leagues-grid {
        grid-template-columns: 1fr;
    }
}

.member-status {
    margin-top: 1rem;
    text-align: center;
}

.status-badge {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.status-badge.active {
    background-color: #4CAF50;
    color: white;
}

.status-badge.inactive {
    background-color: #f44336;
    color: white;
}

.view-league-btn {
    background-color: #2196F3;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s;
}

.view-league-btn:hover {
    background-color: #1976D2;
}

.balance-warning {
    margin-top: 0.5rem;
    color: #f44336;
    font-size: 0.9rem;
    text-align: center;
}

.join-league-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.8;
}

/* Add error message styling */
.error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 1rem;
    border-radius: 4px;
    margin: 1rem 0;
    text-align: center;
    border: 1px solid #ef9a9a;
}

/* Add success message styling */
.success-message {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 1rem;
    border-radius: 4px;
    margin: 1rem 0;
    text-align: center;
    border: 1px solid #a5d6a7;
}
