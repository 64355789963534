/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #f5f5f5;
  overflow: hidden;
}

/* Main Layout */
.user-layout {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #ffffff; /* White background */
}

/* Sidebar Styles */
.user-dashboard-sidebar {
  width: 280px;
  background: #1a1c23; /* Keep sidebar dark */
  color: #fff;
  height: 100vh;
  position: fixed;
  z-index: 1; /* Lower than main container */
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  overflow-x: hidden;
  transition: width 0.3s ease;
}

.user-dashboard-sidebar.collapsed {
  width: 70px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
}

.sidebar-toggle {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 8px;
  margin-left: auto;
}

.toggle-icon {
  font-size: 18px;
  transition: transform 0.3s ease;
}

.user-dashboard-sidebar.collapsed .toggle-icon {
  font-size: 20px;
  padding: 2px;
}

.user-dashboard-sidebar.collapsed .sidebar-header {
  justify-content: center;
  padding: 15px 10px;
}

.user-dashboard-logo {
  padding: 0;
  text-align: left;
  border: none;
  background: transparent;
}

.user-dashboard-logo a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-dashboard-profile {
  padding: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.02);
}

.profile-section {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
  padding: 5px;
}

.profile-icon {
  font-size: 20px;
  opacity: 0.9;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-name {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-balance {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.balance-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  font-size: 15px;
  height: 36px;
}

.balance-icon {
  font-style: normal;
  opacity: 0.9;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Collapsed state adjustments */
.user-dashboard-sidebar.collapsed .profile-section {
  justify-content: center;
  padding: 5px 0;
}

.user-dashboard-sidebar.collapsed .profile-icon {
  margin: 0;
}

.user-dashboard-sidebar.collapsed .balance-item {
  justify-content: center;
  padding: 8px 0;
}

.user-dashboard-sidebar.collapsed .balance-icon {
  margin: 0;
  font-size: 16px;
}

.user-dashboard-sidebar.collapsed .profile-balance {
  gap: 5px;
}

/* Hover tooltips for balance items in collapsed state */
.user-dashboard-sidebar.collapsed .balance-item {
  position: relative;
}

.user-dashboard-sidebar.collapsed .balance-item:hover::after {
  content: attr(title);
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  margin-left: 10px;
  z-index: 1000;
}

.user-dashboard-nav {
  flex: 1;
  padding: 20px 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.user-dashboard-nav::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.dashboard-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dashboard-nav-list li {
  margin: 2px 0;
}

.dashboard-nav-list li a {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  color: #fff;
  text-decoration: none;
  transition: all 0.2s ease;
  font-size: 15px;
  opacity: 0.7;
  gap: 12px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.menu-text {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-notification {
  background: #ff4757;
  color: #fff;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
}

.dashboard-nav-list li a:hover {
  background: rgba(255, 255, 255, 0.1);
  opacity: 1;
}

.dashboard-nav-list li.active a {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  opacity: 1;
  position: relative;
}

.dashboard-nav-list li.active a::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: #007bff;
}

.nav-icon {
  font-style: normal;
  font-size: 20px;
  opacity: 0.9;
  min-width: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-link-with-badge {
  position: relative;
  padding-right: 40px !important;
}

.notification-badge {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: #ff4757;
  color: #fff;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.nav-badge {
  background: #ff4757;
  color: #fff;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 11px;
  margin-left: auto;
}

.user-dashboard-footer {
  padding: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.dashboard-logout-btn {
  width: 100%;
  padding: 12px;
  background: rgba(255, 59, 59, 0.15);
  border: none;
  border-radius: 8px;
  color: #ff3b3b;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.2s ease;
}

.dashboard-logout-btn:hover {
  background: rgba(255, 59, 59, 0.25);
}

/* Main Content Area */
.main-container {
  margin-left: 280px;
  width: calc(100% - 280px);
  min-height: 100vh;
  background: #f5f6fa;
  transition: all 0.3s ease;
}

.main-container.expanded {
  margin-left: 70px;
  width: calc(100% - 70px);
}

/* Remove scrollbars from body */
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* Custom styling for the main content area */
.user-content {
  padding: 20px;
  height: calc(100vh - 80px);
}

/* Header Styles */
.user-header {
  height: 70px;
  background: #2c5f2d;
  padding: 0 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 2px 2px 0 2px;
  border-radius: 12px;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.balances {
  display: flex;
  gap: 1rem;
}

.balance {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.balance:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.balance.naira {
  color: #2ecc71;
}

.balance.fancoins {
  color: #f1c40f;
}

.logout-button {
  background: white;
  color: #2c5f2d;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logout-button:hover {
  background: #f8f9fa;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Content Area */
.user-content {
  flex: 1;
  padding: 2rem;
  background: #f5f5f5;
  overflow-y: auto;
  scrollbar-width: thin;
  margin: 1rem 2px 2rem 2px;
  border-radius: 12px;
}

.user-content::-webkit-scrollbar {
  width: 8px;
}

.user-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.user-content::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.user-content::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Mobile Styles */
@media (max-width: 1024px) {
  .user-dashboard-sidebar {
    width: 180px;
  }

  .main-container {
    margin-left: 0;
  }

  .sidebar-toggle {
    display: block;
  }
}

@media (max-width: 768px) {
  .user-header {
    padding: 0 1rem;
  }

  .balances {
    flex-direction: column;
    gap: 0.5rem;
  }

  .user-content {
    padding: 1rem;
  }
}

/* Responsive adjustments for 14-inch laptops */
@media (max-width: 1440px) {
  .user-dashboard-sidebar {
    width: 240px;
  }
  
  .main-container {
    margin-left: 240px;
    width: calc(100% - 240px);
  }
  
  .main-container.expanded {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
}

@media (max-width: 1366px) { /* 14-inch */
  .user-dashboard-sidebar {
    width: 220px;
  }
  
  .main-container {
    margin-left: 220px;
    width: calc(100% - 220px);
  }
  
  .user-dashboard-sidebar.collapsed {
    width: 60px;
  }
  
  .main-container.expanded {
    margin-left: 60px;
    width: calc(100% - 60px);
  }

  .dashboard-nav-list li a {
    padding: 10px 15px;
    font-size: 14px;
  }

  .nav-icon {
    font-size: 18px;
    min-width: 20px;
  }

  .balance-item {
    padding: 8px 12px;
    font-size: 14px;
  }

  .profile-name {
    font-size: 14px;
  }
}

@media (max-width: 1280px) { /* 13-inch */
  .user-dashboard-sidebar {
    width: 200px;
  }
  
  .main-container {
    margin-left: 200px;
    width: calc(100% - 200px);
  }
  
  .main-container.expanded {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
}

/* Adjust notification badges in collapsed state */
.user-dashboard-sidebar.collapsed .notification-badge {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 16px;
  height: 16px;
  font-size: 10px;
}

.user-dashboard-sidebar.collapsed .menu-notification {
  position: absolute;
  right: 5px;
  min-width: 16px;
  height: 16px;
  font-size: 10px;
  padding: 0;
}

/* Hover tooltips for collapsed state */
.user-dashboard-sidebar.collapsed .dashboard-nav-list li a {
  position: relative;
}

.user-dashboard-sidebar.collapsed .dashboard-nav-list li a:hover::after {
  content: attr(title);
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  margin-left: 10px;
  z-index: 1000;
}

/* Menu tooltips for collapsed state */
.user-dashboard-sidebar.collapsed .dashboard-nav-list li a::after {
  content: attr(title);
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  white-space: nowrap;
  margin-left: 10px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

.user-dashboard-sidebar.collapsed .dashboard-nav-list li a:hover::after {
  opacity: 1;
  visibility: visible;
}

/* Adjust notification badge position in collapsed state */
.user-dashboard-sidebar.collapsed .notification-badge {
  right: 5px;
  min-width: 18px;
  height: 18px;
  font-size: 11px;
}

.user-dashboard-sidebar.collapsed .menu-notification {
  position: absolute;
  right: 5px;
  min-width: 18px;
  height: 18px;
  font-size: 11px;
  padding: 0;
  margin: 0;
}

/* Ensure content fills available width */
.dashboard-content {
  width: 100%;
  padding: 20px;
  overflow-x: hidden;
}

/* Add these new mobile-specific styles at the top */
/* Mobile Header */
.mobile-header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: var(--dark-bg);
  padding: 0 1rem;
  z-index: 1000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  align-items: center;
  justify-content: space-between;
}

.mobile-menu-toggle {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-logo {
  font-size: 1.2rem;
  font-weight: bold;
}

.mobile-logo a {
  color: #fff;
  text-decoration: none;
  background: linear-gradient(45deg, var(--primary-green), #00ff87);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mobile-user-info {
  display: flex;
  align-items: center;
}

.mobile-balance {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #fff;
}

/* Sidebar Overlay */
.sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .mobile-header {
    display: flex;
  }

  .mobile-view .sidebar-overlay {
    display: block;
  }

  .user-dashboard-sidebar {
    position: fixed;
    top: 60px; /* Height of mobile header */
    left: -280px; /* Initial position off-screen */
    height: calc(100vh - 60px);
    width: 280px;
    transition: transform 0.3s ease;
    z-index: 999;
  }

  .user-dashboard-sidebar.mobile.open {
    transform: translateX(280px);
  }

  /* Show menu text in mobile view */
  .user-dashboard-sidebar.mobile span,
  .user-dashboard-sidebar.mobile .menu-text,
  .user-dashboard-sidebar.mobile .profile-name,
  .user-dashboard-sidebar.mobile .balance-item span {
    display: inline-block !important;
    opacity: 1 !important;
    visibility: visible !important;
    white-space: nowrap;
  }

  /* Adjust menu items for mobile */
  .user-dashboard-sidebar.mobile .dashboard-nav-list li a {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    gap: 12px;
  }

  .user-dashboard-sidebar.mobile .nav-icon {
    min-width: 24px;
    display: flex;
    justify-content: center;
  }

  /* Adjust profile section for mobile */
  .user-dashboard-sidebar.mobile .profile-section {
    padding: 15px 20px;
  }

  .user-dashboard-sidebar.mobile .balance-item {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  /* Ensure proper text visibility */
  .user-dashboard-sidebar.mobile .profile-name,
  .user-dashboard-sidebar.mobile .balance-item span {
    color: var(--white);
    font-size: 0.9rem;
  }

  .main-container {
    margin-left: 0 !important;
    width: 100% !important;
    margin-top: 60px; /* Account for mobile header */
  }

  .dashboard-header {
    display: none;
  }

  /* Adjust spacing for mobile */
  .user-dashboard-nav {
    padding: 10px 0;
  }

  /* Hide desktop toggle button on mobile */
  .sidebar-toggle {
    display: none;
  }
}
