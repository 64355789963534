.old-sidebar {
  width: 300px;
  background: var(--primary-green);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  transition: width 0.3s ease;
}

.old-sidebar.collapsed {
  width: 60px !important;
}

.sidebar-toggle {
  position: absolute;
  right: -15px;
  top: 20px;
  width: 30px;
  height: 30px;
  background: var(--primary-green);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
  transition: all 0.3s ease;
}

.sidebar-toggle:hover {
  background: rgba(255, 255, 255, 0.1);
}

.old-sidebar.collapsed .league-item {
  justify-content: center;
  padding: 12px 0;
}

.old-sidebar.collapsed .league-icon {
  margin-right: 0;
}

.old-sidebar.collapsed .league-name {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: var(--primary-green);
  padding: 8px 12px;
  border-radius: 0 4px 4px 0;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 3;
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.1);
}

.old-sidebar.collapsed .league-item:hover .league-name {
  opacity: 1;
  visibility: visible;
}

.old-sidebar.collapsed .view-all-btn {
  justify-content: center;
  padding: 12px 0;
}

.old-sidebar.collapsed .view-all-btn span {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: var(--primary-green);
  padding: 8px 12px;
  border-radius: 0 4px 4px 0;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 3;
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.1);
}

.old-sidebar.collapsed .view-all-btn:hover span {
  opacity: 1;
  visibility: visible;
}

.sidebar-header {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-header h2 {
  color: var(--white);
  font-size: 1.1rem;
  font-weight: 600;
}

.leagues-nav {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.old-sidebar .league-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 1rem;
  text-decoration: none;
  transition: all 0.2s ease;
  position: relative;
}

.old-sidebar .league-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.old-sidebar .league-item:hover .league-icon {
  background: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.old-sidebar .league-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.05) !important;
  margin: 0 !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  padding: 5px !important;
}

.old-sidebar .league-icon-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 2px;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
}

.league-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.league-name {
  font-size: 13px;
  font-weight: 500;
  color: var(--white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-align: left;
  width: 100%;
  padding-left: 1px;
}

.league-min-bet {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  padding: 2px 6px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-align: left;
  align-self: flex-start;
  margin-left: 1px;
}

.view-all-btn {
  margin: 1rem;
  padding: 0.75rem;
  background: var(--white);
  color: var(--primary-green);
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  width: calc(100% - 2rem);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  position: relative;
  z-index: 10;
}

.view-all-btn svg {
  display: block;
  min-width: 20px;
  width: 20px;
  height: 20px;
}

.view-all-btn:hover {
  background: rgba(255, 255, 255, 0.9);
  transform: translateY(-1px);
}

.old-sidebar.collapsed .view-all-btn {
  width: 40px;
  height: 40px;
  min-width: 40px;
  padding: 8px;
  margin: 1rem auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  position: relative;
  z-index: 10;
}

.old-sidebar.collapsed .view-all-btn svg {
  display: block;
  min-width: 20px;
  width: 20px;
  height: 20px;
}

.old-sidebar.collapsed .view-all-text {
  display: none;
}

.user-section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.user-avatar {
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.1);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.user-name {
  color: var(--white);
  font-weight: 500;
}

.auth-section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.sidebar-btn {
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.login-btn, .dashboard-btn {
  background: var(--white);
  color: var(--primary-green);
}

.register-btn, .logout-btn {
  background: rgba(255, 255, 255, 0.1);
  color: var(--white);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.leagues-nav .toggle-btn {
  width: 100%;
  padding: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: auto;
}

.leagues-nav .toggle-btn:hover {
  background: rgba(0, 255, 135, 0.1);
}

.leagues-nav .toggle-btn .toggle-icon {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  flex-shrink: 0;
}

.leagues-nav .toggle-btn .toggle-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.leagues-nav .toggle-btn:hover .toggle-icon {
  background: rgba(0, 255, 135, 0.2);
}

.old-sidebar.collapsed .toggle-btn {
  width: 100%;
  justify-content: center;
}

.old-sidebar.collapsed .toggle-text {
  display: none;
}

.layout-content {
  margin-left: 300px;
  transition: margin-left 0.3s ease;
  width: calc(100% - 300px);
}

.old-sidebar.collapsed + .layout-content {
  margin-left: 80px;
  width: calc(100% - 80px);
}

@media (max-width: 1440px) {
  .old-sidebar:not(.collapsed) {
    width: 300px;
  }

  .old-sidebar:not(.collapsed) .league-info {
    display: flex;
  }

  .old-sidebar.collapsed {
    width: 80px;
  }

  .old-sidebar.collapsed .league-item:hover::after {
    display: block;
  }

  .old-sidebar.collapsed + .layout-content {
    margin-left: 80px;
    width: calc(100% - 80px);
  }
}

@media (max-width: 1366px) {
  .old-sidebar {
    width: 80px;
  }
  
  .old-sidebar .league-item {
    padding: 10px;
    justify-content: center;
  }

  .old-sidebar .league-item:hover::after {
    content: attr(title);
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    background: var(--primary-green);
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 1001;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
  }
  
  .old-sidebar .league-info {
    display: none;
  }
  
  .old-sidebar .view-all-btn {
    padding: 10px;
    font-size: 16px;
  }
  
  .old-sidebar .sidebar-btn {
    padding: 10px;
    justify-content: center;
  }
  
  .old-sidebar .sidebar-btn i {
    margin: 0;
  }
  
  .layout-content {
    margin-left: 80px;
    width: calc(100% - 80px);
  }
}

@media (max-width: 768px) {
  .old-sidebar {
    width: 60px;
  }
  
  .old-sidebar .league-icon {
    width: 32px !important;
    height: 32px !important;
  }
  
  .layout-content {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
}

@media (max-width: 768px) {
  .old-sidebar {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .old-sidebar {
    width: 80px;
  }

  .old-sidebar .league-item {
    padding: 10px;
    justify-content: center;
  }

  .old-sidebar .league-icon {
    margin: 0 !important;
  }

  .old-sidebar .league-info {
    display: none;
  }

  .old-sidebar .view-all-btn {
    padding: 10px;
    font-size: 16px;
  }

  .old-sidebar .sidebar-header h2 {
    font-size: 1rem;
  }

  .old-sidebar .league-item:hover::after {
    content: attr(title);
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    background: var(--primary-green);
    padding: 8px 12px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 1001;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .old-sidebar {
    width: 250px;
  }

  .old-sidebar.collapsed {
    width: 70px;
  }

  .sidebar-header {
    padding: 1.2rem;
  }

  .sidebar-header h2 {
    font-size: 1.1rem;
  }

  .league-item {
    padding: 0.7rem 1.2rem;
  }

  .league-icon {
    width: 35px !important;
    height: 35px !important;
    margin-right: 0.8rem;
  }

  .league-name {
    font-size: 0.9rem;
    display: block !important;
  }

  .league-min-bet {
    font-size: 0.75rem;
  }

  .view-all-btn,
  .sidebar-btn {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
  }

  .leagues-nav .toggle-btn {
    padding: 0.8rem;
    gap: 10px;
  }
  
  .leagues-nav .toggle-btn .toggle-icon {
    font-size: 20px;
    width: 35px;
    height: 35px;
  }

  .leagues-nav .toggle-btn .toggle-text {
    font-size: 13px;
  }
}

/* More specific league name styling - add this at the end of the file */
.old-sidebar .leagues-nav .league-item .league-info .league-name {
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-align: left;
  width: 100%;
  padding-left: 1px;
}

/* Update hover state for better visibility */
.old-sidebar .leagues-nav .league-item:hover .league-info .league-name {
  color: #ffffff;
  opacity: 0.9;
}

/* Ensure collapsed state maintains white text */
.old-sidebar.collapsed .league-item:hover .league-name {
  color: #ffffff;
  opacity: 1;
  visibility: visible;
  font-weight: 400;
}

/* Media query adjustments */
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .old-sidebar .leagues-nav .league-item .league-info .league-name {
    font-size: 0.9rem;
    color: #ffffff;
    display: block !important;
  }
}