/* Base styles */
.join-challenge {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.join-challenge h1 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
}

.join-challenge .challenge-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  padding: 2rem 3rem;
  background: linear-gradient(135deg, #1a2a6c, #b21f1f);
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  width: 100%;
  gap: 2rem;
}

.join-challenge .team-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  position: relative;
  z-index: 1;
  flex: 1;
}

.join-challenge .opponent-name {
  font-size: 18px;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  font-style: normal;
}

.join-challenge .opponent-pick {
  background: #ff6b6b;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 500;
}

.join-challenge .vs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  flex: 0 0 auto;
}

.join-challenge .vs {
  font-size: 1.8rem;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  padding: 0 1rem;
  position: relative;
  z-index: 1;
  background: none;
}

.join-challenge .match-details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 10px;
  margin-bottom: 2rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.join-challenge .detail-item {
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.join-challenge .detail-item .label {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.join-challenge .detail-item .value {
  font-size: 1.2rem;
  color: #333;
  font-weight: 600;
  line-height: 1.2;
}

.join-challenge .detail-item.match-type {
  grid-column: 1 / -1;
  width: 100%;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.join-challenge .detail-item.match-type .label {
  color: #666;
  font-size: 0.85rem;
  font-weight: normal;
}

.join-challenge .detail-item.match-type .value {
  color: #333;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: none;
}

.join-challenge .detail-item.odds {
  background: linear-gradient(to bottom, #f1f8ff, #ffffff);
  border: 1px solid #e3f2fd;
}

.join-challenge .detail-item.odds .value {
  color: #1565c0;
  font-size: 1.4rem;
  font-weight: 600;
}

.join-challenge .detail-item.date {
  grid-column: 1 / -1;
  background: linear-gradient(to right, #f9f9f9, #ffffff);
  border: 1px solid #f5f5f5;
}

.join-challenge .bet-form {
  margin-top: 2rem;
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.join-challenge .bet-form select,
.join-challenge .bet-form input {
  width: 100%;
  padding: 1rem;
  margin: 0.5rem 0 1.5rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1.1rem;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.join-challenge .bet-form select:focus,
.join-challenge .bet-form input:focus {
  outline: none;
  border-color: #2196f3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
}

.join-challenge .bet-form label {
  font-weight: 600;
  color: #333;
  font-size: 1.1rem;
  display: block;
  margin-bottom: 0.5rem;
}

.join-challenge .bet-form button {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(135deg, #1a2a6c, #b21f1f);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.join-challenge .bet-form button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.join-challenge .bet-form button:active {
  transform: translateY(0);
}

.join-challenge .potential-returns {
  width: 100%;
  margin: 2rem auto;
  padding: 2rem;
  background: linear-gradient(145deg, #ffffff, #f5f5f5);
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.join-challenge .potential-returns h3 {
  color: #1a2a6c;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #e0e0e0;
}

.join-challenge .returns-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  width: 100%;
  margin: 0 auto;
}

.join-challenge .return-item {
  background: #fff;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #e8e8e8;
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .join-challenge {
    margin: 0;
    padding: 0;
  }

  .join-challenge .challenge-details {
    margin: 0.5rem;
    padding: 1.5rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: calc(100% - 1rem);
    max-width: none;
    border-radius: 5px;
  }

  .join-challenge .team-info {
    padding: 1rem;
    min-width: 200px;
  }
  
  .join-challenge .vs {
    font-size: 1.5rem;
    margin: 0 0.5rem;
    padding: 0 1rem;
  }

  .join-challenge .opponent-name {
    font-size: 14px;
  }

  .join-challenge .match-details-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    margin: 0 -0.5rem;
    border-radius: 0;
    background: transparent;
  }

  .join-challenge .detail-item {
    padding: 1rem;
    border-radius: 12px;
  }

  .join-challenge .detail-item.match-type {
    background: #f5f5f5;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    margin-bottom: 0.5rem;
  }

  .join-challenge .detail-item.match-type .label {
    margin-bottom: 0;
  }

  .join-challenge .bet-form {
    margin-top: 1.5rem;
    padding: 1rem;
    border-radius: 15px;
  }

  .join-challenge .bet-form select,
  .join-challenge .bet-form input {
    padding: 0.875rem;
    font-size: 1rem;
  }

  .join-challenge .bet-form label {
    font-size: 1rem;
  }

  .join-challenge .potential-returns {
    margin: 1.5rem -1rem;
    padding: 1.25rem;
    border-radius: 0;
  }

  .join-challenge .potential-returns h3 {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  .join-challenge .returns-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0 1rem;
  }

  .join-challenge .return-item {
    padding: 1.25rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    min-width: auto;
  }

  .join-challenge .return-item .label {
    margin-bottom: 0;
    font-size: 0.9rem;
  }

  .join-challenge .return-item .value {
    font-size: 1.4rem;
    margin-bottom: 0;
    text-align: right;
  }

  .join-challenge .return-item .currency {
    font-size: 0.85rem;
    text-align: right;
  }

  .join-challenge .return-item:hover {
    transform: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  }

  .join-challenge .return-item .value-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

/* Tablet styles */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .join-challenge .potential-returns {
    margin: 1.5rem -1rem;
    padding: 1.5rem;
    border-radius: 12px;
  }

  .join-challenge .returns-grid {
    gap: 1.5rem;
    padding: 0 1.5rem;
  }

  .join-challenge .return-item {
    padding: 1.5rem 1rem;
  }

  .join-challenge .return-item .value {
    font-size: 1.6rem;
  }
}

/* 14-inch screen styles */
@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .join-challenge .potential-returns {
    margin: 2rem -1rem;
    padding: 1.75rem;
  }

  .join-challenge .returns-grid {
    gap: 1.75rem;
    padding: 0 2rem;
  }

  .join-challenge .return-item {
    padding: 1.75rem 1.25rem;
  }

  .join-challenge .return-item .value {
    font-size: 1.7rem;
  }
}

/* Desktop styles (already defined above) */
@media screen and (min-width: 1025px) {
  .join-challenge {
    margin: 2rem auto;
    max-width: 1200px;
  }
}

/* Responsive adjustments */
@media screen and (max-width: 1200px) {
  .join-challenge .team-info {
    min-width: 300px;
  }
}

@media screen and (max-width: 992px) {
  .join-challenge .team-info {
    min-width: 250px;
  }
}

@media screen and (max-width: 768px) {
  .join-challenge .challenge-details {
    padding: 1.5rem;
    gap: 1rem;
  }

  .join-challenge .team-info {
    min-width: 200px;
    padding: 1rem;
  }
  
  .join-challenge .vs {
    padding: 0 0.5rem;
    font-size: 1.5rem;
  }
}

/* Win return styling */
.join-challenge .return-item.win {
  background: linear-gradient(145deg, #e8f5e9, #fff);
  border-color: #c8e6c9;
}

.join-challenge .return-item.win .value {
  color: #2e7d32;
}

/* Draw return styling */
.join-challenge .return-item.draw {
  background: linear-gradient(145deg, #e3f2fd, #fff);
  border-color: #bbdefb;
}

.join-challenge .return-item.draw .value {
  color: #1565c0;
}

/* Loss return styling */
.join-challenge .return-item.loss {
  background: linear-gradient(145deg, #fafafa, #fff);
  border-color: #e0e0e0;
}

.join-challenge .return-item.loss .value {
  color: #757575;
}

.join-challenge .error-message {
  background: linear-gradient(to right, #ffebee, #ffcdd2);
  color: #c62828;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.join-challenge .success-message {
  background: linear-gradient(to right, #e8f5e9, #c8e6c9);
  color: #2e7d32;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.join-challenge .invite-link {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 6px;
  text-align: center;
}

.join-challenge .invite-link input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
}

.join-challenge .modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
}