body {
      font-family: 'Roboto', sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f5f5f5;
}

.layout {
      display: flex;
}

.main-content {
      flex: 1;
      margin-left: 250px;
      padding: 20px;
      overflow-y: auto;
      min-height: 100vh;
      box-sizing: border-box;
}

.header-bar {
      background-color: #8BC34A;
      color: white;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 999;
}

/* Team Management Page Styles */

.team-management {
    padding: 20px;
}

/* Card Styles */
.team-management .card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Space between cards */
}

.team-management h1, 
.team-management h2 {
    color: #4CAF50; /* Green color for headings */
}

/* Form Styles */
.team-management form {
    display: flex;
    flex-direction: column;
}

.team-management form label {
    margin-top: 10px;
    color: #333;
}

.team-management form input[type="text"],
.team-management form input[type="file"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
}

.team-management form button[type="submit"] {
    padding: 10px 20px;
    background-color: #4CAF50; /* Green button */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
}

/* Existing Teams Table Styles */
.team-management table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.team-management th, 
.team-management td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.team-management th {
    background-color: #f5f5f5;
    font-weight: 500;
}

.team-management .team-logo {
    width: 30px;
    height: 30px;
    object-fit: contain; /* Ensure logo fits within the cell */
    margin-right: 10px;
}

.team-management button {
    background-color: #f44336; /* Red for delete */
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px; /* Space between buttons */
}

/* Error and Success Message Styles */
.team-management .error-message,
.team-management .success-message {
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
}

.team-management .error-message {
    background-color: #f44336; /* Red for error */
    color: white;
}

.team-management .success-message {
    background-color: #4CAF50; /* Green for success */
    color: white;
}

@media (max-width: 768px) {
      .main-content {
          margin-left: 0;
      }
}

/* Base Container Styles */
.team-management-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
}

.team-management-content {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.team-management-card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
}

/* Form Styles */
.team-form {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.file-hint {
  display: block;
  margin-top: 5px;
  color: #666;
  font-size: 12px;
}

/* Button Styles - Increased Specificity */
.team-management-container .submit-button,
.team-management-container .update-button,
.team-management-container .cancel-button,
.team-management-container .delete-button,
.team-management-container .edit-button {
  width: 100%;
  padding: 1rem;
  background-color: #2C5F2D;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.team-management-container .submit-button:hover,
.team-management-container .update-button:hover,
.team-management-container .edit-button:hover {
  background-color: #224924;
  transform: translateY(-2px);
}

.team-management-container .delete-button {
  background-color: #c62828;
}

.team-management-container .delete-button:hover {
  background-color: #b71c1c;
  transform: translateY(-2px);
}

.team-management-container .cancel-button {
  background-color: #666;
}

.team-management-container .cancel-button:hover {
  background-color: #555;
  transform: translateY(-2px);
}

/* Table Styles */
.teams-table-container {
  width: 100%;
  overflow-x: auto;
}

.teams-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}

.teams-table th,
.teams-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.teams-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

/* Team Logo Style */
.team-logo-image {
  width: 50px;
  height: 50px;
  object-fit: contain;
  display: block;
}

/* Action Buttons Container */
.action-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

.team-management-container .action-buttons button {
  flex: 1;
  min-width: 80px;
  max-width: 120px;
}

/* Message Styles */
.error-message,
.success-message {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #ffcdd2;
}

.success-message {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #c8e6c9;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.team-management-container .modal-buttons button {
  flex: 1;
  max-width: none;
}

/* Responsive Styles */
@media (max-width: 1366px) {
  .team-management-container {
    padding: 15px;
  }
  
  .team-management-card {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .team-management-container {
    padding: 10px;
  }
  
  .action-buttons {
    gap: 8px;
  }
  
  .team-management-container .action-buttons button {
    padding: 0.8rem;
    font-size: 0.9rem;
  }
  
  .modal-content {
    width: 95%;
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .team-management-card {
    padding: 10px;
  }
  
  .teams-table {
    min-width: 400px;
  }
  
  .action-buttons {
    gap: 6px;
  }
  
  .team-management-container .action-buttons button {
    padding: 0.7rem;
    font-size: 0.85rem;
  }
}