/* Force reset any conflicting styles */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

/* Main layout container */
.admin-layout {
  display: flex;
  min-height: 100vh;
  background-color: #f5f5f5;
}

/* Main wrapper - adjust margin based on sidebar width */
.main-wrapper {
  flex: 1;
  margin-left: 260px;
  transition: margin-left 0.3s ease;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Collapsed state adjustments */
.admin-layout.sidebar-collapsed .main-wrapper {
  margin-left: 60px;
}

/* Header positioning */
.admin-header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 999;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Content area */
.content {
  flex: 1;
  padding: 2rem;
  background-color: #f5f5f5;
  margin-top: 1rem;
}

/* Main content area */
main {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  min-height: calc(100vh - 180px);
}

/* Footer positioning */
.admin-layout .admin-footer {
  margin-top: auto;
  background: #fff;
  padding: 15px 0;
  border-top: 1px solid #e0e0e0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-wrapper {
    margin-left: 0;
  }

  .admin-layout.sidebar-collapsed .main-wrapper {
    margin-left: 60px;
  }

  .content {
    padding: 1rem;
  }

  main {
    padding: 1rem;
  }
}

/* Content Card Styles */
.content-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
}

/* Form Styles */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

/* Button Styles */
.btn {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-primary {
  background-color: #2c5f2d;
  color: white;
}

.btn-primary:hover {
  background-color: #234b24;
}

/* Message Styles */
.success-message {
  background-color: #d4edda;
  color: #155724;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
}

/* Force scrollbar styling */
.admin-layout .content::-webkit-scrollbar {
  width: 8px !important;
}

.admin-layout .content::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 4px !important;
}

.admin-layout .content::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 4px !important;
}

.admin-layout .content::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

/* Prevent scroll on body when modal is open */
body.modal-open {
  overflow: hidden !important;
}

