.leaderboard-container {
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    max-width: 1200px;
    margin: 20px auto;
}

.leaderboard-container h2 {
    margin-bottom: 30px;
    color: #333;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
}

/* Table Styles */
.leaderboard-table {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
}

.leaderboard-header {
    display: grid;
    grid-template-columns: 80px minmax(150px, 2fr) 1fr 1.2fr 1fr 1fr 1.2fr;
    background-color: #f8f9fa;
    padding: 15px;
    color: #495057;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9em;
    letter-spacing: 0.5px;
    border-bottom: 2px solid #dee2e6;
}

.leaderboard-row {
    display: grid;
    grid-template-columns: 80px minmax(150px, 2fr) 1fr 1.2fr 1fr 1fr 1.2fr;
    padding: 12px 15px;
    align-items: center;
    border-bottom: 1px solid #eee;
    transition: background-color 0.2s ease;
}

.leaderboard-row:hover {
    background-color: #f8f9fa;
}

/* Column Styles */
.rank-column {
    text-align: center;
    font-weight: 700;
    color: #495057;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.rank-icon {
    font-size: 1.2em;
    margin-right: 2px;
}

.rank-number {
    font-weight: 600;
}

.player-column {
    display: flex;
    align-items: center;
    padding-left: 15px;
    font-weight: 500;
    color: #2c3e50;
}

.points-column {
    text-align: center;
    font-weight: 600;
    color: #28a745;
}

.stats-column {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.wins {
    color: #28a745;
    font-weight: 600;
}

.losses {
    color: #dc3545;
    font-weight: 600;
}

.separator {
    color: #6c757d;
    font-weight: 400;
}

.win-rate {
    color: #6c757d;
    font-size: 0.9em;
}

.bets-column,
.challenges-column {
    text-align: center;
    font-weight: 500;
    color: #495057;
}

.streak-column {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.current-streak {
    font-weight: 600;
    color: #28a745;
}

.highest-streak {
    color: #6c757d;
    font-size: 0.9em;
}

/* Loading State */
.leaderboard-loading {
    text-align: center;
    padding: 40px;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Error State */
.leaderboard-error {
    text-align: center;
    padding: 40px;
    color: #dc3545;
}

.retry-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
    transition: all 0.2s;
    font-weight: 500;
}

.retry-button:hover {
    background-color: #2980b9;
}

/* Pagination */
.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    padding: 15px;
}

.pagination-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
    min-width: 100px;
    font-weight: 500;
}

.pagination-button:disabled {
    background-color: #dee2e6;
    cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
    background-color: #2980b9;
}

.page-info {
    font-size: 0.95em;
    color: #6c757d;
    min-width: 150px;
    text-align: center;
    font-weight: 500;
}

.no-data {
    text-align: center;
    padding: 40px;
    color: #6c757d;
    font-style: italic;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin: 20px 0;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .leaderboard-header,
    .leaderboard-row {
        grid-template-columns: 60px minmax(120px, 1.5fr) 1fr 1fr 1fr 1fr 1fr;
        font-size: 0.9em;
        padding: 10px;
    }

    .leaderboard-container h2 {
        font-size: 22px;
    }
}

@media (max-width: 768px) {
    .leaderboard-container {
        margin: 10px;
        padding: 15px;
    }

    .leaderboard-header,
    .leaderboard-row {
        grid-template-columns: 50px minmax(100px, 1.2fr) 1fr 1fr 0.8fr 0.8fr 0.8fr;
        font-size: 0.85em;
        padding: 8px;
    }

    .player-column {
        padding-left: 8px;
    }

    .pagination-button {
        min-width: 80px;
        padding: 8px 12px;
    }
}

@media (max-width: 576px) {
    .leaderboard-container {
        padding: 10px;
        margin: 5px;
    }

    .leaderboard-header,
    .leaderboard-row {
        grid-template-columns: 40px minmax(80px, 1fr) 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr;
        font-size: 0.75em;
        padding: 6px;
    }

    .leaderboard-container h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }
} 