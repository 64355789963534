/* Base Layout */
.welcome {
  min-height: 100vh;
  background-color: #13141B;
  color: #fff;
  padding: 20px 0;
}

/* Add box-sizing rule */
*, *::before, *::after {
  box-sizing: border-box;
}

.welcome__content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Hero Section */
.hero {
  width: 100%;
  height: 350px;
  margin-bottom: 40px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.hero__slider {
  width: 100%;
  height: 100%;
  position: relative;
}

.hero__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0.9;
}

.hero__image:hover {
  opacity: 1;
  transform: scale(1.02);
}

/* Section Common Styles */
.section {
  background: rgba(26, 27, 35, 0.85);
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 35px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding: 0 10px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.section__title {
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.section__link {
  color: #00ff87;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  background: rgba(0, 255, 135, 0.1);
}

.section__link:hover {
  color: #33ff9f;
  background: rgba(0, 255, 135, 0.2);
  transform: translateY(-1px);
}

/* Challenge List */
.challenges-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #000000;
  padding: 20px;
  border-radius: 12px;
}

/* Challenge List Header */
.challenge-list-header {
  display: grid;
  grid-template-columns: 2fr 1fr auto 100px;
  padding: 18px 25px;
  color: #fff;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-weight: 600;
  background: #2A2B36;
  border-radius: 8px 8px 0 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 2px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.header-teams {
  font-weight: 700;
}

.header-odds {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  font-weight: 700;
}

.header-action,
.header-status {
  font-weight: 700;
  text-align: center;
}

/* Challenge Card Optimizations */
.challenge-card {
  display: grid;
  grid-template-columns: minmax(250px, 2fr) minmax(200px, 1fr) auto minmax(80px, 100px);
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  padding: clamp(15px, 2vw, 25px);
  gap: clamp(10px, 1.5vw, 20px);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.challenge-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.5);
}

/* Teams Display Optimization */
.teams-container {
  display: flex;
  align-items: center;
  gap: clamp(15px, 2vw, 25px);
  padding: 0 10px;
  min-width: 0;
}

.team {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 120px;
  max-width: 180px;
}

.team-logo {
  width: clamp(40px, 5vw, 60px);
  height: clamp(40px, 5vw, 60px);
  border-radius: 50%;
  object-fit: cover;
  background: #2A2B36;
  padding: 6px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.team-logo:hover {
  border-color: #00ff87;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 255, 135, 0.2);
}

.team-name {
  font-size: clamp(0.8rem, 1vw, 1rem);
  color: #fff;
  white-space: normal;
  overflow: visible;
  font-weight: 500;
  letter-spacing: 0.3px;
  max-width: none;
  line-height: 1.2;
}

.vs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  min-width: 120px;
}

.vs {
  font-weight: 600;
  color: #9e9e9e;
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.match-type {
  font-size: 0.75rem;
  padding: 6px 12px;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  white-space: nowrap;
  text-align: center;
  min-width: 90px;
  font-weight: 600;
  margin-top: 10px;
  color: #000000;
}

.match-type.full_time {
  background: rgba(0, 255, 135, 0.15);
  border: 1px solid rgba(0, 255, 135, 0.3);
}

.match-type.half_time {
  background: rgba(64, 156, 255, 0.15);
  border: 1px solid rgba(64, 156, 255, 0.3);
}

/* Odds Display Optimization */
    .odds-container {
  display: flex;
      justify-content: space-between;
  gap: 15px;
      padding: 12px;
  background: rgba(26, 27, 35, 0.8);
  border-radius: 8px;
  min-width: 0;
}

.odds-item {
  text-align: center;
  min-width: 60px;
  padding: 6px;
}

.odds-value {
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  padding: 8px 12px;
}

.odds-item:first-child .odds-value {
  color: #00ff87;
  background: rgba(0, 255, 135, 0.1);
}

.odds-item:nth-child(2) .odds-value {
  color: #ffffff;
}

.odds-item:last-child .odds-value {
  color: #ff453a;
  background: rgba(255, 69, 58, 0.1);
}

.odds-value:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.odds-label {
  font-size: 0.8rem;
  color: #9e9e9e;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Action Button */
    .action-container {
  text-align: center;
}

.action-button {
  padding: 12px 20px;
      border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.action-button.place-bet {
  background: linear-gradient(135deg, #00ff87, #00e676);
  color: #13141B;
}

.action-button.place-bet:hover {
  background: linear-gradient(135deg, #33ff9f, #33eb8f);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 255, 135, 0.3);
}

.action-button.login {
  background: linear-gradient(135deg, #409cff, #3b7dff);
  color: #fff;
}

.action-button.login:hover {
  background: linear-gradient(135deg, #66b2ff, #5c99ff);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(64, 156, 255, 0.3);
}

/* Status Display */
.status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
    padding: 12px;
  border-radius: 8px;
  width: 100%;
  min-width: 100px;
  }

.status-badge {
  display: inline-block;
  padding: 6px 14px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  order: 2;
}

.status-badge.live {
  color: #00ff87;
  animation: pulse 2s infinite;
}

.status-badge.expired {
  color: #ff453a;
}

.status-badge.upcoming {
  color: #409cff;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

/* Countdown Display */
.countdown {
  display: flex;
  gap: 6px;
  justify-content: center;
  order: 1;
  margin-bottom: 8px;
}

.countdown span {
  background: rgba(64, 156, 255, 0.15);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
  color: #409cff;
    font-weight: 600;
  min-width: 32px;
  text-align: center;
  border: 1px solid rgba(64, 156, 255, 0.3);
}

/* Recent Bets Optimization */
.recent-bets-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(15px, 2vw, 25px);
  width: 100%;
}

.recent-bet-card {
  background: linear-gradient(145deg, #1E1F28, #252631);
  border-radius: 12px;
  padding: clamp(15px, 2vw, 25px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.recent-bet-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 255, 135, 0.1);
}

.bet-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    gap: 15px;
}

.bet-ref {
  font-family: 'Courier New', monospace;
  font-size: 0.85rem;
  color: #9e9e9e;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background: rgba(255, 255, 255, 0.05);
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.bet-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
}

.bet-amount span {
  font-size: clamp(0.8rem, 1vw, 0.9rem);
  padding: 4px 10px;
  min-width: 80px;
  text-align: right;
  background: rgba(0, 255, 135, 0.1);
  color: #00ff87;
  border: 1px solid rgba(0, 255, 135, 0.3);
  border-radius: 6px;
  white-space: nowrap;
}

.bet-teams {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  padding: 15px 0;
}

.bet-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.bet-team-logo {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
  background: #2A2B36;
  padding: 6px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bet-team-logo:hover {
  border-color: #00ff87;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 255, 135, 0.2);
}

.bet-team-name {
  font-size: 0.95rem;
  text-align: center;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.3px;
}

.bet-vs {
  color: #9e9e9e;
  font-weight: 600;
  font-size: 1.2rem;
  background: rgba(255, 255, 255, 0.05);
  padding: 8px 12px;
  border-radius: 6px;
}

.bet-odds {
  display: flex;
  justify-content: space-between;
  background: #2A2B36;
    padding: 12px;
  border-radius: 8px;
  margin: 10px 0;
}

.bet-odds-item {
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.bet-odds-item:hover {
  transform: scale(1.05);
}

.bet-odds-item:first-child .bet-odds-value {
  color: #00ff87; /* Green for team A win */
}

.bet-odds-item:nth-child(2) .bet-odds-value {
  color: #ffffff; /* White for draw */
}

.bet-odds-item:last-child .bet-odds-value {
  color: #ff453a; /* Red for team B win */
}

.bet-odds-value {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  transition: opacity 0.2s;
}

.bet-odds-value:hover {
  opacity: 0.9;
}

.bet-odds-label {
    font-size: 0.8rem;
  color: #9e9e9e;
  margin-top: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.bet-users {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.bet-user {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.bet-username {
  font-size: 0.9rem;
  font-weight: 500;
  color: #00ff87;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.5px;
}

.bet-amount span {
  font-size: clamp(0.8rem, 1vw, 0.9rem);
  padding: 4px 10px;
  min-width: 80px;
  text-align: right;
  background: rgba(0, 255, 135, 0.1);
  color: #00ff87;
  border: 1px solid rgba(0, 255, 135, 0.3);
  border-radius: 6px;
  white-space: nowrap;
}

/* Add a small indicator before usernames */
.bet-user:before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background: #00ff87;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 255, 135, 0.5);
}

/* Media Queries for 13-14 inch screens */
@media (max-width: 1366px) {
    .challenge-card {
    grid-template-columns: minmax(200px, 1.5fr) minmax(180px, 1fr) auto 80px;
    padding: 20px;
    gap: 15px;
    }

    .teams-container {
  gap: 12px;
    padding: 0 8px;
    }

    .team {
    min-width: 100px;
    max-width: 150px;
    }

    .team-logo {
    width: 45px;
    height: 45px;
    }

    .team-name {
      font-size: 0.85rem;
    max-width: 80px;
}

.odds-container {
    padding: 10px;
  gap: 10px;
  }

  .odds-item {
    min-width: 50px;
    padding: 4px;
}

.odds-value {
  font-size: 0.9rem;
    padding: 6px 10px;
  }

  .recent-bets-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .recent-bet-card {
    padding: 15px;
  }

  .bet-amount span {
  font-size: 0.8rem;
    padding: 4px 8px;
    min-width: 70px;
  }
}

/* Media Queries for smaller screens */
@media (max-width: 1200px) {
  .recent-bets-grid {
    grid-template-columns: repeat(2, 1fr);
}

  .challenge-card {
    grid-template-columns: 1fr;
  gap: 15px;
  }

  .teams-container {
  justify-content: center;
}

.odds-container {
  justify-content: center;
  }

  .bet-amount span {
  text-align: center;
    min-width: 60px;
  }
}

@media (max-width: 768px) {
  .recent-bets-grid {
    grid-template-columns: 1fr;
  }
  
  /* Mobile layout for live challenges */
  .challenge-card {
    display: flex;
    flex-direction: column;
    background: rgba(26, 27, 35, 0.9);
    border-radius: 12px;
    padding: 20px;
    gap: 16px;
    margin-bottom: 15px;
    border: 2px solid rgba(0, 255, 135, 0.2);
    width: 100%;
    overflow: visible;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  .challenge-list-header {
    display: none; /* Hide header on mobile */
  }

  /* Teams section styling */
  .teams-container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 12px;
    padding: 16px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: calc(100% + 8px);
    max-width: calc(100% + 8px);
    margin: 0 -4px;
    border-radius: 10px;
  }

  .team {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 0;
    padding: 12px 8px;
    gap: 12px;
    flex: 1;
  }

  .team-name {
    font-size: 0.9rem;
    color: #fff;
    text-align: center;
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    width: 92%;
    min-width: 0;
    line-height: 1.3;
    padding: 8px 12px;
    margin: 0;
    order: 2;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
  }

  .team-logo {
    width: 64px;
    height: 64px;
    min-width: 64px;
    padding: 6px;
    margin: 0;
    order: 1;
    border: 2px solid rgba(255, 255, 255, 0.2);
  }

  .vs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    gap: 8px;
    width: auto;
    min-width: 0;
  }

  .vs-container .match-type {
    order: 1;
    margin-bottom: 4px;
  }

  .vs-container .vs {
    order: 2;
    margin: 4px 0;
  }

  .vs-container .countdown {
    order: 3;
  }

  .match-type {
    width: auto;
    min-width: 90px;
    text-align: center;
    padding: 6px 12px;
    font-size: 0.8rem;
    white-space: nowrap;
    margin: 4px 0;
    font-weight: 600;
    border-width: 2px;
  }

  .match-type.full_time {
    background: rgba(0, 255, 135, 0.15);
    border: 2px solid rgba(0, 255, 135, 0.3);
  }

  .match-type.half_time {
    background: rgba(64, 156, 255, 0.15);
    border: 2px solid rgba(64, 156, 255, 0.3);
  }

  /* Odds section styling */
  .odds-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    padding: 10px;
    background: rgba(26, 27, 35, 0.8);
    border-radius: 8px;
    width: 100%;
  }

  .odds-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 6px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 6px;
    min-width: 0;
  }

  .odds-value {
    font-size: 0.9rem;
    font-weight: 600;
    color: #fff;
    width: 100%;
    text-align: center;
  }

  /* Action and status section styling */
  .action-container {
    width: 100%;
    padding: 0;
  }

  .action-button {
    width: 100%;
    padding: 12px;
    font-size: 0.85rem;
    border-radius: 8px;
    background: #00ff87;
    color: #000;
    border: none;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .status-container {
    width: 100%;
    padding: 8px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .status-badge {
    width: 100%;
    text-align: center;
    padding: 8px;
    font-size: 0.85rem;
    border-radius: 6px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
  }

  .countdown {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 6px;
    margin: 0;
  }

  .countdown span {
    padding: 4px 6px;
    font-size: 0.75rem;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 4px;
    min-width: 24px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .challenge-card {
    padding: 15px;
  }

  .team {
    width: 100%;
  }

  .team-logo {
    width: 45px;
    height: 45px;
  }

  .vs-container {
    flex-direction: column;
    gap: 10px;
  }

  .match-type {
    width: 100%;
  }

  .status-container {
    flex-direction: column;
  }

  .bet-header {
    flex-direction: column;
    align-items: stretch;
  }

  .bet-ref {
    text-align: center;
  }

  .bet-amount {
    align-items: stretch;
  }

  .bet-amount span {
    text-align: center;
    min-width: auto;
    width: 100%;
  }

  .bet-users {
    flex-direction: column;
  }

  .bet-user {
    max-width: none;
  }

  .odds-container {
    padding: 12px;
  }

  .odds-item {
    min-width: 70px;
  }
}

@media (max-width: 480px) {
  .vs-container,
  .status-container {
    padding: 10px;
  }

  .match-type {
    min-width: auto;
    width: 100%;
  }

  .countdown span {
    min-width: 28px;
    font-size: 0.8rem;
  }

  .team {
    padding: 8px 6px;
    gap: 10px;
  }

  .team-name {
    font-size: 0.85rem;
    min-height: 32px;
    width: 94%;
    padding: 6px 10px;
  }

  .teams-container {
    padding: 12px;
    width: calc(100% + 4px);
    max-width: calc(100% + 4px);
    margin: 0 -2px;
  }
}

/* Responsive Design Improvements */
@media (min-width: 1920px) {
    /* 24-inch and larger screens */
    .welcome__content {
        max-width: 1800px;
    }
    
    .challenge-card {
        padding: 30px;
        gap: 30px;
    }
    
    .team-logo {
        width: 80px;
        height: 80px;
    }
    
    .team-name {
        font-size: 1.1rem;
  }
}