.friends-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;
    background: #ffffff;
    min-height: calc(100vh - 70px);
    overflow-y: visible;
    gap: 30px;
    display: flex;
    flex-direction: column;
}

.friends-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px 0;
}

.friend-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
}

.friend-card:hover {
    transform: translateY(-5px);
}

.friend-banner {
    background: linear-gradient(135deg, #2c5f2d 0%, #1e4620 100%);
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
}

.friend-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    overflow: hidden;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.friend-avatar img {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.friend-info {
    color: #ffffff;
}

.friend-info h3 {
    margin: 0;
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: opacity 0.2s ease;
}

.friend-info h3:hover {
    opacity: 0.8;
}

.friend-stats {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 10px;
}

.stat-item:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 20%;
    height: 60%;
    width: 1px;
    background: #e0e0e0;
}

.stat-label {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 5px;
}

.stat-value {
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
}

.friend-actions {
    padding: 15px;
    border-top: 1px solid #e0e0e0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.friend-actions button {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.friend-actions button svg {
    font-size: 1.1rem;
}

.friend-actions .message-btn {
    background: #2c5f2d;
    color: white;
}

.friend-actions .message-btn:hover {
    background: #234b24;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.friend-actions .unfriend-btn {
    background: #ff3838;
    color: white;
}

.friend-actions .unfriend-btn:hover {
    background: #e60000;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.search-section {
    padding: 20px;
    background: #f8f9fa;
    border-radius: 15px;
    margin-bottom: 20px;
}

.search-container {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.search-input {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
}

.search-type {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.search-type button {
    padding: 8px 15px;
    border: none;
    border-radius: 8px;
    background: #e9ecef;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.search-type button.active {
    background: #2c5f2d;
    color: white;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 30px;
    border-radius: 15px;
    width: 90%;
    max-width: 500px;
}

.modal-header {
    margin-bottom: 20px;
}

.modal-header h2 {
    margin: 0;
    color: #333;
}

.modal-body {
    margin-bottom: 20px;
}

.modal-body textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    min-height: 100px;
    resize: vertical;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
}

.modal-actions button.cancel {
    background: #e9ecef;
}

.modal-actions button.send {
    background: #2c5f2d;
    color: white;
}

.message-status {
    padding: 15px;
    border-radius: 8px;
    margin: 10px 0;
}

.message-status.success {
    background: rgba(40, 167, 69, 0.1);
    border-left: 4px solid #28a745;
    color: #28a745;
}

.message-status.error {
    background: rgba(255, 56, 56, 0.1);
    border-left: 4px solid #ff3838;
    color: #ff3838;
}

.friends-header {
    background: #ffffff;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.friends-title-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.friends-title-section h1 {
    margin: 0;
    color: #2c5f2d;
    font-size: 28px;
}

.friends-count {
    color: #666;
    font-size: 14px;
}

.friends-search-section {
    flex: 1;
    max-width: 500px;
}

.search-wrapper {
    display: flex;
    gap: 10px;
}

.search-wrapper input {
    flex: 1;
    padding: 12px 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    font-size: 14px;
    transition: border-color 0.3s ease;
}

.search-wrapper input:focus {
    outline: none;
    border-color: #2c5f2d;
}

.search-wrapper button {
    padding: 12px 24px;
    background: #2c5f2d;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.search-wrapper button:hover {
    background: #1e4620;
}

.friends-loading {
    text-align: center;
    padding: 40px;
    color: #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #2c5f2d;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.friends-error {
    text-align: center;
    padding: 40px;
    color: #dc3545;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.no-friends {
    text-align: center;
    padding: 60px 20px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.no-friends-content {
    max-width: 400px;
    margin: 0 auto;
}

.no-friends-icon {
    font-size: 48px;
    color: #2c5f2d;
    margin-bottom: 20px;
}

.no-friends h3 {
    color: #333;
    margin: 0 0 10px 0;
    font-size: 20px;
}

.no-friends p {
    color: #666;
    margin: 0;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .friends-header {
        flex-direction: column;
        align-items: stretch;
        padding: 20px;
    }

    .friends-search-section {
        max-width: none;
    }

    .search-wrapper {
        flex-direction: column;
    }

    .search-wrapper button {
        width: 100%;
        justify-content: center;
    }
}

/* Message Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    backdrop-filter: blur(5px);
}

.message-modal {
    background: white;
    border-radius: 15px;
    width: 95%;
    max-width: 600px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.message-input-section {
    padding: 25px;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
}

.message-input-header {
    margin-bottom: 15px;
}

.message-input-header label {
    font-size: 1.1rem;
    font-weight: 500;
    color: #333;
}

.message-textarea-container {
    width: 100%;
    margin-bottom: 20px;
}

.message-textarea {
    width: 100%;
    min-height: 180px;
    padding: 15px;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    font-size: 1rem;
    line-height: 1.6;
    resize: vertical;
    box-sizing: border-box;
    font-family: inherit;
    transition: all 0.3s ease;
}

.message-textarea:focus {
    outline: none;
    border-color: #2c5f2d;
    box-shadow: 0 0 0 3px rgba(44, 95, 45, 0.1);
}

.message-modal-footer {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-btn {
    padding: 12px 25px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    min-width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.modal-btn.cancel-btn {
    background: #f0f0f0;
    color: #666;
}

.modal-btn.cancel-btn:hover {
    background: #e0e0e0;
}

.modal-btn.send-btn {
    background: #2c5f2d;
    color: white;
}

.modal-btn.send-btn:hover {
    background: #234b24;
}

.modal-btn.send-btn:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.send-icon {
    font-size: 1.1rem;
}

.close-button {
    position: absolute;
    right: 15px;
    top: 15px;
    background: none;
    border: none;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
    z-index: 1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.close-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.modal-user-preview {
    background: linear-gradient(135deg, #2c5f2d 0%, #1e4620 100%);
    padding: 25px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.modal-user-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: white;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid rgba(255, 255, 255, 0.2);
}

.modal-user-avatar img,
.modal-user-avatar .avatar-placeholder {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.modal-user-info {
    text-align: center;
}

.modal-user-info h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
}

.modal-team-name {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 5px;
    display: block;
}

.modal-user-stats {
    display: flex;
    gap: 30px;
    margin-top: 15px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    width: 100%;
    justify-content: center;
}

.modal-stat {
    display: flex;
    align-items: center;
    gap: 12px;
}

.modal-stat-icon {
    font-size: 20px;
    color: #ffd700;
}

.modal-stat-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal-stat-details label {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 2px;
}

.modal-stat-details span {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
}

.message-input-container {
    padding: 20px;
    width: 100%;
}

.message-textarea-wrapper {
    width: 100%;
    margin: 10px 0;
}

.message-textarea {
    width: 100%;
    min-height: 120px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.5;
    resize: vertical;
    box-sizing: border-box;
    font-family: inherit;
}

.message-textarea:focus {
    outline: none;
    border-color: #2c5f2d;
    box-shadow: 0 0 0 2px rgba(44, 95, 45, 0.1);
}

.modal-user-stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 15px 20px;
    background: #f8f9fa;
    border-radius: 8px;
    margin-top: 15px;
}

.modal-stat-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.modal-stat-grid-item label {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 5px;
}

.modal-stat-grid-item span {
    font-size: 1.2rem;
    font-weight: 600;
    color: #2c5f2d;
}

.message-modal {
    background: white;
    border-radius: 15px;
    width: 90%;
    max-width: 500px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-actions {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
}

.modal-actions button {
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 8px;
    border: none;
    min-width: 120px;
}

.cancel-btn {
    background: #f5f5f5;
    color: #666;
}

.cancel-btn:hover {
    background: #e0e0e0;
}

.send-btn {
    background: #2c5f2d;
    color: white;
}

.send-btn:hover {
    background: #1e4620;
    transform: translateY(-2px);
}

.send-btn:disabled {
    background: #cccccc;
    cursor: not-allowed;
    transform: none;
}

.send-icon {
    font-size: 16px;
}

/* Add these new styles */
.search-type-toggle {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.toggle-btn {
    padding: 8px 16px;
    border: 1px solid #2c5f2d;
    background: transparent;
    color: #2c5f2d;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.toggle-btn.active {
    background: #2c5f2d;
    color: white;
}

.new-friends-results {
    margin-top: 30px;
    background: white;
    border-radius: 15px;
    padding: 25px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.new-friends-results h3 {
    margin: 0 0 20px 0;
    color: #2c5f2d;
}

.search-results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.add-friend-btn {
    width: 100%;
    padding: 12px;
    background: #2c5f2d;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: all 0.3s ease;
}

.add-friend-btn:hover {
    background: #1e4620;
}

/* Confirmation Modal Styles */
.confirm-modal {
    background: #ffffff;
    width: 90%;
    max-width: 400px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    animation: modalSlideIn 0.3s ease-out;
}

.confirm-content {
    padding: 30px;
    text-align: center;
}

.confirm-icon {
    width: 60px;
    height: 60px;
    background: rgba(220, 53, 69, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
}

.confirm-icon svg {
    font-size: 24px;
    color: #dc3545;
}

.confirm-content h3 {
    color: #333;
    margin: 0 0 15px 0;
    font-size: 20px;
}

.confirm-content p {
    color: #666;
    margin: 0 0 25px 0;
    font-size: 16px;
    line-height: 1.5;
}

.confirm-content .highlight {
    color: #2c5f2d;
    font-weight: 600;
}

.confirm-actions {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.confirm-actions button {
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    min-width: 120px;
}

.confirm-actions .cancel-btn {
    background: #f5f5f5;
    color: #666;
}

.confirm-actions .cancel-btn:hover {
    background: #e0e0e0;
}

.confirm-actions .confirm-btn {
    background: #dc3545;
    color: white;
}

.confirm-actions .confirm-btn:hover {
    background: #c82333;
    transform: translateY(-2px);
}

@keyframes modalSlideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Search Instructions */
.search-instructions {
    text-align: center;
    color: #666;
    margin: 10px 0;
    font-size: 14px;
    font-style: italic;
}

