.credit-user {
    padding: 20px;
}

.credit-form-container {
    max-width: 600px;
    margin: 20px auto;
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.credit-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form-group label {
    font-weight: 600;
    color: #2c5f2d;
}

.form-group select,
.form-group input {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.credit-btn {
    background: #2c5f2d;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
}

.credit-btn:hover {
    background: #234824;
}

.success-message,
.error-message {
    padding: 15px;
    margin: 10px 0;
    border-radius: 4px;
    text-align: center;
}

.success-message {
    background: #d4edda;
    color: #155724;
}

.error-message {
    background: #f8d7da;
    color: #721c24;
}
