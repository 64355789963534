/* Common Footer Styles */
.main-footer {
  background-color: #1a1a1a;
  color: white;
  width: 100%;
  margin-bottom: 0;
  text-align: left;
}

/* Desktop Footer */
.desktop-footer .footer-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 30px 20px;
  display: grid;
  grid-template-columns: 1.8fr 1fr 1fr 1fr;
  gap: 20px;
}

@media screen and (max-width: 1024px) {
  .desktop-footer .footer-content {
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    gap: 15px;
  }
}

@media screen and (max-width: 900px) {
  .desktop-footer .footer-content {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .desktop-footer .footer-section {
    padding-right: 10px;
  }

  .desktop-footer .main-info {
    grid-column: 1 / -1;
    max-width: 100%;
  }

  .desktop-footer .betting-features {
    max-width: 500px;
  }
}

.desktop-footer .footer-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0;
  margin: 0;
}

.desktop-footer .main-info {
  max-width: 400px;
}

.desktop-footer .main-info p {
  text-align: left;
  padding: 0;
  margin: 0;
  color: #ccc;
  line-height: 1.6;
  font-size: 0.95rem;
}

.desktop-footer .betting-features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin: 15px 0;
  background: rgba(255, 255, 255, 0.03);
  padding: 12px;
  border-radius: 6px;
  width: 100%;
}

.desktop-footer .feature {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ccc;
  padding: 6px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
}

.desktop-footer .feature-icon {
  font-size: 1.2rem;
  color: #00ff87;
  margin: 0;
  padding: 0;
}

.desktop-footer .footer-section h3 {
  font-size: 1.6rem;
  margin: 0 0 12px 0;
  padding: 0;
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.desktop-footer .footer-section h4 {
  font-size: 1.1rem;
  margin: 0 0 12px 0;
  padding: 0;
  color: #00ff87;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: left;
}

.desktop-footer .footer-nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.desktop-footer .footer-nav a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  text-align: left;
}

.desktop-footer .footer-nav a:hover {
  color: #00ff87;
}

.desktop-footer .age-restriction {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 15px 0 0 0;
  padding: 12px;
  background: rgba(255, 68, 68, 0.1);
  border-radius: 6px;
  font-size: 0.9rem;
  width: 100%;
}

.desktop-footer .age-restriction p {
  margin: 0;
  padding: 0;
  text-align: left;
}

.desktop-footer .footer-bottom-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.desktop-footer .social-links {
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.desktop-footer .social-links a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.95rem;
  margin: 0;
  padding: 0;
  text-align: left;
}

.desktop-footer .security-badges {
  display: flex;
  gap: 15px;
}

.desktop-footer .security-badge {
  background: rgba(0, 255, 135, 0.1);
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #00ff87;
  display: flex;
  align-items: center;
  gap: 6px;
}

.desktop-footer .security-badge::before {
  content: '✓';
  font-weight: bold;
}

/* Medium Footer (14-inch) */
.medium-footer .medium-footer-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 20px;
}

.medium-footer .medium-footer-main {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.medium-footer .footer-links-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.medium-footer .footer-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.medium-footer .footer-column h4 {
  font-size: 1.1rem;
  margin-bottom: 12px;
  color: #00ff87;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: left;
}

.medium-footer .footer-column a {
  color: #ccc;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
}

.medium-footer .footer-column a:hover {
  color: #00ff87;
}

.medium-footer .medium-footer-bottom {
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.medium-footer .medium-bottom-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

/* Small Footer (13-inch and 12-inch) */
.small-footer .small-footer-content {
  padding: 25px 20px;
}

.small-footer .small-footer-top {
  margin-bottom: 25px;
}

.small-footer .small-footer-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.small-footer .small-footer-brand {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.small-footer .small-footer-brand h3 {
  font-size: 1.6rem;
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.small-footer .small-footer-features {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #00ff87;
  font-size: 0.9rem;
  text-align: left;
}

.small-footer .small-footer-features span {
  white-space: nowrap;
}

.small-footer .small-footer-nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-bottom: 30px;
}

.small-footer .small-nav-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.small-footer .small-nav-column a {
  color: #ccc;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
}

.small-footer .small-nav-column a:hover {
  color: #00ff87;
}

.small-footer .small-footer-bottom {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.small-footer .security-badges {
  display: flex;
  gap: 15px;
}

.small-footer .security-badge {
  background: rgba(0, 255, 135, 0.1);
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #00ff87;
  display: flex;
  align-items: center;
  gap: 6px;
}

.small-footer .security-badge::before {
  content: '✓';
  font-weight: bold;
}

/* Compact Footer (14-inch and smaller) */
.compact-footer .compact-footer-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 20px;
}

.compact-footer .footer-links-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin: 20px 0;
}

.compact-footer .footer-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.compact-footer .footer-column h4 {
  font-size: 1.1rem;
  margin-bottom: 12px;
  color: #00ff87;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: left;
}

.compact-footer .footer-column a {
  color: #ccc;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
}

.compact-footer .footer-column a:hover {
  color: #fff;
}

/* Mobile Footer */
.mobile-footer .mobile-footer-content {
  padding: 20px;
}

.mobile-footer .mobile-footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mobile-footer .mobile-footer-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 20px;
}

.mobile-footer .mobile-footer-links a {
  color: #ccc;
  text-decoration: none;
  font-size: 0.9rem;
  text-align: center;
  padding: 12px 8px;
  background: #222;
  border-radius: 4px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.mobile-footer .mobile-footer-links a:hover {
  background: #333;
  color: #00ff87;
}

.mobile-footer .mobile-footer-bottom {
  text-align: center;
  padding-top: 15px;
  border-top: 1px solid #333;
}

/* Common Components */
.age-restriction {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  padding: 12px;
  background: rgba(255, 68, 68, 0.1);
  border-radius: 6px;
}

.age-badge {
  background-color: #ff4444;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
}

.social-links {
  display: flex;
  gap: 20px;
}

.social-links a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.95rem;
  text-align: left;
}

.social-links a:hover {
  color: #00ff87;
}

.footer-bottom {
  border-top: 1px solid #333;
  padding: 20px;
  background-color: #111;
}

.copyright {
  color: #888;
  font-size: 0.9rem;
}

/* Footer Navigation */
.footer-nav {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-nav a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
}