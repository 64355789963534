.admin-login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.admin-login-page h2 {
    color: #1a73e8;
    margin-bottom: 20px;
}

.admin-login-page form {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.admin-login-page form div {
    margin-bottom: 15px;
}

.admin-login-page label {
    display: block;
    margin-bottom: 5px;
    color: #5f6368;
}

.admin-login-page input {
    width: 100%;
    padding: 10px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    font-size: 16px;
}

.admin-login-page button {
    width: 100%;
    padding: 10px;
    background-color: #1a73e8;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.admin-login-page button:hover {
    background-color: #1557b0;
}

.error-message {
    color: #d93025;
    margin-bottom: 15px;
    text-align: center;
}
