:root {
    --primary-color: #1976D2;
    --bg-color: #ffffff;
    --border-color: #e2e8f0;
    --text-primary: #2d3748;
    --text-secondary: #718096;
    --hover-bg: #f7fafc;
    --active-bg: #edf2f7;
    --sent-bubble-color: #e3f2fd;
    --received-bubble-color: #f0f2f5;
    --sent-text-color: #1a1a1a;
    --received-text-color: #1a1a1a;
    --active-tab-bg: #f7fafc;
    --avatar-bg: #4299e1;
    --avatar-color: #ffffff;
    --username-color: #757575;
    --divider-color: #e0e0e0;
    --sent-username-color: #0D47A1;
    --received-username-color: #B71C1C;
}

.messages-container {
    width: 100%;
    height: calc(100vh - 64px);
    display: flex;
    background: var(--bg-color);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.messages-sidebar {
    width: 320px;
    border-right: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    background: var(--bg-color);
}

.messages-header {
    padding: 24px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.messages-header h2 {
    margin: 0;
    font-size: 24px;
    color: var(--text-primary);
    font-weight: 600;
}

.back-button,
.delete-button {
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 50%;
    border: none;
    background: transparent;
    cursor: pointer;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}

.back-button:hover {
    background: var(--hover-bg);
    color: var(--text-primary);
}

.delete-button {
    color: #dc3545;
}

.delete-button:hover {
    background: #ffebee;
}

.messages-tabs {
    padding: 12px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    gap: 8px;
}

.tab {
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--text-secondary);
    font-size: 0.9rem;
    transition: all 0.2s;
}

.tab:hover {
    background: var(--hover-bg);
    color: var(--text-primary);
}

.tab.active {
    background: var(--active-tab-bg);
    color: var(--primary-color);
    font-weight: 500;
}

.conversations-list {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    -ms-overflow-style: none;
}

.conversations-list::-webkit-scrollbar {
    width: 6px;
}

.conversations-list::-webkit-scrollbar-track {
    background: transparent;
}

.conversations-list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: transparent;
}

.conversations-list::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.conversation-item {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    transition: background 0.2s;
    border-bottom: 1px solid var(--border-color);
}

.conversation-item:hover {
    background: var(--hover-bg);
}

.conversation-item.active {
    background: var(--active-bg);
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--avatar-bg);
    color: var(--avatar-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.1rem;
}

.conversation-info {
    flex: 1;
    min-width: 0;
}

.conversation-name {
    font-weight: 500;
    color: var(--text-primary);
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.conversation-preview {
    color: var(--text-secondary);
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.conversation-time {
    font-size: 0.8rem;
    color: var(--text-secondary);
}

.chat-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: var(--bg-color);
}

.chat-header {
    padding: 16px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--bg-color);
}

.chat-title {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0 16px;
}

.chat-title h3 {
    margin: 0;
    font-size: 1.1rem;
    color: var(--text-primary);
    font-weight: 500;
}

.delete-confirm {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
}

.delete-confirm p {
    margin: 0 0 16px 0;
    color: var(--text-primary);
}

.delete-actions {
    display: flex;
    gap: 12px;
    justify-content: center;
}

.delete-actions button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
}

.delete-actions button:first-child {
    background: #dc3545;
    color: white;
}

.delete-actions button:first-child:hover {
    background: #c82333;
}

.delete-actions button:last-child {
    background: var(--hover-bg);
    color: var(--text-primary);
}

.delete-actions button:last-child:hover {
    background: var(--active-bg);
}

.messages-content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 0;
    background: var(--bg-color);
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    -ms-overflow-style: none;
}

.messages-content::-webkit-scrollbar {
    width: 6px;
}

.messages-content::-webkit-scrollbar-track {
    background: transparent;
}

.messages-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: transparent;
}

.messages-content::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.message {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 8px 16px;
    border-bottom: 1px solid var(--border-color);
}

.message:last-child {
    border-bottom: none;
}

.message.sent {
    align-self: stretch;
}

.message.received {
    align-self: stretch;
}

.message-user-info {
    margin-bottom: 4px;
    padding: 0 8px;
}

.message-username {
    font-size: 0.85rem;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    gap: 6px;
}

.username-text {
    font-weight: 600;
    letter-spacing: 0.5px;
    color: var(--text-primary);
}

.message.sent .username-text {
    color: var(--sent-username-color);
}

.message.received .username-text {
    color: var(--received-username-color);
}

.team-info {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 0.85rem;
}

.team-name {
    color: var(--text-secondary);
    font-weight: 500;
    font-style: italic;
}

.team-logo {
    width: 20px;
    height: 20px;
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid var(--border-color);
    background-color: white;
    padding: 1px;
}

.message.sent .team-name {
    color: var(--sent-username-color);
    opacity: 0.8;
}

.message.received .team-name {
    color: var(--received-username-color);
    opacity: 0.8;
}

.message-bubble {
    padding: 12px 16px;
    border-radius: 18px;
    font-size: 0.95rem;
    line-height: 1.4;
    position: relative;
    margin: 4px 0;
    width: 100%;
}

.message.sent .message-bubble {
    background: var(--sent-bubble-color);
    color: var(--sent-text-color);
    border-top-right-radius: 4px;
}

.message.received .message-bubble {
    background: var(--received-bubble-color);
    color: var(--received-text-color);
    border-top-left-radius: 4px;
}

.message-time {
    font-size: 0.75rem;
    color: var(--text-secondary);
    margin-top: 4px;
    text-align: right;
}

.message.sent .message-time {
    padding-right: 8px;
}

.message.received .message-time {
    padding-left: 8px;
    text-align: left;
}

.message-date-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;
    position: relative;
}

.message-date {
    background: var(--bg-color);
    padding: 0 16px;
    color: var(--text-secondary);
    font-size: 0.85rem;
    z-index: 1;
}

.message-date-divider::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--border-color);
}

.message-input-container {
    padding: 16px 16px 24px 16px;
    border-top: 1px solid var(--border-color);
    display: flex;
    gap: 12px;
    align-items: flex-end;
    margin-bottom: 8px;
    background: var(--bg-color);
}

.message-input {
    flex: 1;
    border: 1px solid var(--border-color);
    border-radius: 24px;
    padding: 12px 16px;
    resize: none;
    max-height: 120px;
    font-size: 0.95rem;
    line-height: 1.4;
}

.message-input:focus {
    outline: none;
    border-color: var(--primary-color);
}

.send-button {
    padding: 10px;
    border-radius: 50%;
    border: none;
    background: #006400;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}

.send-button:hover {
    background: #005000;
}

.send-button:disabled {
    background: var(--border-color);
    cursor: not-allowed;
}

.no-conversation {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-secondary);
    font-size: 16px;
    gap: 16px;
}

.no-conversation svg {
    font-size: 48px;
    opacity: 0.5;
}

/* Responsive Design */
@media (max-width: 768px) {
    .messages-container {
        height: calc(100vh - 60px);
    }

    .messages-sidebar {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
    }

    .messages-sidebar.active {
        transform: translateX(0);
    }

    .chat-area {
        width: 100%;
    }

    .message {
        max-width: 100%;
        padding: 8px 12px;
    }

    .back-button {
        display: flex;
    }
}

@media (min-width: 769px) {
    .back-button {
        display: none;
    }
}

@media (max-width: 480px) {
    .messages-header {
        padding: 16px;
    }

    .messages-header h2 {
        font-size: 20px;
    }

    .message {
        max-width: 100%;
        padding: 8px 8px;
    }

    .message-input-container {
        padding: 12px 16px 20px 16px;
        margin-bottom: 6px;
    }
}

.message.unread {
    background-color: rgba(0, 123, 255, 0.05);
}

.conversation-item.unread {
    background-color: rgba(0, 123, 255, 0.1);
    font-weight: bold;
}

.unread-indicator {
    width: 8px;
    height: 8px;
    background-color: #007bff;
    border-radius: 50%;
    display: inline-block;
    margin-left: 8px;
}

.message-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.messages-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 20px;
    height: calc(100vh - 180px);
    scroll-behavior: smooth;
}

/* Ensure the scroll container takes available space */
.chat-area {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Style the notification badge in the sidebar */
.notification-badge {
    background-color: #dc3545;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    position: absolute;
    top: -5px;
    right: -5px;
    min-width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}
