/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
}

.layout {
  display: flex;
}

/* Main Content Area */
.main-content {
  flex-grow: 1;
  background-color: white;
  padding: 20px;
}

h1, h2, h3 {
  color: #2c5f2d;
}

/* Buttons */
button {
  background-color: #2c5f2d;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 5px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #3a783a;
}

/* Form Styles */
form {
  margin-top: 20px;
}

label {
  display: block;
  margin: 10px 0 5px;
  color: #2c5f2d;
}

input, select {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="number"] {
  max-width: 150px;
}

input:focus, select:focus {
  border-color: #2c5f2d;
}

.challenge-form {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.match-settings {
    width: 100%;
    margin-bottom: 30px;
}

/* Container for both teams */
.team-container {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: stretch !important;
    gap: 20px !important;
    margin: 15px 0 !important;
    max-width: 100% !important;
}

/* Individual team sections */
.team-section {
    flex: 1 1 0 !important;
    min-width: 0 !important;
    background-color: white !important;
    padding: 20px !important;
    border-radius: 8px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
    border: 1px solid #e0e0e0 !important;
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 15px !important;
}

.team-section h3 {
    margin: 0 0 10px 0 !important;
    padding-bottom: 10px !important;
    border-bottom: 2px solid #e0e0e0 !important;
    font-size: 1.2em !important;
    color: #2c5f2d !important;
}

.team-section .form-group {
    padding: 12px !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 6px !important;
    margin-bottom: 12px !important;
    background-color: #fafafa !important;
}

.team-section label {
    display: block !important;
    margin-bottom: 8px !important;
    color: #2c5f2d !important;
    font-weight: 500 !important;
    font-size: 0.95em !important;
}

.team-section select,
.team-section input {
    width: 100% !important;
    padding: 8px !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 6px !important;
    margin-bottom: 12px !important;
    background-color: white !important;
    transition: all 0.2s ease !important;
}

.team-section .logo-container {
    text-align: center !important;
    padding: 15px !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 8px !important;
    margin: 10px 0 !important;
    background-color: white !important;
}

.team-section .logo-preview {
    width: 150px !important;
    height: 150px !important;
    object-fit: contain !important;
    margin: 0 auto !important;
    display: block !important;
    padding: 8px !important;
}

/* Ensure equal width distribution */
.team-section.team1,
.team-section.team2 {
    width: calc(50% - 15px) !important;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
    .team-container {
        flex-direction: column !important;
        gap: 20px !important;
    }

    .team-section,
    .team-section.team1,
    .team-section.team2 {
        width: 100% !important;
    }
}

.logo-preview {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

/* Team sections as individual cards */
.team-section {
  background-color: #e9f7e4;
  padding: 15px;
  border-radius: 4px;
  width: 48%; /* Each card takes 48% of the container width */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Team logos */
.logo-preview {
  margin-top: 10px;
  max-width: 100px;
}

.odds-explanation {
  font-size: 12px;
  color: #777;
}

/* Challenge Preview */
.challenge-preview {
    background-color: white !important;
    padding: 20px !important;
    margin-top: 20px !important;
    border-radius: 8px !important;
    border: 1px solid #e0e0e0 !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
}

.challenge-preview h3 {
    color: #2c5f2d !important;
    margin-bottom: 15px !important;
    padding-bottom: 10px !important;
    border-bottom: 2px solid #e0e0e0 !important;
    font-size: 1.2em !important;
}

.preview-teams-container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 20px !important;
    margin: 15px 0 !important;
}

.preview-team {
    flex: 1 !important;
    text-align: center !important;
    padding: 15px !important;
    background-color: #fafafa !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 8px !important;
}

.preview-team .logo-container {
    margin-bottom: 10px !important;
}

.preview-team .logo-preview {
    width: 100px !important;
    height: 100px !important;
    object-fit: contain !important;
}

.preview-team-name {
    font-weight: 600 !important;
    color: #2c5f2d !important;
    margin: 8px 0 !important;
}

.preview-odds {
    font-size: 1.1em !important;
    font-weight: 500 !important;
    color: #333 !important;
    margin-top: 8px !important;
}

.preview-vs {
    font-size: 1.1em !important;
    font-weight: 600 !important;
    color: #666 !important;
}

.preview-time-details {
    margin-top: 15px !important;
    padding: 12px !important;
    background-color: #fafafa !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 8px !important;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
}

.preview-time-details p {
    margin: 0 !important;
    padding: 0 12px !important;
    color: #444 !important;
    font-size: 0.9em !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 4px !important;
}

.preview-time-details p span {
    font-weight: 600 !important;
    color: #2c5f2d !important;
}

/* Remove all existing alert styles and replace with new ones */
.success-message,
.error-message {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  animation: slideInRight 0.3s ease-out, fadeOut 0.3s ease-in 2.7s forwards;
  white-space: nowrap;
}

.success-message {
  background: #dcf7dc;
  color: #0a5d0a;
}

.error-message {
  background: #fde8e8;
  color: #c81e1e;
}

.success-message::before {
  content: "✓";
  font-weight: bold;
}

.error-message::before {
  content: "!";
  font-weight: bold;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Remove all conflicting alert styles */
.challenge-system .success-message,
.challenge-system .error-message {
  position: fixed;
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  transform: none;
  min-width: auto;
  max-width: none;
  border: none;
}

/* Remove the footer positioning styles that were affecting alerts */
.challenge-system .success-message,
.challenge-system .error-message {
  bottom: auto;
  left: auto;
  transform: none;
}

/* Remove the slideUp animation that was conflicting */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th {
  background-color: #2c5f2d;
  color: white;
  padding: 10px;
  text-align: left;
}

table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

table td img.team-logo {
  max-width: 40px;
  margin-right: 10px;
}

table td .team-info {
  display: flex;
  align-items: center;
}

.actions button {
  background-color: #f0ad4e;
  margin-left: 10px;
}

.actions button:hover {
  background-color: #ec971f;
}

.challenges-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.challenge-card {
  background: white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.challenge-card:hover {
  transform: translateY(-5px);
}

.teams-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.team-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain;
}

.vs {
  font-weight: bold;
  font-size: 1.2em;
  color: #666;
}

.odds {
  font-size: 0.9em;
  color: #3498db;
  font-weight: 600;
}

.countdown-timer {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 15px 0;
}

.countdown-segment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-value {
  font-size: 1.5em;
  font-weight: bold;
  color: #2c3e50;
}

.countdown-label {
  font-size: 0.8em;
  color: #7f8c8d;
}

.join-challenge-btn {
  width: 100%;
  padding: 12px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;
}

.join-challenge-btn:hover {
  background: #2980b9;
}

.countdown-ended {
  color: #e74c3c;
  font-weight: 600;
}

.match-time {
  text-align: center;
  color: #666;
  font-size: 0.9em;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .challenges-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}

/* Add these styles to fix scrolling */

.challenge-system {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Main content area */
.challenge-form {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 60px; /* Space for footer */
}

/* Scrollable preview section */
.challenge-preview {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

/* Fix header actions - remove sticky positioning */
.header-actions {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  padding: 10px 0;
  background: transparent;
  border-bottom: 1px solid #e0e0e0;
}

.header-actions button {
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.header-actions button:first-child {
  background: #2c5f2d;
  color: white;
}

.header-actions button:last-child {
  background: #f8f9fa;
  color: #6c757d;
  border: 1px solid #dee2e6;
}

.header-actions button:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Match Settings Section */
.match-settings {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
}

.match-type-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  align-items: start;
}

.match-type-section h3 {
  grid-column: 1 / -1;
  margin-bottom: 15px;
  color: #2c5f2d;
  font-size: 1.2em;
}

.match-type-section .form-group {
  margin-bottom: 15px;
}

.match-type-section label {
  display: block;
  margin-bottom: 8px;
  color: #2c5f2d;
  font-weight: 500;
}

.match-type-section select,
.match-type-section input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: white;
  transition: border-color 0.2s ease;
}

.match-type-section select:focus,
.match-type-section input:focus {
  border-color: #2c5f2d;
  outline: none;
  box-shadow: 0 0 0 2px rgba(44, 95, 45, 0.1);
}

.match-type-section .odds-explanation {
  font-size: 12px;
  color: #6c757d;
  margin-top: 4px;
}
