.challenge-management {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.challenge-management h1 {
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
}

.table-container {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.challenge-management-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.challenge-management-table th {
    background: #f8f9fa;
    padding: 12px;
    font-weight: 600;
    color: #495057;
    text-align: left;
    border-bottom: 2px solid #dee2e6;
}

.challenge-management-table td {
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    vertical-align: middle;
}

/* Column widths */
.index-column {
    width: 50px;
    text-align: center;
}

/* Matchup styling */
.matchup-grid {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 10px;
    align-items: center;
    max-width: 400px;
}

.team-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.team-logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
    background: #f8f9fa;
    padding: 2px;
}

.team-name {
    font-weight: 500;
    font-size: 14px;
    color: #333;
    text-align: center;
}

.team-odds {
    font-size: 13px;
    color: #666;
    background: #f8f9fa;
    padding: 2px 8px;
    border-radius: 12px;
}

.vs-center {
    color: #999;
    font-size: 14px;
    font-weight: 500;
}

/* Time and Date styling */
.time-date-cell {
    width: 180px;
}

.time-date-stack {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.match-time {
    font-weight: 500;
    color: #333;
}

.end-time {
    color: #666;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    background: #f8f9fa;
    padding: 6px 12px;
    border-radius: 4px;
}

/* Status styling */
.status-badge {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    min-width: 100px;
    text-transform: capitalize;
}

.status-badge.Open {
    background-color: #e3f2fd;
    color: #1976d2;
}

.status-badge.Closed {
    background-color: #ffebee;
    color: #d32f2f;
}

.status-badge.Completed,
.status-badge.Settled {
    background-color: #e8f5e9;
    color: #2e7d32;
}

.status-badge.Expired {
    background-color: #fafafa;
    color: #616161;
}

/* Action buttons */
.action-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100px;
}

.btn {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: opacity 0.2s;
}

.btn:hover {
    opacity: 0.9;
}

.btn-preview {
    background: #4caf50;
    color: white;
}

.btn-edit {
    background: #2196f3;
    color: white;
}

.btn-delete {
    background: #f44336;
    color: white;
}

/* Common Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.close-button {
    position: absolute !important;
    top: 20px !important;
    right: 20px !important;
    background: none !important;
    border: none !important;
    font-size: 28px !important;
    color: #666 !important;
    cursor: pointer !important;
    padding: 8px 12px !important;
    border-radius: 4px !important;
    transition: all 0.2s !important;
    z-index: 1001 !important;
}

.close-button:hover {
    background: #f5f5f5 !important;
    color: #333 !important;
}

/* Preview Modal Specific Styles */
.preview-modal {
    background: white;
    border-radius: 12px;
    padding: 32px;
    width: 95%;
    max-width: 1200px;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

/* Edit Modal Specific Styles */
.edit-modal {
    background: white;
    border-radius: 12px;
    padding: 32px;
    width: 95%;
    max-width: 1200px;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    min-height: fit-content;
}

.edit-modal h2 {
    color: #333;
    margin: 0 0 32px;
    text-align: center;
    font-size: 28px;
    padding-right: 40px;
}

.edit-modal .form-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-bottom: 32px;
}

.form-group {
    margin-bottom: 0;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #495057;
    font-weight: 500;
    font-size: 15px;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 12px;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    font-size: 15px;
    color: #495057;
    transition: all 0.2s;
    background: #fff;
}

.form-group input:focus,
.form-group select:focus {
    border-color: #2196f3;
    box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
    outline: none;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 32px;
    padding-top: 24px;
    border-top: 1px solid #dee2e6;
}

.form-actions button {
    padding: 12px 32px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.form-actions button.save-button {
    background: #4caf50;
    color: white;
}

.form-actions button.cancel-button {
    background: #f44336;
    color: white;
}

.form-actions button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

.form-actions button:active {
    transform: translateY(0);
}

/* Match Preview Display */
.match-preview-display {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 30px;
    align-items: center;
    margin-bottom: 30px;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
}

.match-team-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;
}

.team-logo-large {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 50%;
    background: white;
    padding: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.match-team-preview .team-name {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.match-team-preview .team-odds {
    font-size: 15px;
    color: #666;
    background: white;
    padding: 4px 12px;
    border-radius: 16px;
    margin: 0;
}

.match-vs-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.vs-text {
    font-size: 24px;
    font-weight: 600;
    color: #999;
}

.match-odds {
    text-align: center;
}

.draw-odds, .lost-odds {
    margin: 4px 0;
    font-size: 14px;
    color: #666;
    background: white;
    padding: 4px 12px;
    border-radius: 16px;
}

/* Match Details */
.match-details {
    background: white;
    border-radius: 8px;
    padding: 20px;
}

.detail-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 16px;
}

.detail-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.detail-item .label {
    font-size: 14px;
    color: #666;
}

.detail-item .value {
    font-size: 15px;
    color: #333;
    font-weight: 500;
}

/* Match Time Display */
.match-time-display {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

.match-time {
    font-weight: 500;
    color: #333;
    font-size: 14px;
    white-space: nowrap;
    background: #f5f7fa;
    padding: 6px 12px;
    border-radius: 4px;
}

/* Countdown Timer */
.countdown-container {
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.countdown-time {
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
    font-weight: 500;
    color: #1976d2;
    background: #e3f2fd;
    padding: 4px 8px;
    border-radius: 4px;
    letter-spacing: 0.5px;
    white-space: nowrap;
}

.countdown-expired {
    font-size: 14px;
    font-weight: 500;
    color: #616161;
    background: #fafafa;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
}

/* Table Styles */
.challenge-table td {
    padding: 16px;
    vertical-align: middle;
    border-bottom: 1px solid #eee;
}

.challenge-table .time-cell {
    min-width: 280px;
    text-align: center;
    white-space: nowrap;
}

.challenge-table .status-cell {
    text-align: center;
    min-width: 120px;
}

/* Match Type Section */
.match-type-section {
    text-align: center;
    margin-bottom: 20px;
}

.match-type-badge {
    background: #f8f9fa;
    color: #495057;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

/* Messages */
.error-message {
    background: #ffe8e8;
    color: #d32f2f;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.success-message {
    background: #e7f5e7;
    color: #1e7e1e;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}