.user-layout {
  display: flex;
  min-height: 100vh;
  background-color: transparent;
  overflow-x: hidden;
}

.user-header {
  background: #2c5f2d;
  padding: 1rem 2rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo a {
  color: white;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
}

.user-nav {
  flex: 1;
  display: flex;
  justify-content: center;
}

.user-nav ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.user-nav li {
  position: relative;
  padding: 0.5rem 0;
}

.user-nav a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  display: block;
  border-radius: 4px;
}

.user-nav a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.user-nav li.active > a {
  color: #ffd700;
  background: rgba(255, 255, 255, 0.1);
}

.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #2c5f2d;
  min-width: 200px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 0 0 8px 8px;
  padding: 0.5rem 0;
  z-index: 1000;
}

.user-nav li:hover .submenu {
  display: block;
}

.submenu a {
  padding: 0.75rem 1.5rem;
  font-size: 0.95rem;
  white-space: nowrap;
  border-radius: 0;
}

.submenu a:hover {
  background: #45a049;
}

.logout-container {
  margin-left: 2rem;
}

.logout-button {
  background: white;
  color: #2c5f2d;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.logout-button:hover {
  background: #f0f0f0;
  transform: translateY(-1px);
}

.user-content {
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.user-footer {
  background: #2c5f2d;
  color: white;
  text-align: center;
  padding: 1rem;
  margin-top: auto;
}

.main-container {
  background-color: #ffffff;
  margin-left: 280px;
  width: calc(100% - 280px);
  min-height: 100vh;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
  overflow-y: auto;
  height: 100vh;
}

.main-container.expanded {
  margin-left: 70px;
  width: calc(100% - 70px);
}

body {
  background-color: #ffffff;
}

.dashboard-header {
  background: white;
  height: 70px;
  border-bottom: 1px solid #edf2f7;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}

.dashboard-nav {
  height: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-left {
  display: flex;
  align-items: center;
}

.page-title {
  font-size: 24px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 24px;
}

.user-balances {
  display: flex;
  gap: 20px;
}

.balance-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.balance-label {
  font-size: 14px;
  color: #718096;
}

.balance-amount {
  font-size: 15px;
  font-weight: 600;
  color: #2d3748;
}

.nav-logout-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(255, 59, 59, 0.1);
  border: none;
  border-radius: 8px;
  color: #ff3b3b;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.nav-logout-btn:hover {
  background: rgba(255, 59, 59, 0.2);
}

.nav-icon {
  font-style: normal;
}

.dashboard-content {
  padding: 20px;
  min-height: calc(100vh - 70px - 20px);
  overflow: visible;
}

.dashboard-footer {
  background: white;
  border-top: 1px solid #edf2f7;
  padding: 20px 30px;
  margin-top: auto;
}

.footer-content {
  text-align: center;
  color: #718096;
  font-size: 14px;
}

/* Mobile Navigation */
@media (max-width: 1024px) {
  .user-header {
    flex-direction: column;
    padding: 1rem;
  }

  .logo {
    margin-bottom: 1rem;
  }

  .user-nav ul {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  .user-nav li {
    position: static;
  }

  .submenu {
    position: fixed;
    top: auto;
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0.5rem;
  }

  .logout-container {
    margin: 1rem 0 0 0;
  }
}

@media (max-width: 768px) {
  .user-nav ul {
    flex-direction: column;
    align-items: center;
  }

  .user-nav li {
    width: 100%;
    text-align: center;
  }

  .submenu {
    position: static;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.1);
    margin: 0.5rem 0;
  }

  .user-content {
    padding: 1rem;
  }
}

@media (max-width: 1280px) {
  .user-balances {
    gap: 15px;
  }
  
  .dashboard-nav {
    padding: 0 20px;
  }
}

@media (max-width: 1024px) {
  .main-container {
    margin-left: 180px;
    width: calc(100% - 180px);
  }

  .dashboard-header {
    left: 0;
  }

  .user-balances {
    display: none;
  }
}

@media (max-width: 768px) {
  .dashboard-nav {
    padding: 0 15px;
  }

  .page-title {
    font-size: 20px;
  }
}

.nav-link-with-badge {
    position: relative;
    display: flex;
    align-items: center;
}

.notification-badge {
    background-color: #dc3545;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    position: absolute;
    top: -5px;
    right: -5px;
    min-width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-banner {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background: #fff;
  animation: slideIn 0.5s ease-out, fadeOut 0.5s ease-in 3.5s;
  max-width: 400px;
  width: auto;
}

.notification-banner.message {
  border-left: 4px solid #007bff;
  background: linear-gradient(to right, #e3f2fd, #fff);
}

.notification-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.notification-icon {
  color: #007bff;
  font-size: 20px;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Make the notification badge more visible */
.notification-badge {
  background: linear-gradient(45deg, #ff3366, #dc3545);
  box-shadow: 0 2px 4px rgba(220, 53, 69, 0.4);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 2px 4px rgba(220, 53, 69, 0.4);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(220, 53, 69, 0.6);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 2px 4px rgba(220, 53, 69, 0.4);
  }
}

/* Add media queries for specific screen sizes */
@media (max-width: 1440px) {
  .main-container {
    margin-left: 240px;
    width: calc(100% - 240px);
  }
}

@media (max-width: 1366px) { /* 14-inch */
  .main-container {
    margin-left: 220px;
    width: calc(100% - 220px);
  }
  
  .main-container.expanded {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
}

@media (max-width: 1280px) { /* 13-inch */
  .main-container {
    margin-left: 200px;
    width: calc(100% - 200px);
  }
}

@media (max-width: 1024px) {
  .main-container {
    margin-left: 180px;
    width: calc(100% - 180px);
  }
}
