/* Base container */
.join-challenge {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}

/* Header */
.join-challenge h1 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
}

/* Layout container */
.join-challenge .centered-layout {
  width: 100%;
}

/* Match details grid */
.match-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin: 2rem 0;
}

.detail-item {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
}

.detail-item .label {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.detail-item .value {
  font-size: 1.1rem;
  color: #333;
  font-weight: 600;
}

/* Returns grid */
.returns-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 2rem 0;
}

.return-item {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
}

.return-item .label {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.return-item .value {
  font-size: 1.1rem;
  color: #333;
  font-weight: 600;
}

/* Form elements */
.bet-form {
  margin-top: 2rem;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: #1a2a6c;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s;
}

.submit-button:hover {
  transform: translateY(-2px);
}

/* Messages */
.error-message {
  color: #dc3545;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 8px;
  text-align: center;
}

.success-message {
  color: #28a745;
  background: #d4edda;
  border: 1px solid #c3e6cb;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 8px;
  text-align: center;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .returns-grid {
    grid-template-columns: 1fr;
  }
}
