.transfer-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.transfer-container h1 {
    color: #2c3e50;
    margin-bottom: 20px;
}

.balance-display {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    font-size: 1.2em;
    color: #2c3e50;
    margin-bottom: 20px;
    border: 1px solid #e9ecef;
}

.transfer-form {
    background: white;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #2c3e50;
    font-weight: 500;
}

.form-group select,
.form-group input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    font-size: 1em;
}

.form-group select:focus,
.form-group input:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74,144,226,0.2);
}

.transfer-btn {
    background-color: #4a90e2;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
}

.transfer-btn:hover {
    background-color: #357abd;
    transform: translateY(-1px);
}

.error-message {
    background-color: #fee2e2;
    color: #dc2626;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 20px;
}

.success-message {
    background-color: #dcfce7;
    color: #16a34a;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 20px;
}

/* Confirmation Modal */
.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 30px;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
}

.modal-content h2 {
    color: #2c3e50;
    margin-bottom: 15px;
}

.modal-content p {
    color: #4a5568;
    margin-bottom: 25px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.cancel-btn {
    background-color: #e2e8f0;
    color: #4a5568;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cancel-btn:hover {
    background-color: #cbd5e1;
}

.confirm-btn {
    background-color: #4a90e2;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.confirm-btn:hover {
    background-color: #357abd;
}

/* Responsive Design */
@media (max-width: 768px) {
    .transfer-container {
        padding: 15px;
    }
    
    .transfer-form {
        padding: 20px;
    }
    
    .modal-content {
        width: 95%;
        padding: 20px;
    }
} 