.user-management {
    padding: 20px;
}

.user-management table {
    width: 100%;
    border-collapse: collapse;
}

.user-management th, .user-management td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.user-management th {
    background-color: #f2f2f2;
}

.edit-user-form {
    margin-top: 20px;
}

.edit-user-form input, .edit-user-form select {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    padding: 5px;
}

.edit-user-form button {
    margin-right: 10px;
}
