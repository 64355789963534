.league-user-management {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
}

.league-user-management h1 {
    color: #2c3e50;
    margin-bottom: 20px;
    font-size: 1.8rem;
}

.leagues-table {
    overflow-x: auto;
    margin: 1rem 0;
    border-radius: 8px;
}

.table-header {
    display: grid;
    grid-template-columns: minmax(140px, 1.5fr) minmax(160px, 2fr) 70px minmax(100px, 1fr) 110px 140px;
    gap: 0;
    padding: 0.75rem 1rem;
    background: #228B22 !important;
    color: white;
    font-weight: 600;
    font-size: 0.85rem;
    border-radius: 6px 6px 0 0;
}

.table-header th {
    background: transparent !important;
    border: none !important;
    padding: 0.5rem;
}

.table-row {
    display: grid;
    grid-template-columns: minmax(140px, 1.5fr) minmax(160px, 2fr) 70px minmax(100px, 1fr) 110px 140px;
    gap: 0.8rem;
    padding: 0.6rem 1rem;
    border-bottom: 1px solid #eee;
    font-size: 0.85rem;
}

.table-row:hover {
    background: #f8f9fa;
}

.table-row td {
    padding: 15px;
    color: #2c3e50;
    vertical-align: middle;
    background: none;
    white-space: nowrap;
}

.league-icon {
    display: none;
}

.name {
    display: block;
    padding: 0.2rem 0;
}

.league-description {
    font-size: 0.8rem;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.members {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
}

.bet-range {
    letter-spacing: -0.5px;
    font-size: 0.8rem;
}

.status-badge {
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    font-size: 0.7rem;
    display: inline-block;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}

.status-badge.active {
    background: #e8f5e9;
    color: #2e7d32;
}

.status-badge.inactive {
    background: #fff3e0;
    color: #ef6c00;
}

.view-users-btn {
    width: 100%;
    padding: 0.35rem 0.5rem;
    font-size: 0.8rem;
    background: #228B22;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
}

.delete-btn {
    background-color: #ef4444;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s;
    min-width: 100px;
}

.delete-btn:hover {
    background-color: #dc2626;
}

.loading {
    text-align: center;
    padding: 20px;
    color: #6b7280;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
}

.pagination-button {
    padding: 0.5rem 1rem;
    background: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.2s;
}

.pagination-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.page-info {
    color: #4b5563;
    font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .table-header,
    .table-row {
        grid-template-columns: minmax(120px, 1fr) minmax(140px, 1.5fr) 60px 80px 80px 120px;
        gap: 0.6rem;
        padding: 0.6rem 0.8rem;
    }
}

@media (max-width: 992px) {
    .table-header,
    .table-row {
        grid-template-columns: minmax(120px, 1fr) minmax(150px, 1.5fr) 60px 90px 80px 100px;
    }
    
    .bet-range {
        font-size: 0.8rem;
    }
}

@media (max-width: 768px) {
    .table-header {
        display: none;
    }
    
    .table-row {
        grid-template-columns: 1fr;
        padding: 0.8rem;
        gap: 0.3rem;
    }
    
    .table-row > *::before {
        font-size: 0.75rem;
    }
    
    .view-users-btn {
        font-size: 0.75rem;
        padding: 0.3rem 0.5rem;
    }
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.leaderboard-modal {
    background: white;
    border-radius: 12px;
    padding: 20px;
    width: 98%;
    max-width: 1400px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e7eb;
}

.modal-header h2 {
    color: #2c3e50;
    margin: 0;
}

.close-modal {
    background: none;
    border: none;
    font-size: 24px;
    color: #6b7280;
    cursor: pointer;
    padding: 0;
}

.close-modal:hover {
    color: #4b5563;
}

.leaderboard-table {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    background: white;
}

.leaderboard-table table {
    width: 100%;
    border-collapse: collapse;
    min-width: 1000px;
}

.leaderboard-table .table-header {
    background-color: #f8f9fa;
    border-bottom: 2px solid #e9ecef;
}

.leaderboard-table .table-header th {
    color: #1a1a1a;
    font-weight: 600;
    padding: 15px;
    text-align: left;
    background: transparent !important;
}

.points {
    color: #059669;
    font-weight: 600;
}

.stats {
    display: flex;
    gap: 10px;
    align-items: center;
    white-space: nowrap;
}

.deposit {
    color: #2563eb;
    font-weight: 500;
}

.leaderboard-table th:nth-child(4),
.leaderboard-table td:nth-child(4) {
    display: none;
}

/* Error and success messages */
.error-message,
.success-message {
    margin: 10px 0;
    padding: 12px 15px;
    border-radius: 6px;
    font-size: 0.95rem;
}

.error-message {
    background-color: #ffe3e3;
    color: #dc3545;
    border: 1px solid #fecaca;
}

.success-message {
    background-color: #e8f5e9;
    color: #2e7d32;
    border: 1px solid #bbf7d0;
}

/* Add vertical bet range styling */
.bet-range-container {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    font-size: 0.75rem;
}

/* Add table cell alignment */
.table-row td:nth-child(5) {
    text-align: center;
    padding: 0 0.5rem;
} 