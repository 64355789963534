 .user-dashboard {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    font-family: Arial, sans-serif;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    margin-bottom: 24px;
    padding: 24px;
    margin-top: 20px;
    width: 100%;
}

.dashboard-card {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.card-title {
    font-size: 18px;
    font-weight: 600;
    color: #2d3748;
    margin: 0;
}

.card-icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(66, 153, 225, 0.1);
    color: #4299e1;
}

.card-content {
    margin-bottom: 16px;
}

.stat-value {
    font-size: 28px;
    font-weight: 700;
    color: #2d3748;
    margin: 0;
}

.stat-label {
    font-size: 14px;
    color: #718096;
    margin: 4px 0 0;
}

.card-footer {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
}

.trend-positive {
    color: #48bb78;
}

.trend-negative {
    color: #e53e3e;
}

.user-dashboard h1 {
    color: #2c5f2d;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
}

/* Account Balance Section */
.account-balance {
    background: white;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    width: 100%;
}

.account-balance h2 {
    color: #2c5f2d;
    margin: 0 0 20px 0;
    font-size: 20px;
    font-weight: 600;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: nowrap;
    width: 100%;
}

.primary-button, .secondary-button {
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    text-decoration: none;
    text-align: center;
    min-width: 180px;
    white-space: nowrap;
    flex: 1 1 0;
}

.primary-button {
    background-color: #2c5f2d;
    color: white;
    border: none;
}

.secondary-button {
    background-color: white;
    color: #2c5f2d;
    border: 2px solid #2c5f2d;
}

/* Media query for smaller screens */
@media screen and (max-width: 480px) {
    .action-buttons {
        flex-direction: column;
        gap: 12px;
    }

    .primary-button, .secondary-button {
        width: 100%;
        min-width: unset;
    }
}

/* Recent Bets Section */
.recent-bets-container {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
    width: 100%;
}

.recent-bets-container h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #1e293b;
}

.bets-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.bet-card {
    background: #f8fafc;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #e2e8f0;
}

.bet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.bet-status {
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
}

.bet-status.pending {
    background: #fff7ed;
    color: #c2410c;
}

.bet-status.active {
    background: #ecfdf5;
    color: #047857;
}

.bet-status.completed {
    background: #eff6ff;
    color: #1d4ed8;
}

.bet-reference {
    font-size: 14px;
    color: #2c5f2d;
    font-weight: 600;
    background: #f0f8f0;
    padding: 4px 8px;
    border-radius: 4px;
    letter-spacing: 0.5px;
}

.potential-returns {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.return-item {
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
}

.return-item.win {
    background: #f0fdf4;
    color: #166534;
}

.return-item.draw {
    background: #fef9c3;
    color: #854d0e;
}

.return-item.loss {
    background: #fef2f2;
    color: #991b1b;
}

.bet-matchup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    padding: 20px 0;
}

.bet-matchup .team-name,
.teams-compact .team-name-compact {
    color: #000000 !important;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 4px;
}

.team-name {
    font-weight: 700;
    color: #000000 !important;
    font-size: 16px;
    margin-bottom: 4px;
}

.bet-team {
    flex: 1;
    max-width: 40%;
}

.team-logo-medium {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    object-fit: cover;
}

.team-details {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.username {
    text-transform: capitalize;
    font-weight: 500;
    color: #444;
    font-size: 14px;
}

.bet-amount {
    font-size: 14px;
    font-weight: 500;
    color: #0f172a;
}

.vs-badge {
    padding: 8px 16px;
    background: #f8f9fa;
    border-radius: 8px;
    font-weight: 700;
    color: #666;
    position: relative;
}

.vs-badge::before,
.vs-badge::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 40px;
    height: 1px;
    background: #e0e0e0;
}

.vs-badge::before {
    right: 100%;
    margin-right: 8px;
}

.vs-badge::after {
    left: 100%;
    margin-left: 8px;
}

/* Recent Challenges Section */
.recent-challenges-container {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width: 100%;
    margin-top: 2rem;
}

.recent-challenges-container h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 20px;
    font-weight: 600;
    color: #333;
}

.challenges-list {
    overflow-x: auto;
}

.challenges-list table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.challenges-list th {
    background: #2c5f2d;
    color: white;
    padding: 12px 16px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
}

.challenges-list th:first-child {
    border-top-left-radius: 8px;
}

.challenges-list th:last-child {
    border-top-right-radius: 8px;
}

.challenges-list td {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
}

.challenges-list tr:last-child td {
    border-bottom: none;
}

.challenges-list tr:hover td {
    background: #f8f9fa;
}

.table-container table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.table-container th,
.table-container td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.table-container th {
    background: #f8f9fa;
    font-weight: 600;
    color: #495057;
    border-bottom: 2px solid #dee2e6;
}

.table-container tr:last-child td {
    border-bottom: none;
}

.table-container tr:hover {
    background-color: #f8f9fa;
}

.teams-compact {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    min-width: 200px;
}

.team-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.team-logo-small {
    width: 32px;
    height: 32px;
    object-fit: contain;
    border-radius: 4px;
    background: #f8f9fa;
    padding: 2px;
}

.team-name-compact {
    font-weight: 500;
    color: #2c3e50;
}

.vs-small {
    color: #6c757d;
    font-size: 0.875rem;
    font-weight: 500;
}

.odds-compact,
.goals-compact {
    font-family: monospace;
    background: #f8f9fa;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
}

.date-compact {
    color: #495057;
    font-size: 0.875rem;
}

.countdown-compact {
    font-family: monospace;
    color: #dc3545;
    font-weight: 500;
}

.place-bet-button-small {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: #28a745;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.2s ease;
    border: none;
    text-align: center;
    min-width: 80px;
}

.place-bet-button-small:hover {
    background: #218838;
    transform: translateY(-1px);
}

.no-data {
    padding: 3rem;
    text-align: center;
    color: #6c757d;
    font-size: 1.1rem;
}

/* Recent Activity Section */
.activity-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.activity-item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 0;
    border-bottom: 1px solid #edf2f7;
}

.activity-item:last-child {
    border-bottom: none;
}

.activity-icon {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(66, 153, 225, 0.1);
    color: #4299e1;
}

.activity-details {
    flex: 1;
}

.activity-title {
    font-size: 15px;
    font-weight: 500;
    color: #2d3748;
    margin: 0;
}

.activity-time {
    font-size: 13px;
    color: #718096;
    margin: 4px 0 0;
}

.activity-amount {
    font-size: 15px;
    font-weight: 600;
    color: #2d3748;
}

/* Remove loading animation styles */
.loading-spinner,
.loading-spinner:before,
.loading-spinner:after {
    display: none;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border-radius: 8px;
    margin: 20px 0;
    text-align: center;
}

/* Section Header Styles */
.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.section-header h2 {
    margin: 0;
}

.view-all-button,
.dashboard-view-all-button {
    padding: 6px 16px;
    border-radius: 6px;
    background: #f8fafc;
    color: #2c5f2d;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all 0.2s ease;
    border: 1px solid #2c5f2d;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
}

.view-all-button:hover,
.dashboard-view-all-button:hover {
    background: #f1f5f9;
    color: #234b24;
    border-color: #234b24;
    transform: translateY(-1px);
}

/* Responsive Design */
@media (min-width: 768px) {
    .dashboard-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .account-balance {
        grid-column: 1 / -1;
    }
    
    .recent-challenges-container {
        grid-column: 1 / -1;
    }
}

@media (max-width: 767px) {
    .action-buttons {
        flex-direction: column;
    }
    
    .potential-returns {
        flex-direction: column;
    }
    
    .bet-matchup {
        flex-direction: column;
        gap: 15px;
        text-align: center;
    }
    
    .vs-badge {
        margin: 10px 0;
    }
    
    .team-logo-medium {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 768px) {
    .user-dashboard {
        padding: 20px;
    }

    .dashboard-grid {
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .dashboard-card {
        padding: 20px;
    }

    .stat-value {
        font-size: 24px;
    }

    .bets-list {
        display: block;
        overflow-x: auto;
    }
}

@media (max-width: 768px) {
    .bet-matchup {
        flex-direction: column;
        gap: 15px;
    }

    .bet-team {
        width: 100%;
    }

    .vs-badge {
        margin: 10px 0;
    }

    .potential-returns {
        flex-direction: column;
    }
}

/* Dashboard Recent Challenges */
.dashboard-recent-challenges {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
}

.dashboard-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard-section-header h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #1e293b;
}

.dashboard-view-all-button {
    padding: 6px 16px;
    border-radius: 6px;
    background: #f8fafc;
    color: #2c5f2d;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all 0.2s ease;
    border: 1px solid #2c5f2d;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
}

.dashboard-view-all-button:hover {
    background: #f1f5f9;
    color: #234b24;
    border-color: #234b24;
    transform: translateY(-1px);
}

.dashboard-challenges-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.dashboard-challenge-card {
    background: #f8fafc;
    border-radius: 12px;
    padding: 20px;
    border: 1px solid #e2e8f0;
    transition: all 0.2s ease;
}

.dashboard-challenge-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.dashboard-challenge-teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;
}

.dashboard-challenge-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex: 1;
}

.dashboard-team-logo-wrapper {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-team-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}

.dashboard-team-name {
    font-size: 0.9rem;
    color: #2b3035;
    text-align: center;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
}

.dashboard-vs-badge {
    font-weight: 600;
    color: #6c757d;
    padding: 8px 16px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    height: 32px;
    background: #f8f9fa;
    position: relative;
}

.dashboard-vs-badge::before,
.dashboard-vs-badge::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 2px;
    height: 24px;
    background-color: #e2e8f0;
    transform: translateY(-50%);
}

.dashboard-vs-badge::before {
    left: 0;
}

.dashboard-vs-badge::after {
    right: 0;
}

.dashboard-fc-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.dashboard-fc-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    background: #f8fafc;
    border-radius: 4px;
}

.dashboard-fc-label {
    color: #64748b;
    font-size: 0.9rem;
    font-weight: 500;
}

.dashboard-fc-amount {
    font-family: 'Courier New', monospace;
    font-weight: 600;
    color: #2c5f2d;
    font-size: 1.1rem;
}

.dashboard-place-bet-button {
    display: inline-block;
    width: 100%;
    padding: 12px;
    background: #2c5f2d;
    color: white;
    text-align: center;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 600;
    transition: all 0.2s ease;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.dashboard-place-bet-button:hover {
    background: #234b24;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dashboard-challenge-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.dashboard-challenge-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-challenge-amount {
    font-family: 'Courier New', monospace;
    font-weight: 600;
    color: #2c5f2d;
    font-size: 1.1rem;
}

.dashboard-challenge-date {
    color: #64748b;
    font-size: 0.9rem;
}

.dashboard-challenge-timer {
    text-align: center;
    font-family: 'Courier New', monospace;
    color: #dc2626;
    font-weight: 500;
}

.dashboard-join-button {
    display: inline-block;
    width: 100%;
    padding: 10px;
    background: #2c5f2d;
    color: white;
    text-align: center;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.2s ease;
}

.dashboard-join-button:hover {
    background: #234b24;
    transform: translateY(-1px);
}

.dashboard-no-data {
    text-align: center;
    padding: 40px;
    color: #64748b;
    font-size: 1rem;
    background: #f8fafc;
    border-radius: 8px;
    border: 1px dashed #e2e8f0;
}

.dashboard-challenge-stats {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.dashboard-stat-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    background: #f8fafc;
    border-radius: 4px;
}

.dashboard-stat-label {
    color: #64748b;
    font-size: 0.9rem;
    font-weight: 500;
}

.dashboard-stat-value {
    font-family: 'Courier New', monospace;
    font-weight: 600;
    color: #1e293b;
    font-size: 0.95rem;
}

.dashboard-challenge-status {
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
}

.dashboard-status-open {
    background: #dcfce7;
    color: #166534;
}

.dashboard-status-closed {
    background: #fee2e2;
    color: #991b1b;
}

.dashboard-status-expired {
    background: #fef3c7;
    color: #92400e;
}

.dashboard-status-settled {
    background: #e0f2fe;
    color: #075985;
}

@media (max-width: 1200px) {
    .dashboard-challenges-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .dashboard-challenges-grid {
        grid-template-columns: 1fr;
    }
}
