.admin-header {
  height: 60px;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  position: fixed;
  top: 0;
  right: 0;
  left: 280px;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.header-content {
  height: 100%;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
}

.page-title {
  font-size: 20px;
  font-weight: 600;
  color: #2c5f2d;
  margin: 0;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-info {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.admin-name {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #495057;
  font-size: 14px;
  font-weight: 500;
}

.admin-icon {
  font-style: normal;
  font-size: 16px;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-button {
  width: 36px;
  height: 36px;
  border: none;
  background: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  position: relative;
}

.action-button:hover {
  background: #f8f9fa;
}

.action-button.notifications:hover {
  background: #fff3cd;
}

.action-button.settings:hover {
  background: #e9ecef;
}

.action-button.logout:hover {
  background: #f8d7da;
}

.action-icon {
  font-style: normal;
  font-size: 18px;
}

.action-button.settings a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .admin-header {
    left: 0;
  }
  
  .header-content {
    padding: 0 16px;
  }
  
  .admin-name span {
    display: none;
  }
  
  .header-actions {
    gap: 4px;
  }
  
  .action-button {
    width: 32px;
    height: 32px;
  }
} 