/* Root Variables - Enhanced Color Palette */
:root {
    --primary-color: #2C5F2D;
    --primary-hover: #224924;
    --danger-color: #DC2626;
    --danger-hover: #B91C1C;
    --success-color: #2ecc71;
    --success-hover: #27ae60;
    --warning-color: #f1c40f;
    --date-color: #3498db;
    --background-light: #F9FAFB;
    --background-dark: #1F2937;
    --border-color: #E5E7EB;
    --text-primary: #1F2937;
    --text-secondary: #6B7280;
    --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
    --transition-base: all 0.3s ease;
}

/* Container Styles - Enhanced Spacing */
.credit-history-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 1.5rem;
}

.credit-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;
    padding: 1rem 1.5rem;
    background: white;
    border-radius: 12px;
    box-shadow: var(--shadow-sm);
}

.credit-history-header h1 {
    color: var(--text-primary);
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    letter-spacing: -0.025em;
}

/* Enhanced Credit Summary Cards */
.credit-summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.summary-card {
    background: white;
    border-radius: 12px;
    padding: 1.75rem;
    box-shadow: var(--shadow-sm);
    transition: var(--transition-base);
    border: 1px solid transparent;
}

.summary-card:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
}

.summary-card.approved {
    background: linear-gradient(135deg, #e0f7e9, #f0fff0);
    border-color: var(--success-color);
}

.summary-card.pending {
    background: linear-gradient(135deg, #fef9e7, #fff8e1);
    border-color: var(--warning-color);
}

.summary-card h3 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.summary-card .amount {
    font-size: 2.25rem;
    font-weight: 700;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    letter-spacing: -0.025em;
}

.summary-card.approved .amount { 
    color: var(--success-color);
}

.summary-card.pending .amount { 
    color: var(--warning-color);
}

/* Enhanced Table Styles */
.credit-requests-section {
    background: white;
    border-radius: 12px;
    box-shadow: var(--shadow-sm);
    overflow: hidden;
}

.credit-requests-table {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.credit-requests-table table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    min-width: 800px;
    table-layout: fixed;
}

/* Column Width Definitions */
.credit-requests-table th.date-header,
.credit-requests-table td.date-cell {
    width: 12%;
}

.credit-requests-table th.amount-header,
.credit-requests-table td.amount-cell {
    width: 15%;
}

.credit-requests-table th.payment-method-header,
.credit-requests-table td.payment-method-cell {
    width: 35%;
}

.credit-requests-table th.status-header,
.credit-requests-table td.status-cell {
    width: 18%;
}

.credit-requests-table th.actions-header,
.credit-requests-table td.actions-cell {
    width: 20%;
}

/* Enhanced Table Headers */
.credit-requests-table th {
    background: var(--background-light);
    padding: 1.25rem 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: var(--text-primary);
    text-align: left;
    white-space: nowrap;
    border-bottom: 2px solid var(--border-color);
}

/* Enhanced Table Cells */
.credit-requests-table td {
    padding: 1.25rem 1rem;
    vertical-align: middle;
    border-bottom: 1px solid var(--border-color);
    font-size: 0.9375rem;
    transition: var(--transition-base);
}

/* Enhanced Date Cell */
.date-cell {
    color: var(--date-color);
    font-weight: 500;
}

.date-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.date-line {
    font-size: 0.9375rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Enhanced Amount Cell */
.amount-cell {
    font-size: 1.125rem;
    font-weight: 700;
    color: var(--success-color);
    white-space: nowrap;
}

/* Enhanced Payment Method Cell */
.payment-method-cell {
    font-weight: 500;
    color: var(--text-primary);
    padding-right: 1.5rem;
}

.payment-method-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.method-name {
    font-weight: 600;
    color: var(--primary-color);
    font-size: 0.9375rem;
}

.method-details {
    color: var(--text-secondary);
    font-size: 0.8125rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Enhanced Status Badge */
.status-badge {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.8125rem;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0.025em;
    transition: var(--transition-base);
}

.status-badge-success {
    background-color: #e0f7e9;
    color: var(--success-color);
}

.status-badge-danger {
    background-color: #fee2e2;
    color: var(--danger-color);
}

.status-badge-warning {
    background-color: #fef3c7;
    color: var(--warning-color);
}

.status-badge-pending {
    background-color: #e1effe;
    color: var(--date-color);
}

/* Enhanced Action Buttons */
.view-proof-btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.625rem 1.25rem;
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition-base);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.view-proof-btn:hover {
    background-color: var(--primary-hover);
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Enhanced Table Row Hover */
.credit-requests-table tbody tr {
    transition: var(--transition-base);
}

.credit-requests-table tbody tr:hover {
    background-color: var(--background-light);
}

.credit-requests-table tbody tr:hover td {
    color: var(--text-primary);
}

/* Enhanced New Request Button */
.new-request-btn {
    background-color: var(--success-color);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-size: 0.9375rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition-base);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

.new-request-btn:hover {
    background-color: var(--success-hover);
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Enhanced Pagination */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
    gap: 1rem;
    border-top: 1px solid var(--border-color);
    background: var(--background-light);
}

.pagination-btn {
    background: white;
    border: 1px solid var(--border-color);
    padding: 0.625rem 1rem;
    border-radius: 8px;
    font-size: 0.9375rem;
    font-weight: 500;
    color: var(--text-primary);
    cursor: pointer;
    transition: var(--transition-base);
}

.pagination-btn:not(:disabled):hover {
    background: var(--background-light);
    border-color: var(--text-secondary);
    transform: translateY(-1px);
}

.page-info {
    font-size: 0.9375rem;
    font-weight: 500;
    color: var(--text-primary);
}

/* Maintain Responsive Design */
@media (min-width: 1024px) and (max-width: 1366px) {
    .credit-history-container {
        padding: 1.25rem;
    }

    .credit-history-header {
        padding: 1rem;
        margin-bottom: 2rem;
    }

    .credit-history-header h1 {
        font-size: 1.75rem;
    }

    .summary-card {
        padding: 1.5rem;
    }

    .summary-card .amount {
        font-size: 2rem;
    }

    .credit-requests-table th,
    .credit-requests-table td {
        padding: 1rem;
    }

    .amount-cell {
        font-size: 1rem;
    }

    .date-line {
        font-size: 0.875rem;
    }

    .credit-requests-table th.payment-method-header,
    .credit-requests-table td.payment-method-cell {
        width: 32%;
    }

    .method-name {
        font-size: 0.875rem;
    }

    .method-details {
        font-size: 0.75rem;
    }
}

/* Ensure table is visible on all non-mobile screens */
@media (min-width: 768px) {
    .credit-requests-table table {
        display: table;
    }
    
    .mobile-cards {
        display: none;
    }
}

/* Tablet View */
@media (min-width: 768px) and (max-width: 1023px) {
    .credit-history-container {
        padding: 0.75rem;
    }

    .credit-requests-table {
        font-size: 0.75rem;
    }

    .summary-card {
        padding: 1rem;
    }

    .summary-card .amount {
        font-size: 1.25rem;
    }

    /* Further optimize column widths for tablets */
    .date-cell {
        min-width: 80px;
        max-width: 90px;
    }

    .amount-cell {
        min-width: 80px;
        max-width: 90px;
    }

    .payment-method-cell {
        min-width: 140px;
        max-width: 160px;
    }

    .status-cell {
        min-width: 80px;
        max-width: 90px;
    }

    .actions-cell {
        min-width: 80px;
        max-width: 90px;
    }

    .credit-requests-table th.payment-method-header,
    .credit-requests-table td.payment-method-cell {
        width: 30%;
    }

    .payment-method-cell {
        padding-right: 1rem;
    }
}

/* Mobile View */
@media (max-width: 767px) {
    .credit-history-container {
        padding: 0.5rem;
    }

    .credit-history-header {
        flex-direction: column;
        gap: 1rem;
    }

    .credit-summary {
        grid-template-columns: 1fr;
    }

    .credit-requests-table table {
        display: none;
    }

    /* Mobile Card Layout */
    .mobile-cards {
        display: grid;
        gap: 0.75rem;
    }

    .mobile-card {
        background: white;
        border-radius: 8px;
        padding: 1rem;
        box-shadow: var(--shadow-sm);
    }

    .mobile-card-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.75rem;
    }

    .mobile-card-amount {
        font-weight: 600;
        color: var(--primary-color);
        font-size: 1.125rem;
    }

    .mobile-card-date {
        font-size: 0.75rem;
        color: var(--text-secondary);
    }

    .mobile-card-content {
        display: grid;
        gap: 0.5rem;
    }

    .mobile-card-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .mobile-card-label {
        font-size: 0.75rem;
        color: var(--text-secondary);
    }

    .mobile-card-value {
        font-size: 0.875rem;
    }
}

/* Loading State */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap: 1rem;
}

.loading-spinner {
    width: 2rem;
    height: 2rem;
    border: 2px solid var(--border-color);
    border-top-color: var(--primary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

/* Error Message */
.error-message {
    background-color: #FDE2E1;
    color: var(--danger-color);
    padding: 0.75rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 0.875rem;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1.25rem;
}

.proof-modal {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    max-width: 90%;
    max-height: 90vh;
    width: 800px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.close-modal {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #4a5568;
    padding: 0.25rem;
    line-height: 1;
    z-index: 2;
    transition: color 0.2s;
}

.close-modal:hover {
    color: #2d3748;
}

.proof-details {
    background: #f7fafc;
    border-radius: 8px;
    padding: 1.5rem;
}

.proof-details h3 {
    margin: 0 0 1rem 0;
    color: #2d3748;
    font-size: 1.25rem;
    font-weight: 600;
}

.proof-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.proof-info-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.proof-label {
    color: #718096;
    font-size: 0.875rem;
    font-weight: 500;
}

.proof-value {
    color: #2d3748;
    font-size: 1rem;
    font-weight: 500;
}

.proof-image-container {
    flex: 1;
    min-height: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #f7fafc;
    border-radius: 8px;
    padding: 1rem;
}

.proof-image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-requests {
    background: white;
    border-radius: 12px;
    padding: 3rem;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-data-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.no-data-message span[role="img"] {
    font-size: 3rem;
}

.no-data-message p {
    margin: 0;
    color: #4a5568;
    font-size: 1.125rem;
}

.no-data-message .sub-text {
    color: #718096;
    font-size: 0.875rem;
}

.new-request-btn:active {
    transform: translateY(0);
}

/* Ensure smooth transitions */
.credit-requests-table td,
.credit-requests-table th,
.view-proof-btn,
.pagination-btn,
.status-badge {
    transition: all 0.2s ease;
}

/* Add subtle animations */
@media (min-width: 1024px) and (max-width: 1366px) {
    .credit-requests-table tr {
        transition: transform 0.15s ease;
    }

    .credit-requests-table tr:hover {
        transform: translateY(-1px);
    }

    .summary-card {
        transition: transform 0.2s ease;
    }

    .summary-card:hover {
        transform: translateY(-2px);
    }
} 