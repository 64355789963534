:root {
    --primary-green: #2c5f2d;
    --light-green: #52b788;
    --white: #ffffff;
    --gray-100: #f8f9fa;
    --gray-200: #e9ecef;
    --gray-300: #dee2e6;
    --gray-600: #718096;
    --gray-800: #2d3748;
    --shadow-sm: 0 2px 4px rgba(0,0,0,0.05);
    --shadow-md: 0 4px 6px rgba(0,0,0,0.1);
    --border-radius: 12px;
}

.login-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background: linear-gradient(135deg, var(--primary-green) 0%, var(--light-green) 100%);
    overflow-y: auto;
}

.login-container {
    width: 100%;
    max-width: 420px;
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-md);
    padding: 32px 24px;
    position: relative;
    overflow: visible;
    margin: auto;
}

.login-header {
    text-align: center;
    margin-bottom: 32px;
}

.brand-logo {
    font-size: 28px;
    font-weight: bold;
    color: var(--primary-green);
    text-decoration: none;
    display: block;
    margin-bottom: 16px;
}

.login-header h1 {
    font-size: 22px;
    color: var(--gray-800);
    margin: 0 0 8px;
}

.login-subtitle {
    color: var(--gray-600);
    font-size: 14px;
    margin: 0;
    line-height: 1.4;
}

.login-form {
    width: 100%;
}

.form-group {
    margin-bottom: 16px;
}

.form-group label {
    display: block;
    margin-bottom: 4px;
    color: var(--gray-800);
    font-size: 13px;
    font-weight: 500;
}

.input-wrapper {
    position: relative;
    width: 100%;
}

.input-wrapper i {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--gray-600);
    font-size: 14px;
}

.input-wrapper input {
    width: 100%;
    padding: 9px 12px 9px 32px;
    border: 1px solid var(--gray-300);
    border-radius: var(--border-radius);
    font-size: 13px;
    transition: all 0.3s ease;
}

.input-wrapper input:focus {
    outline: none;
    border-color: var(--light-green);
    box-shadow: 0 0 0 3px rgba(82, 183, 136, 0.1);
}

.input-wrapper input::placeholder {
    color: var(--gray-600);
    font-size: 12px;
}

.error-message {
    background: #fee2e2;
    color: #dc2626;
    padding: 12px;
    border-radius: 8px;
    font-size: 13px;
    margin-bottom: 20px;
    text-align: center;
}

.login-button {
    width: 100%;
    padding: 12px;
    background: var(--primary-green);
    color: var(--white);
    border: none;
    border-radius: var(--border-radius);
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.login-button:hover {
    background: var(--light-green);
}

.login-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.links-section {
    margin-top: 20px;
    text-align: center;
    font-size: 13px;
}

.links-section a {
    color: var(--primary-green);
    text-decoration: none;
    transition: color 0.3s ease;
}

.links-section a:hover {
    color: var(--light-green);
}

@media screen and (max-height: 700px) {
    .login-page {
        align-items: flex-start;
        padding: 20px 16px;
    }

    .login-container {
        margin: 20px auto;
        padding: 24px 20px;
    }

    .login-header {
        margin-bottom: 24px;
    }

    .brand-logo {
        font-size: 24px;
        margin-bottom: 12px;
    }

    .login-header h1 {
        font-size: 20px;
        margin-bottom: 6px;
    }

    .form-group {
        margin-bottom: 16px;
    }
}

@media screen and (max-width: 380px) {
    .login-container {
        padding: 20px 16px;
    }

    .input-wrapper input {
        font-size: 13px;
        padding: 8px 12px 8px 32px;
    }

    .input-wrapper i {
        font-size: 13px;
        left: 12px;
    }
}
