/* Modern Color Variables */
:root {
    --primary-green: #2C5F2D;
    --primary-green-hover: #224924;
    --light-green: #e8f5e9;
    --white: #ffffff;
    --gray-50: #f9fafb;
    --gray-100: #f3f4f6;
    --gray-200: #e5e7eb;
    --gray-300: #d1d5db;
    --gray-400: #9ca3af;
    --gray-500: #6b7280;
    --gray-600: #4b5563;
    --gray-700: #374151;
    --gray-800: #1f2937;
    --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.05);
    --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.05);
    --radius-sm: 0.375rem;
    --radius-md: 0.5rem;
    --radius-lg: 0.75rem;
    --spacing-xs: 0.5rem;
    --spacing-sm: 1rem;
    --spacing-md: 1.5rem;
    --spacing-lg: 2rem;
    --spacing-xl: 3rem;
    --container-width: 1440px;
}

/* Base Layout */
.credit-wallet-container {
    width: 100%;
    max-width: var(--container-width);
    margin: 0 auto;
    padding: var(--spacing-lg);
    background-color: var(--gray-50);
    min-height: 100vh;
}

.credit-wallet-container h1 {
    color: var(--gray-800);
    margin-bottom: var(--spacing-xl);
    text-align: center;
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    font-weight: 600;
}

/* Step Container */
.step-container {
    background: var(--white);
    border-radius: var(--radius-lg);
    padding: var(--spacing-lg);
    box-shadow: var(--shadow-md);
    margin-bottom: var(--spacing-lg);
    border: 1px solid var(--gray-200);
    width: 100%;
}

.step-container h2 {
    color: var(--gray-800);
    margin-bottom: var(--spacing-md);
    font-size: clamp(1.25rem, 3vw, 1.75rem);
    font-weight: 600;
    padding-bottom: var(--spacing-sm);
    border-bottom: 1px solid var(--gray-200);
}

/* Payment Methods List Layout */
.payment-methods-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    margin-top: var(--spacing-md);
    list-style: none;
    padding: 0;
}

.payment-method-item {
    width: 100%;
    background: var(--white);
    border-radius: var(--radius-lg);
    padding: var(--spacing-md);
    border: 1px solid var(--gray-200);
    transition: all 0.3s ease;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--spacing-md);
    align-items: start;
}

.payment-method-item:hover {
    border-color: var(--primary-green);
    box-shadow: var(--shadow-md);
    transform: translateY(-1px);
}

/* Method Type Icon */
.method-type {
    font-size: clamp(1.75rem, 3vw, 2.25rem);
    width: min(3.5rem, 12vw);
    height: min(3.5rem, 12vw);
    background: var(--light-green);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid rgba(44, 95, 45, 0.1);
    flex-shrink: 0;
}

/* Method Details */
.method-details {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    width: 100%;
}

.method-details h3 {
    color: var(--primary-green);
    font-size: clamp(1.1rem, 2vw, 1.3rem);
    font-weight: 600;
    padding-bottom: var(--spacing-xs);
    border-bottom: 1px solid var(--gray-200);
    word-break: break-word;
    margin: 0;
}

.method-type-label {
    color: var(--gray-600);
    font-size: 0.9rem;
    margin-bottom: var(--spacing-sm);
}

/* Field Lists */
.field-list,
.method-fields {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    width: 100%;
    margin-top: var(--spacing-xs);
}

/* Field Items */
.field-item {
    background: var(--gray-50);
    border-radius: var(--radius-md);
    padding: var(--spacing-md);
    border: 1px solid var(--gray-200);
    transition: all 0.2s ease;
    position: relative;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spacing-sm);
    align-items: start;
}

.field-item:hover {
    border-color: var(--primary-green);
    box-shadow: var(--shadow-sm);
}

.field-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    width: 100%;
}

.field-item strong {
    color: var(--primary-green);
    font-size: 0.9rem;
    font-weight: 600;
    display: block;
    margin-bottom: var(--spacing-xs);
    word-break: break-word;
}

.field-item p {
    margin: 0;
    padding: var(--spacing-sm);
    color: var(--gray-700);
    font-size: 0.95rem;
    line-height: 1.5;
    word-break: break-word;
    background: var(--white);
    border-radius: var(--radius-sm);
    font-family: monospace;
}

/* Click to Copy */
.copy-indicator {
    color: var(--gray-400);
    font-size: 0.75rem;
    padding: var(--spacing-xs);
    background: var(--white);
    border-radius: var(--radius-sm);
    border: 1px solid var(--gray-200);
    white-space: nowrap;
    opacity: 0;
    transition: all 0.2s ease;
}

.field-item:hover .copy-indicator {
    opacity: 1;
    color: var(--primary-green);
}

.field-item.copied .copy-indicator {
    background: var(--light-green);
    color: var(--primary-green);
    border-color: var(--primary-green);
}

/* Action Buttons */
.action-buttons {
    display: flex;
    gap: var(--spacing-sm);
    margin-top: var(--spacing-md);
}

.action-button {
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--radius-md);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
    font-size: 0.95rem;
    flex: 1;
}

.select-button {
    background: var(--primary-green);
    color: var(--white);
}

.select-button:hover {
    background: var(--primary-green-hover);
}

/* Selected State */
.payment-method-item.selected {
    border-color: var(--primary-green);
    background: var(--light-green);
}

.payment-method-item.selected .field-item {
    background: var(--white);
}

/* Payment Details */
.payment-details {
    background: var(--gray-50);
    border-radius: var(--radius-lg);
    padding: var(--spacing-lg);
    margin: var(--spacing-md) 0;
    border: 1px solid var(--gray-200);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    width: 100%;
}

.payment-details h3,
.payment-details h4 {
    color: var(--gray-800);
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    font-weight: 600;
    padding-bottom: var(--spacing-xs);
    border-bottom: 1px solid var(--gray-200);
}

.payment-details p {
    font-size: clamp(0.95rem, 2vw, 1.1rem);
    color: var(--gray-700);
    padding: var(--spacing-sm);
    background: var(--white);
    border-radius: var(--radius-sm);
    border: 1px solid var(--gray-200);
    margin: 0;
}

/* Form Elements */
.form-group {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    margin-bottom: var(--spacing-md);
}

.form-group label {
    color: var(--gray-700);
    font-weight: 500;
    font-size: 0.95rem;
}

.form-group input {
    width: 100%;
    padding: var(--spacing-sm);
    border: 1px solid var(--gray-200);
    border-radius: var(--radius-md);
    font-size: 1rem;
    transition: all 0.2s ease;
}

.form-group input:focus {
    outline: none;
    border-color: var(--primary-green);
    box-shadow: 0 0 0 3px rgba(44, 95, 45, 0.1);
}

/* Buttons */
.next-btn,
.submit-btn {
    width: 100%;
    padding: var(--spacing-sm) var(--spacing-md);
    background: var(--primary-green);
    color: var(--white);
    border: none;
    border-radius: var(--radius-md);
    font-size: clamp(0.95rem, 2vw, 1.1rem);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.next-btn:hover,
.submit-btn:hover {
    background: var(--primary-green-hover);
    transform: translateY(-1px);
    box-shadow: var(--shadow-md);
}

/* Timer */
.timer {
    background: var(--light-green);
    padding: var(--spacing-md);
    border-radius: var(--radius-md);
    text-align: center;
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    font-weight: 600;
    color: var(--primary-green);
    margin-bottom: var(--spacing-md);
    border: 1px solid rgba(44, 95, 45, 0.1);
}

/* Messages */
.error-message,
.success-message {
    padding: var(--spacing-sm);
    border-radius: var(--radius-md);
    margin-bottom: var(--spacing-md);
    text-align: center;
    font-weight: 500;
    font-size: clamp(0.9rem, 2vw, 1rem);
}

.error-message {
    background: #fef2f2;
    color: #dc2626;
    border: 1px solid #fee2e2;
}

.success-message {
    background: var(--light-green);
    color: var(--primary-green);
    border: 1px solid rgba(44, 95, 45, 0.1);
}

/* Success Container */
.success-container {
    text-align: center;
    padding: var(--spacing-xl) var(--spacing-lg);
}

.success-icon {
    font-size: clamp(3rem, 6vw, 4rem);
    margin-bottom: var(--spacing-lg);
}

.success-container h2 {
    color: var(--primary-green);
    margin-bottom: var(--spacing-md);
    border: none;
    font-size: clamp(1.25rem, 3vw, 1.75rem);
}

.success-container p {
    color: var(--gray-600);
    margin: var(--spacing-xs) 0;
    font-size: clamp(0.95rem, 2vw, 1.1rem);
    line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .credit-wallet-container {
        padding: var(--spacing-md);
    }
    
    .step-container {
        padding: var(--spacing-md);
    }
}

@media (max-width: 768px) {
    .credit-wallet-container {
        padding: var(--spacing-sm);
    }
    
    .payment-methods-list {
        grid-template-columns: 1fr;
    }
    
    .payment-details {
        padding: var(--spacing-md);
    }
    
    .field-item {
        padding: var(--spacing-sm);
    }
}

@media (max-width: 480px) {
    .step-container {
        padding: var(--spacing-sm);
    }
    
    .method-type {
        font-size: 1.75rem;
        width: 3rem;
        height: 3rem;
    }
    
    .field-item p {
        font-size: 0.9rem;
        padding: var(--spacing-xs);
    }
} 