.recent-bets-page {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
}

.recent-bets-header {
    margin-bottom: 2rem;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.recent-bets-header h1 {
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.75rem;
}

.recent-bets-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.search-box {
    flex: 1;
    min-width: 250px;
}

.search-box input {
    padding: 0.75rem 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    font-size: 1rem;
    transition: border-color 0.2s ease;
}

.search-box input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.filter-buttons {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.filter-button {
    padding: 0.75rem 1.25rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
    color: #333;
}

.filter-button:hover {
    background: #f5f5f5;
    border-color: #c1c1c1;
}

.filter-button.active {
    background: #28a745;
    color: white;
    border-color: #218838;
    box-shadow: 0 2px 4px rgba(40, 167, 69, 0.2);
}

.recent-bets-container {
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    overflow-x: auto;
    max-width: 100%;
}

.recent-bets-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    min-width: 800px;
    max-width: 100%;
}

.recent-bets-table td {
    padding: 5px;
    vertical-align: middle;
}

th,
td {
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

th {
    font-weight: 600;
    color: #495057;
    border-bottom: 2px solid #dee2e6;
    position: sticky;
    top: 0;
    z-index: 1;
}

tr:last-child td {
    border-bottom: none;
}

tr:hover {
    background-color: #f8f9fa;
}

.number-column {
    width: 50px;
    text-align: center !important;
    color: #6c757d;
    font-family: monospace;
    font-size: 0.875rem;
}

.teams-compact {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    min-width: 360px;
    max-width: 440px;
    padding: 5px;
}

.team-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.375rem;
}

.team-info span {
    color: #000;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
}

.bet-details-stack {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-start;
}

.bet-details-compact {
    padding: 5px;
    min-width: 120px;
}

.reference-code {
    font-family: monospace;
    font-size: 0.875rem;
    color: #2e7d32;
    background: #e8f5e9;
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    min-width: 120px;
    text-align: center;
}

.bet-choice {
    font-size: 0.75rem;
    color: #1976d2;
    background: #e3f2fd;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-weight: 500;
    display: inline-block;
    margin: 0;
    text-align: center;
    width: 100%;
}

.returns-compact {
    white-space: nowrap;
    width: 110px;
    padding: 5px;
}

.returns-compact div {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    color: #666;
}

.returns-compact div:last-child {
    margin-bottom: 0;
}

.returns-compact div:nth-child(1) {
    color: #28a745;
}

.returns-compact div:nth-child(2) {
    color: #ffc107;
}

.returns-compact div:nth-child(3) {
    color: #dc3545;
}

.date-column {
    white-space: nowrap;
    width: 85px;
    font-size: 0.875rem;
    color: #666;
    text-align: center;
    padding: 5px;
}

.status-badge {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
}

.status-badge.pending {
    background: #fff3e0;
    color: #e65100;
}

.status-badge.active {
    background: #e8f5e9;
    color: #2e7d32;
}

.status-badge.completed {
    background: #e3f2fd;
    color: #1565c0;
}

.status-badge.cancelled {
    background: #fafafa;
    color: #757575;
}

.status-badge.won {
    background: #28a745;
    color: white;
}

.status-badge.lost {
    background: #dc3545;
    color: white;
}

.status-badge.pending {
    background: #ffc107;
    color: #000;
}

.view-bet-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: #28a745;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.2s ease;
    border: none;
    text-align: center;
    min-width: 80px;
}

.view-bet-button:hover {
    background: #218838;
    transform: translateY(-1px);
}

.no-data {
    padding: 3rem;
    text-align: center;
    color: #6c757d;
    font-size: 1.1rem;
}

.loading {
    padding: 3rem;
    text-align: center;
    color: #6c757d;
    font-size: 1.1rem;
}

.error-message {
    padding: 1rem;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    margin-bottom: 1rem;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-top: none;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
}

.pagination-button {
    min-width: 36px;
    height: 36px;
    padding: 0 0.75rem;
    border: 1px solid #dee2e6;
    color: #495057;
    font-size: 0.875rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    font-weight: 500;
}

.pagination-button:hover:not(:disabled) {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #0056b3;
    transform: translateY(-1px);
}

.pagination-button.active {
    background: #28a745;
    border-color: #218838;
    color: white;
    box-shadow: 0 2px 4px rgba(40, 167, 69, 0.2);
}

.pagination-button:disabled {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #6c757d;
    cursor: not-allowed;
    opacity: 0.7;
}

.pagination-button.nav {
    font-weight: bold;
    min-width: 42px;
}

.pagination-ellipsis {
    color: #6c757d;
    padding: 0 0.5rem;
    user-select: none;
}

.reference-code {
    font-family: monospace;
    font-size: 0.875rem;
    color: #2e7d32;
    background: #e8f5e9;
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    font-weight: bold;
    display: inline-block;
    margin: 0.25rem 0;
    min-width: 120px;
    text-align: center;
}

.reference-code.clickable {
    cursor: pointer;
    transition: all 0.2s ease;
}

.reference-code.clickable:hover {
    background: #c8e6c9;
    color: #1b5e20;
}

.team-name {
    font-weight: 600;
    font-size: 14px;
    color: #333;
    line-height: 1.2;
    white-space: nowrap;
    padding: 0 0.5rem;
    text-align: center;
    min-height: 2.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-card {
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    min-width: 240px;
    transition: transform 0.2s ease;
}

.team-cell .team-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    font-weight: 500;
}

.team-cell .team-info span {
    color: #000;
    font-weight: 500;
}

.match-grid {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 1.5rem;
    align-items: start;
    margin: 1rem 0;
    padding: 1.5rem;
    border-radius: 12px;
}

.team-card {
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    min-width: 200px;
    transition: transform 0.2s ease;
}

.team-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.team-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    text-align: center;
}

.team-logo {
    width: 32px !important;
    height: 32px !important;
    object-fit: contain;
    transition: transform 0.2s ease;
}

.team-card:hover .team-logo {
    transform: scale(1.05);
}

.team-name {
    font-size: 14px !important;
    color: #000 !important;
    font-weight: normal !important;
    line-height: 1.2;
    white-space: nowrap;
    padding: 0 0.5rem;
    text-align: center;
    min-height: 2.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding: 1rem;
}

.vs {
    font-weight: bold;
    color: #666;
    font-size: 1.5rem;
    background: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.pick-badge {
    background: #e3f2fd;
    color: #1976d2;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 4px rgba(25,118,210,0.1);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    border-radius: 12px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
}

.modal-header {
    padding: 1.25rem;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px 12px 0 0;
}

.modal-header h3 {
    margin: 0;
    color: #1976d2;
    font-size: 1.25rem;
}

.close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #666;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s ease;
}

.close-btn:hover {
    background: #e3f2fd;
    color: #1976d2;
}

.modal-body {
    padding: 1.5rem;
}

.section-title {
    font-weight: 600;
    color: #333;
    margin: 1.5rem 0 1rem;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.section-title:first-child {
    margin-top: 0;
}

.teams-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 1rem 0;
    padding: 1.5rem;
    border-radius: 12px;
    gap: 1rem;
}

.team-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.vs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 1rem;
}

.vs {
    font-weight: bold;
    color: #666;
    font-size: 1.25rem;
}

.team {
    display: flex;
    align-items: center;
    gap: 8px;
}

.team-logo {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.team-name {
    font-size: 14px;
    color: #333;
}

.bet-info {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #666;
    margin-left: 8px;
}

.vs {
    padding: 0 12px;
    color: #666;
    font-size: 14px;
    border-left: 1px solid #e2e8f0;
    border-right: 1px solid #e2e8f0;
}

.teams-row {
    display: flex;
    align-items: center;
    padding: 8px 0;
}

.match-date {
    font-size: 12px;
    color: #666;
    margin-bottom: 4px;
}

.team-odds, .draw-odds {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    min-width: 100px;
}

.odds-label {
    font-size: 0.75rem;
    color: #666;
    text-transform: uppercase;
}

.odds-value {
    font-weight: bold;
    color: #1976d2;
    font-size: 1.125rem;
}

.odds-grid,
.financial-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    margin: 1rem 0;
}

.odds-item,
.financial-item {
    padding: 0.75rem;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.odds-label,
.financial-label {
    font-size: 0.75rem;
    color: #666;
}

.odds-value,
.financial-value {
    font-weight: bold;
    color: #333;
}

.financial-value.win {
    color: #4caf50;
}

.financial-value.loss {
    color: #f44336;
}

.financial-value.draw {
    color: #ff9800;
}

.view-details-btn {
    background: transparent;
    border: none;
    color: #1976d2;
    padding: 0.25rem;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.125rem;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
}

.view-details-btn:hover {
    background: #e3f2fd;
    color: #1565c0;
}

.modal-content .team-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    text-align: center;
}

.modal-content .team-header .team-name {
    font-weight: 600;
    font-size: 1.125rem;
    color: #000 !important;
    line-height: 1.2;
    white-space: nowrap;
    padding: 0 0.5rem;
    text-align: center;
    min-height: 2.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content .team-card {
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    min-width: 240px;
    transition: transform 0.2s ease;
}

.modal-content .team-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.modal-content .team-logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    transition: transform 0.2s ease;
}

.modal-content .team-card:hover .team-logo {
    transform: scale(1.05);
}

.team-cell .team-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.team-cell .team-info span {
    color: #000;
    font-weight: 500;
}

.user-bet-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    padding: 0.75rem;
    border-radius: 8px;
    margin-top: auto;
}

.user-bet-info .username {
    font-weight: 500;
    color: #1976d2;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-bet-info .bet-amount {
    color: #666;
    font-size: 0.875rem;
}

.match-date {
    font-size: 0.875rem;
    color: #666;
    text-align: center;
    margin-top: 0.5rem;
}

.users-info {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #666;
}

.users-info .user-label {
    color: #1976d2;
    font-weight: 500;
    margin-left: 0.5rem;
}

.users-info .user-label:first-child {
    margin-left: 0;
}

.recent-bets-table .team-container {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    padding: 0.375rem;
    background: white;
    min-width: 340px;
    max-width: 420px;
    border: none;
    border-radius: 0;
}

.recent-bets-table .team-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.recent-bets-table .team-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.375rem;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.recent-bets-table .user-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.375rem;
    border: none;
    box-shadow: none;
}

.recent-bets-table .date-info {
    font-size: 0.75rem;
    color: #2e7d32;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    background: #e8f5e9;
    border-radius: 4px;
    display: inline-block;
}

/* Keep modal styles separate */
.modal-content .team-container,
.modal-content .team-section,
.modal-content .team-info {
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.team-container {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    padding: 0.375rem;
    background: white;
    min-width: 340px;
    max-width: 420px;
}

.date-info {
    font-size: 0.75rem;
    color: #666;
    padding: 0 0.25rem;
    margin-bottom: 0.125rem;
}

.teams-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.team-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.team-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.375rem;
}

.team-info span {
    color: #000;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
}

.user-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.375rem;
}

.user-info .username {
    color: #1976d2;
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-info .bet-amount {
    color: #666;
    font-size: 0.875rem;
}

.vs-divider {
    color: #666;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
    flex-shrink: 0;
    font-size: 0.875rem;
}

.team-logo-small {
    width: 20px;
    height: 20px;
    object-fit: contain;
    flex-shrink: 0;
}

.bet-choice {
    display: inline-flex;
    align-items: center;
    color: #1976d2;
    padding: 0.25rem 0.5rem;
    border-radius: 16px;
    font-size: 0.75rem;
    font-weight: 500;
    margin-top: 0.375rem;
    white-space: nowrap;
}

.status-actions {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    justify-content: flex-end;
    min-width: 140px;
}

.actions-column {
    text-align: right;
    white-space: nowrap;
}

.teams-cell {
    padding: 12px;
    min-width: 400px;
}

.recent-bets-team-logo {
    width: 32px;
    height: 32px;
    object-fit: contain;
    margin-bottom: 4px;
}

.recent-bets-team-name {
    font-size: 14px;
    color: #000;
    font-weight: normal;
}

.recent-bets-team-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 8px;
}

.recent-bets-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 160px;
}

.recent-bets-user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 4px;
}

.recent-bets-username {
    font-size: 15px;
    color: #2c5f2d;
    font-weight: 500;
}

.recent-bets-amount {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.recent-bets-vs {
    padding: 0 20px;
    color: #666;
    font-size: 14px;
    height: 32px;
    display: flex;
    align-items: center;
    margin-top: 16px;
    border-left: 1px solid #e2e8f0;
    border-right: 1px solid #e2e8f0;
}

.recent-bets-teams-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;
    position: relative;
}

.recent-bets-teams-row .recent-bets-vs {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.match-date {
    font-size: 12px;
    color: #666;
    margin-bottom: 12px;
    text-align: center;
}

.recent-bets-table td {
    padding: 5px;
    vertical-align: middle;
}

.bet-username {
    font-weight: 600;
    color: #2c3e50;
    font-size: 0.9rem;
    text-decoration: none;
    transition: color 0.2s ease;
    text-transform: uppercase;
}

.bet-username:hover {
    color: #3498db;
}

.bet-amount {
    color: #666;
    font-size: 0.875rem;
}
