.bet-management {
  padding: 20px;
  width: 100%;
  background-color: #f8f9fa;
}

.bet-management h1 {
  color: #2c5f2d;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
}

.bet-management h2 {
  color: #2c5f2d;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
}

.credit-form-section {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.credit-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 800px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: bold;
}

.form-group select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background: #2c5f2f;
}

.bets-list {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  overflow-x: auto;
}

.bets-list table {
  width: 100%;
  border-collapse: collapse;
  min-width: 1200px;
}

.bets-list th {
  background: #2c5f2d;
  color: white;
  text-align: left;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.bets-list td {
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  vertical-align: middle;
}

.bets-list tr:hover {
  background-color: #f8f9fa;
}

.bets-list .team-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.bets-list .team-info img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.bets-list .team-info span {
  font-size: 14px;
  white-space: nowrap;
}

.status {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  text-transform: capitalize;
}

.status.open { 
  background: #fff3cd; 
  color: #856404; 
}

.status.joined { 
  background: #cce5ff; 
  color: #004085; 
}

.status.completed { 
  background: #d4edda; 
  color: #155724; 
}

.returns-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 13px;
}

.returns-info div {
  white-space: nowrap;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    padding: 20px;
}

.pagination button {
    padding: 8px 16px;
    border: 1px solid #2c5f2d;
    background: white;
    color: #2c5f2d;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease;
}

.pagination button:hover {
    background: #2c5f2d;
    color: white;
}

.pagination button.active {
    background: #2c5f2d;
    color: white;
}

@media (max-width: 1200px) {
  .bets-list {
    margin: 0 -20px;
    border-radius: 0;
  }
}
