/* Root Variables */
:root {
    --primary-color: #2C5F2D;
    --primary-hover: #224924;
    --danger-color: #DC2626;
    --danger-hover: #B91C1C;
    --success-color: #059669;
    --warning-color: #D97706;
    --background-light: #F9FAFB;
    --background-dark: #1F2937;
    --border-color: #E5E7EB;
    --text-primary: #1F2937;
    --text-secondary: #6B7280;
    --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Container Styles */
.transaction-management-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 1.5rem;
}

.transaction-management-container h1 {
    color: var(--text-primary);
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

/* Table Container */
.credit-requests-table {
    position: relative;
    background: white;
    border-radius: 0.5rem;
    box-shadow: var(--shadow-sm);
    overflow: hidden;
    width: 100%;
    overflow-x: auto; /* Enable horizontal scroll by default */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

/* Sticky Header */
.credit-requests-table thead {
    position: sticky;
    top: 0;
    z-index: 10;
    background: white;
    box-shadow: var(--shadow-sm);
}

/* Table Styles */
.credit-requests-table table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    min-width: 1200px; /* Minimum width to ensure all columns are visible */
}

/* Header Cells */
.credit-requests-table th {
    background: var(--background-light);
    color: var(--text-primary);
    font-size: 0.875rem;
    font-weight: 600;
    text-align: left;
    padding: 0.75rem 1rem;
    white-space: nowrap;
}

/* Column Widths */
.credit-requests-table th.row-number { width: 50px; }  /* # */
.credit-requests-table th:nth-child(2) { width: 130px; } /* Date */
.credit-requests-table th:nth-child(3) { width: 150px; } /* User */
.credit-requests-table th:nth-child(4) { width: 120px; } /* Amount */
.credit-requests-table th:nth-child(5) { width: 200px; } /* Payment Method */
.credit-requests-table th:nth-child(6) { width: 100px; } /* Status */
.credit-requests-table th:nth-child(7) { width: 130px; } /* Expires */
.credit-requests-table th:nth-child(8) { width: 100px; } /* Proof */
.credit-requests-table th:nth-child(9) { width: 160px; } /* Actions */

/* Table Body */
.credit-requests-table td {
    padding: 0.75rem 1rem;
    font-size: 0.8125rem;
    color: var(--text-primary);
    border-bottom: 1px solid var(--border-color);
    vertical-align: middle;
}

/* Zebra Striping */
.credit-requests-table tbody tr:nth-child(even) {
    background-color: var(--background-light);
}

/* Row Hover */
.credit-requests-table tbody tr:hover {
    background-color: #F3F4F6;
}

/* Amount Styling */
td.amount {
    font-weight: 600;
    color: var(--primary-color);
}

/* Date Styling */
td.date {
    color: var(--text-secondary);
    font-size: 0.75rem;
}

/* Status Badge */
.status-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-size: 0.6875rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.status-badge::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.status-badge.success {
    background-color: #DEF7EC;
    color: var(--success-color);
}

.status-badge.success::before {
    background-color: var(--success-color);
}

.status-badge.danger {
    background-color: #FDE2E1;
    color: var(--danger-color);
}

.status-badge.danger::before {
    background-color: var(--danger-color);
}

.status-badge.warning {
    background-color: #FEF3C7;
    color: var(--warning-color);
}

.status-badge.warning::before {
    background-color: var(--warning-color);
}

.status-badge.pending {
    background-color: #E1EFFE;
    color: #1E429F;
}

.status-badge.pending::before {
    background-color: #1E429F;
}

/* Payment Method Info */
.payment-method-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-width: 300px;
}

.method-name {
    font-weight: 600;
    color: var(--primary-color);
}

.method-details {
    font-size: 0.75rem;
    color: var(--text-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Action Buttons */
.action-buttons {
    display: flex;
    gap: 0.5rem;
}

.approve-btn,
.reject-btn,
.view-proof-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    padding: 0.5rem 0.75rem;
    border-radius: 0.375rem;
    font-size: 0.75rem;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: all 0.2s;
    min-height: 32px;
}

.approve-btn {
    background-color: var(--primary-color);
    color: white;
}

.approve-btn:hover {
    background-color: var(--primary-hover);
}

.reject-btn {
    background-color: var(--danger-color);
    color: white;
}

.reject-btn:hover {
    background-color: var(--danger-hover);
}

.view-proof-btn {
    background-color: var(--text-secondary);
    color: white;
}

.view-proof-btn:hover {
    background-color: var(--text-primary);
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    padding: 1rem;
}

.proof-modal {
    background: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: min(600px, 90vw);
    max-height: 90vh;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.close-modal {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--text-secondary);
    cursor: pointer;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    transition: all 0.2s;
}

.close-modal:hover {
    background-color: var(--background-light);
    color: var(--text-primary);
}

.proof-details {
    margin-bottom: 1rem;
}

.proof-details h3 {
    color: var(--primary-color);
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
}

.proof-details p {
    margin: 0.25rem 0;
    color: var(--text-secondary);
    font-size: 0.875rem;
}

.proof-image-container {
    flex: 1;
    min-height: 0;
    overflow: auto;
    border-radius: 0.25rem;
    background: var(--background-light);
}

.proof-image-container img {
    width: 100%;
    height: auto;
    display: block;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .method-details {
        max-width: 200px;
    }
    
    .payment-method-info {
        max-width: 200px;
    }
}

@media (max-width: 768px) {
    .transaction-management-container {
        padding: 1rem;
    }

    .credit-requests-table {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .credit-requests-table table {
        min-width: 650px;
    }

    .method-details {
        display: none;
    }

    .payment-method-info {
        max-width: 150px;
    }

    .action-buttons {
        flex-direction: column;
    }

    .approve-btn,
    .reject-btn,
    .view-proof-btn {
        width: 100%;
        padding: 0.5rem;
    }

    td.date {
        white-space: nowrap;
    }
}

@media (max-width: 640px) {
    .credit-requests-table {
        background: transparent;
        box-shadow: none;
    }

    .credit-requests-table table {
        display: none;
    }

    /* Card Layout for Mobile */
    .credit-requests-table .mobile-cards {
        display: grid;
        gap: 1rem;
    }

    .request-card {
        background: white;
        border-radius: 0.5rem;
        padding: 1rem;
        box-shadow: var(--shadow-sm);
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 0.75rem;
    }

    .card-amount {
        font-size: 1.125rem;
        font-weight: 600;
        color: var(--primary-color);
    }

    .card-date {
        font-size: 0.75rem;
        color: var(--text-secondary);
    }

    .card-content {
        display: grid;
        gap: 0.75rem;
    }

    .card-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .card-label {
        font-size: 0.75rem;
        color: var(--text-secondary);
    }

    .card-value {
        font-size: 0.875rem;
        color: var(--text-primary);
    }

    .card-actions {
        display: grid;
        gap: 0.5rem;
        margin-top: 1rem;
    }

    .card-content .username-display {
        font-size: 1.125rem;
        font-weight: 600;
        color: var(--text-primary);
        margin: 0.5rem 0;
        padding: 0.5rem;
        background: var(--background-light);
        border-radius: 0.375rem;
        text-align: center;
    }

    .request-card {
        padding-top: 2rem; /* Space for the row number */
    }
}

/* Loading State */
.loading {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
}

/* Messages */
.error-message,
.success-message {
    padding: 0.75rem 1rem;
    border-radius: 0.375rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
}

.error-message {
    background-color: #FDE2E1;
    color: var(--danger-color);
    border: 1px solid #F8B4B4;
}

.success-message {
    background-color: #DEF7EC;
    color: var(--success-color);
    border: 1px solid #84E1BC;
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
    .approve-btn,
    .reject-btn,
    .view-proof-btn,
    .close-modal {
        transition: none;
    }
}

/* Focus States */
.approve-btn:focus,
.reject-btn:focus,
.view-proof-btn:focus,
.close-modal:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}

/* Screen Reader Only */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

/* Row Number Column */
.row-number {
    width: 50px !important;
    text-align: center !important;
    color: var(--text-secondary);
    font-size: 0.75rem;
}

/* Sortable Headers */
.sortable {
    cursor: pointer;
    user-select: none;
    position: relative;
    padding-right: 1.5rem !important;
}

.sortable:hover {
    background-color: #F3F4F6;
}

.sortable::after {
    content: attr(data-sort);
    position: absolute;
    right: 0.5rem;
    color: var(--text-secondary);
    font-size: 0.75rem;
}

/* Username Display */
.username-display {
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-primary);
}

/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 0.5rem;
    background: white;
    border-top: 1px solid var(--border-color);
}

.page-numbers {
    display: flex;
    gap: 0.25rem;
}

.pagination-button {
    padding: 0.5rem 1rem;
    border: 1px solid var(--border-color);
    background: white;
    color: var(--text-primary);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s;
}

.pagination-button:hover:not(:disabled) {
    background: var(--background-light);
    border-color: var(--text-secondary);
}

.pagination-button.active {
    background: var(--primary-color);
    color: white;
    border-color: var(--primary-color);
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Accessibility improvements */
.sortable:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: -2px;
}

.pagination-button:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}

/* High contrast mode support */
@media (prefers-contrast: high) {
    .status-badge {
        border: 1px solid currentColor;
    }
    
    .pagination-button.active {
        outline: 2px solid currentColor;
    }
}

/* Medium Screen Optimization (13"-14" displays) */
@media (min-width: 1024px) and (max-width: 1366px) {
    .transaction-management-container {
        padding: 0.75rem;
    }

    .credit-requests-table {
        font-size: 0.8125rem;
    }

    /* Adjust table cell padding */
    .credit-requests-table td,
    .credit-requests-table th {
        padding: 0.5rem 0.625rem;
    }

    /* Optimize column widths for medium screens */
    .credit-requests-table th.row-number { width: 40px; }
    .credit-requests-table th:nth-child(2) { width: 120px; }
    .credit-requests-table th:nth-child(3) { width: 140px; }
    .credit-requests-table th:nth-child(4) { width: 100px; }
    .credit-requests-table th:nth-child(5) { width: 180px; }
    .credit-requests-table th:nth-child(6) { width: 90px; }
    .credit-requests-table th:nth-child(7) { width: 120px; }
    .credit-requests-table th:nth-child(8) { width: 90px; }
    .credit-requests-table th:nth-child(9) { width: 140px; }

    /* Compact action buttons */
    .action-buttons {
        display: flex;
        gap: 0.25rem;
        flex-wrap: nowrap; /* Prevent button wrapping */
    }

    .approve-btn,
    .reject-btn,
    .view-proof-btn {
        padding: 0.375rem 0.5rem;
        font-size: 0.75rem;
        min-height: 28px;
        white-space: nowrap;
        min-width: fit-content;
    }
}

/* Tablet Optimization */
@media (min-width: 768px) and (max-width: 1023px) {
    .transaction-management-container {
        padding: 0.75rem;
    }

    .credit-requests-table {
        font-size: 0.75rem;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    /* Adjust table cell padding */
    .credit-requests-table td,
    .credit-requests-table th {
        padding: 0.5rem;
    }

    /* Optimize column widths for tablets */
    .credit-requests-table th.row-number { width: 40px; }
    .credit-requests-table th:nth-child(2) { width: 110px; }
    .credit-requests-table th:nth-child(3) { width: 130px; }
    .credit-requests-table th:nth-child(4) { width: 90px; }
    .credit-requests-table th:nth-child(5) { width: 160px; }
    .credit-requests-table th:nth-child(6) { width: 80px; }
    .credit-requests-table th:nth-child(7) { width: 110px; }
    .credit-requests-table th:nth-child(8) { width: 80px; }
    .credit-requests-table th:nth-child(9) { width: 130px; }

    /* Compact action buttons for tablets */
    .action-buttons {
        display: flex;
        gap: 0.25rem;
        flex-wrap: nowrap;
    }

    .approve-btn,
    .reject-btn,
    .view-proof-btn {
        padding: 0.375rem 0.5rem;
        font-size: 0.75rem;
        min-height: 26px;
        white-space: nowrap;
        min-width: fit-content;
    }

    /* Hide less important information */
    .method-details {
        display: none;
    }

    .payment-method-info {
        max-width: 160px;
    }
}

/* Add scroll hint for non-mobile devices */
@media (min-width: 641px) {
    .scroll-hint {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        background: var(--background-light);
        color: var(--text-secondary);
        font-size: 0.75rem;
        border-bottom: 1px solid var(--border-color);
    }

    .scroll-hint svg {
        width: 16px;
        height: 16px;
        margin-right: 0.25rem;
    }
}

/* Tablet Layout (13"-14" screens) */
@media (min-width: 641px) and (max-width: 1366px) {
    .transaction-management-container {
        padding: 1rem;
        max-width: 100%;
    }

    .tablet-cards {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        padding: 1rem;
    }

    .tablet-request-card {
        background: white;
        border-radius: 0.5rem;
        box-shadow: var(--shadow-sm);
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        transition: box-shadow 0.2s;
    }

    .tablet-request-card:hover {
        box-shadow: var(--shadow-md);
    }

    .tablet-card-header {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .tablet-card-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .tablet-card-number {
        font-size: 0.875rem;
        color: var(--text-secondary);
        font-weight: 500;
    }

    .tablet-card-date {
        font-size: 0.75rem;
        color: var(--text-secondary);
    }

    .tablet-card-username {
        font-size: 1.125rem;
        font-weight: 600;
        color: var(--text-primary);
        flex: 1;
        margin-right: 1rem;
    }

    .tablet-card-amount {
        font-size: 1.125rem;
        font-weight: 600;
        color: var(--primary-color);
    }

    .tablet-card-content {
        display: grid;
        gap: 0.75rem;
        padding: 0.75rem 0;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
    }

    .tablet-card-method {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
    }

    .tablet-card-method .payment-method-info {
        max-width: none;
    }

    .tablet-card-method .method-name {
        font-size: 0.875rem;
    }

    .tablet-card-method .method-details {
        font-size: 0.75rem;
        white-space: normal;
        max-width: none;
    }

    .tablet-card-status {
        display: flex;
        align-items: center;
    }

    .tablet-card-expires {
        font-size: 0.75rem;
        color: var(--text-secondary);
    }

    .tablet-card-actions {
        display: flex;
        gap: 0.5rem;
        margin-top: auto;
    }

    .tablet-card-actions button {
        flex: 1;
        min-width: 0;
        white-space: nowrap;
        padding: 0.625rem 1rem;
        font-size: 0.875rem;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    /* Hide table view on tablet */
    .credit-requests-table table {
        display: none;
    }

    /* Optimize status badges for tablet */
    .status-badge {
        padding: 0.25rem 0.75rem;
        font-size: 0.75rem;
    }

    /* Adjust pagination for tablet */
    .pagination {
        background: transparent;
        padding: 1rem 0;
        margin-top: 1rem;
    }

    .pagination-button {
        min-width: 36px;
        height: 36px;
        padding: 0.5rem;
        font-size: 0.875rem;
    }

    /* Remove scroll hint for tablet view */
    .scroll-hint {
        display: none;
    }
}

/* Specific optimizations for larger tablets (1024px-1366px) */
@media (min-width: 1024px) and (max-width: 1366px) {
    .tablet-cards {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }

    .tablet-card-username {
        font-size: 1.25rem;
    }

    .tablet-card-amount {
        font-size: 1.25rem;
    }

    .tablet-card-actions button {
        height: 40px;
        font-size: 1rem;
    }
}

/* High-DPI Screen Support */
@media (min-width: 641px) and (max-width: 1366px) and (min-resolution: 120dpi) {
    .tablet-request-card {
        border: 1px solid var(--border-color);
    }

    .tablet-card-actions button {
        font-weight: 500;
    }
}

/* Desktop-specific styles */
@media screen and (min-width: 1367px) {
    .username-display {
        font-size: 18px;
        text-transform: capitalize;
        font-weight: 500;
    }

    .date-expires {
        min-width: 180px;
        padding: 8px 12px;
    }

    .date-content {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .date-line,
    .expires-line {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 13px;
    }

    .date-label {
        color: #666;
        font-size: 12px;
        font-weight: 500;
        min-width: 35px;
    }

    .created-date {
        color: #2C5F2D;  /* Primary green color */
        font-weight: 500;
    }

    .expires-date {
        color: #D97706;  /* Warning/orange color for expiry */
        font-weight: 500;
    }

    /* Update the date column width in table */
    .credit-requests-table th.date-column {
        min-width: 180px;
    }

    .action-buttons {
        display: flex;
        gap: 8px;
    }

    .approve-btn,
    .reject-btn {
        padding: 6px 12px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        transition: all 0.2s ease;
        min-width: 80px;
    }

    .approve-btn {
        background-color: #28a745;
        color: white;
    }

    .approve-btn:hover {
        background-color: #218838;
    }

    .reject-btn {
        background-color: #dc3545;
        color: white;
    }

    .reject-btn:hover {
        background-color: #c82333;
    }

    /* Table header styles */
    .date-column {
        min-width: 400px;
    }
}

/* Tablet view adjustments */
@media (min-width: 1024px) and (max-width: 1366px) {
    .date-expires {
        min-width: 160px;
    }

    .date-line,
    .expires-line {
        font-size: 12px;
    }

    .date-label {
        font-size: 11px;
        min-width: 32px;
    }
}

/* Existing styles remain unchanged */
.transaction-management-container {
    padding: 20px;
    max-width: 100%;
    overflow-x: hidden;
}

.credit-requests-table {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-top: 20px;
}

.table-container {
    overflow-x: auto;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th {
    background-color: #f8f9fa;
    padding: 12px;
    text-align: left;
    font-weight: 600;
    color: #333;
    border-bottom: 2px solid #dee2e6;
}

td {
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    vertical-align: middle;
}

.sortable {
    cursor: pointer;
    user-select: none;
}

.sortable:hover {
    background-color: #e9ecef;
} 