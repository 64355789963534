.add-user {
    padding: 20px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.add-user h1 {
    margin-bottom: 30px;
    color: #333;
}

.add-user form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-user label {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-weight: 500;
}

.add-user input,
.add-user select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.add-user button {
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.2s;
}

.add-user button:hover {
    background-color: #0056b3;
}

.error-message {
    color: #dc3545;
    padding: 10px;
    border-radius: 4px;
    margin-top: 20px;
    background-color: rgba(220, 53, 69, 0.1);
}

.success-message {
    color: #28a745;
    padding: 10px;
    border-radius: 4px;
    margin-top: 20px;
    background-color: rgba(40, 167, 69, 0.1);
}
